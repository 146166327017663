import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import React, { createContext, useState, useEffect } from 'react';
import { ConfigProvider, FloatButton, Drawer, Button, Form, Input, Select, TimePicker, Image } from 'antd';
import { FooterComp } from './components/footer/FooterComp';
import Scroll from './hoc/Scroll';
import DataStore from './store/DataStore';
import ErrorPage from './pages/errorPage/ErrorPage';
import locale from 'antd/es/locale/ru_RU';
import { Header } from './components/header/Header';
import { MainPage } from './pages/mainPage/MainPage';
import { GarantiyaPage } from './pages/garantiyaPage/GarantiyaPage';
import { StyleProvider } from '@ant-design/cssinjs';
import 'antd/dist/reset.css';
import { ServicePage } from './pages/servicePage/ServicePage';
import { OtzyvyPage } from './pages/otzyvyPage/OtzyvyPage';
import { AktsiiPage } from './pages/aktsiiPage/AktsiiPage';
import { AboutPage } from './pages/aboutPage/AboutPage';
import { PhoneOutlined } from '@ant-design/icons';
import { ServicePremiumPage } from './pages/servicePremiumPage/ServicePremiumPage';
import ListArticlesPage from './pages/listArticlesPage/ListArticlesPage';
import UniversalArticlesPage from './pages/universalArticlesPage/UniversalArticlesPage';
import ProdazhaPage from './pages/prodazha/ProdazhaPage';
import SkupkaPage from './pages/skupkaPage/SkupkaPage';
import KalkulyatorPage from './pages/kalkulyatorPage/KalkulyatorPage';
import { AvtoHoldPage } from './pages/avtoHoldPage/AvtoHoldPage';
import WindowsComp from './components/windowsModal/WindowsComp';
// import { sendOrderTelegram } from './http/telegramAPI';
// import InputMask from 'react-input-mask'

export const Context = createContext(null);



function App() {
  const [dataApp] = useState(new DataStore());
  const [hover, setHover] = useState(false);
  // const [drawerVisible, setDrawerVisible] = useState(false);
  // const [tel, setTel] = useState('')

  const handleMouseLeave = () => {
    setHover(false);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setDrawerVisible(true);
  //   }, 10000);

  //   return () => clearTimeout(timer);
  // }, []);

  // const handleDrawerClose = () => {
  //   setDrawerVisible(false);
  // };
  // <b>Время звонка:</b> ${values.time.format('HH:mm') || ''}
  // const handleFormSubmit = (values) => {
  //   console.log("🚀 🚀 🚀  _ handleFormSubmit _ values:", values)
  //   const message = `
  //     <b>Запрос на консультацию с сайта</b>\n
  //     <b>- - - - - - - - - - - - - - -</b>\n
  //     <b>Телефон:</b> <a href='tel:${tel}'>${tel}</a>\n

  //   `;
  //   sendOrderTelegram(message);
  //   setDrawerVisible(false);
  // };

  // const beforeMaskedValueChange = (newState, oldState, userInput) => {
  //   var { value } = newState
  //   var selection = newState.selection
  //   var cursorPosition = selection ? selection.start : null
  //   if (value.endsWith('-') && userInput !== '-' && !tel.endsWith('-')) {
  //     if (cursorPosition === value.length) {
  //       cursorPosition--
  //       selection = { start: cursorPosition, end: cursorPosition }
  //     }
  //     value = value.slice(0, -1)
  //   }
  //   return {
  //     value,
  //     selection
  //   }
  // }

  return (
    <ConfigProvider locale={locale}>
      <Context.Provider value={{ dataApp }}>
        <StyleProvider hashPriority="high">
          <BrowserRouter>
            <div className="app">
              <FloatButton.BackTop
                type="primary"
                style={{ right: 'auto', left: 20, bottom: 35 }}
                icon={<PhoneOutlined className="animate-pulse rotate-[115deg] text-white" />}
              />
              <div className="fon"></div>
              <div>
                <Header setHover={setHover} hover={hover} />
                <main className="" onMouseEnter={handleMouseLeave}>
                  <Scroll>
                    <Routes>
                      <Route path="/" element={<MainPage />} />
                      <Route path="/uslugi/:link" element={<ServicePage />} />
                      <Route path="/uslugi-premium/:link" element={<ServicePremiumPage />} />
                      <Route path="/uslugi" element={<Navigate to="/" />} />
                      <Route path="/uslugi-premium" element={<Navigate to="/" />} />
                      <Route path="/aktsii-i-skidki" element={<AktsiiPage />} />
                      <Route path="/garantiya" element={<GarantiyaPage />} />
                      <Route path="/o-nas" element={<AboutPage />} />
                      <Route path="/otzyvy" element={<OtzyvyPage />} />
                      <Route path="/prodazha" element={<ProdazhaPage />} />
                      <Route path="/vykup-tehniki" element={<SkupkaPage />} />
                      <Route path="/uslugi/remont-avto-kholodilnikov" element={<AvtoHoldPage />} />
                      <Route path="/kalkulyator-remonta/:link" element={<KalkulyatorPage />} />
                      <Route path="/dlya-klienta" element={<ListArticlesPage />} />
                      <Route path="/article/:link" element={<UniversalArticlesPage />} />
                      <Route path="*" element={<ErrorPage />} />
                    </Routes>
                  </Scroll>
                </main>
              </div>
              <FooterComp />

              {/* <Drawer
                title="Консультация"
                placement="right"
                onClose={handleDrawerClose}
                visible={drawerVisible}
                style={{ background: '#eab308' }}
              >
                <div className='pt-16 relative h-full z-50'>
                  <p className='text-center mb-10 text-xl font-light'>
                    Есть вопросы? Перезвоним Вам в течении 30 секунд!
                  </p>
                  <Form
                    onFinish={handleFormSubmit}
                    size='large'
                    layout="vertical"
                  >

                    <Form.Item
                      label='Ваш телефон'
                      name="tel"
                      
                      tooltip="код оператора и номер"
                      rules={[
                        {
                          required: true,
                          message: 'Пожалуйста введите номер телефона!',
                        },
                      ]}
                    >
                      <InputMask
                        placeholder="29 123-45-67"
                        mask="+3\7\5 99 999 99 99"
                        maskChar={'-'}
                        className='border py-1 px-4 rounded-md w-full'
                        beforeMaskedValueChange={beforeMaskedValueChange}
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                      />
                    </Form.Item>
                   
                    <Form.Item>
                      <Button type="" htmlType="submit" className='w-full' style={{ background: '#000', color: '#fff' }} >
                        Жду звонка
                      </Button>
                    </Form.Item>
                  </Form>

                  <div className='mt-8 text-center'>

                    <p className='uppercase mt-6 text-red-600 text-xl'>
                      Звонок бесплатный
                    </p>
                  </div>

                  <div className='bg-white/25 w-20 h-20 rotate-12 absolute bottom-24 left-10 -z-10' />
                  <div className='bg-white/25 w-8 h-8 rotate-2 absolute bottom-6 left-32 -z-10' />
                  <div className='bg-white/25 w-20 h-20 rotate-12 absolute bottom-36 right-10 -z-10' />
                  <div className='bg-white/25 w-24 h-24 rotate-45 absolute bottom-60 left-10 -z-10' />
                  <div className='bg-white/25 w-28 h-28 rotate-45 absolute bottom-80 right-10 -z-10' />
                  <div className='bg-white/25 w-16 h-16 rotate-45 absolute bottom-12 right-12 -z-10' />
                  <div className='bg-white/25 w-6 h-6 rotate-12 absolute bottom-0 right-28 -z-10' />
                </div>
              </Drawer> */}

              <WindowsComp />
            </div>
          </BrowserRouter>
        </StyleProvider>
      </Context.Provider>
    </ConfigProvider>
  );
}

export default App;
