import holodilnik from '../../images/service/holodilnik2.webp'
import ledogenerator from '../../images/service/ledogenerator2.webp'
import morozilnik from '../../images/service/morozilnik1.webp'
import kondicioner from '../../images/service/kondicioner1.webp'
import stiralnyemashiny from '../../images/service/stiralnye-mashiny1.webp'
import sushilnyemashiny from '../../images/service/sushilnye-mashiny1.webp'
import posudomoechnayaMashina from '../../images/service/posudomoechnaya-mashina1.webp'
import varochnyePaneli from '../../images/service/varochnye-paneli1.webp'
import indukcionnyePlity from '../../images/service/indukcionnye-plity1.webp'
import duhovyeShkafy from '../../images/service/duhovye-shkafy1.webp'
import televizory from '../../images/service/televizory1.webp'
import robotPpylesosy from '../../images/service/robot-pylesosy1.webp'
import pylesosy from '../../images/service/pylesos1.webp'
import plan from '../../images/service/plan.webp'
import plan2 from '../../images/service/plan2.webp'

import img1 from '../../images/lg/1.webp'
import img2 from '../../images/lg/2.webp'
import img3 from '../../images/lg/3.webp'
import img4 from '../../images/lg/4.webp'
import img5 from '../../images/lg/5.webp'
import img6 from '../../images/lg/6.webp'
import img7 from '../../images/lg/7.webp'
import img8 from '../../images/lg/8.webp'
import img9 from '../../images/lg/9.webp'
import img10 from '../../images/lg/10.webp'
import img11 from '../../images/lg/11.webp'
import img12 from '../../images/lg/12.webp'
import img13 from '../../images/lg/13.webp'
import img14 from '../../images/lg/14.webp'
import img15 from '../../images/lg/15.webp'
import img16 from '../../images/lg/16.webp'
import img17 from '../../images/lg/17.webp'
import img18 from '../../images/lg/18.webp'
import img19 from '../../images/lg/19.webp'
import img20 from '../../images/lg/20.webp'



export const dataHolodilnik = [
	{
		id: 1,
		h1: 'Ремонт холодильников',
		p: 'У нас вы найдете полный спектр услуг по ремонту холодильников: точная диагностика, использование только фирменных запчастей и ремонт "под ключ". Наша команда опытных техников обладает глубокими знаниями и навыками, чтобы выявить и исправить любую неисправность. Мы гарантируем качество и долговечность наших ремонтных работ, используя только оригинальные детали. Восстановите работоспособность вашего холодильника с помощью наших профессионалов и снова наслаждайтесь свежими продуктами без лишних хлопот.',
		description: 'Сломался холодильник? Наша компания - ваш надежный партнер в ремонте. Опытные мастера, оригинальные запчасти, быстрый и профессиональный сервис. Восстановим работу вашего холодильника быстро и качественно. Не откладывайте ремонт - звоните нам прямо сейчас!',
		img: holodilnik,
		alt: 'ремонт холодильников',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта холодильника',
		price: [
			{
				id: 1,
				title: 'Не холодит',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не морозит',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не включается',
				price: '19 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не отключается',
				price: '20 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Намерзает',
				price: '21 руб',
				time: '2-4 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Шумит',
				price: '17 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
		],
		content: [
			{
				h4: 'Ремонт холодильника в Минске',
				p: `
				Ремонт холодильника – важная задача для многих владельцев бытовой техники. Ведь холодильник является неотъемлемой частью нашей повседневной жизни и от его надежной работы зависит сохранность продуктов питания. Если ваш холодильник перестал работать должным образом, то поиск квалифицированного сервисного центра по ремонту холодильников в Минске становится приоритетной задачей.

				Когда вам необходим ремонт холодильника в Минске, важно обратиться к профессионалам с большим опытом и знаниями в данной области. При выборе сервисного центра стоит обратить внимание на их репутацию, отзывы клиентов и квалификацию специалистов.
				На команда мастеров предлагает высококачественные услуги по ремонту холодильников в Минске. Наша команда профессионалов имеет богатый опыт в работе с различными моделями холодильников разных марок. Мы предлагаем полный спектр услуг, связанных с ремонтом холодильников, включая диагностику, замену деталей, настройку и профилактику.
				
				При обращении в наш сервисный центр по ремонту холодильников в Минске, вы можете рассчитывать на профессиональный подход и индивидуальный подход к каждому клиенту. Мы осознаем, что каждая поломка холодильника уникальна, поэтому наши специалисты проводят тщательную диагностику проблемы и предлагают эффективные решения.
				
				В нашем арсенале имеются все необходимые инструменты и запчасти для выполнения ремонта холодильников. Мы работаем с оригинальными запчастями, что гарантирует долговечность и надежность вашего холодильника после ремонта.
				
				Основные преимущества работы с нашей компанией включают:
				
				Профессионализм и опыт. Наши специалисты обладают высоким уровнем квалификации и регулярно повышают свои навыки, чтобы быть в курсе последних технологических разработок и новых моделей холодильников.
				
				Быстрое реагирование и оперативность. Мы понимаем, что поломка холодильника может создать неудобства и привести к потере продуктов. Поэтому мы стремимся предоставить нашим клиентам быстрые сроки выполнения ремонта, чтобы восстановить работоспособность холодильника как можно скорее.

				Индивидуальный подход. Мы учитываем особенности каждого клиента и его холодильника. Наша команда проводит детальную диагностику поломки и предлагает оптимальные решения, учитывая бюджет и потребности клиента. Мы всегда готовы ответить на ваши вопросы и дать профессиональную консультацию.
				
				Гарантия качества. Мы уверены в качестве нашей работы и предоставляем гарантию на выполненные ремонтные работы и установленные запчасти. Это дает нашим клиентам уверенность в надежности и долговечности ремонтированного холодильника.
				
				Доступные цены. Мы стремимся предложить нашим клиентам адекватные и конкурентные цены на ремонт холодильников в Минске. Мы работаем без скрытых дополнительных платежей и предлагаем прозрачную ценовую политику.
				
				Если вам требуется ремонт холодильника в Минске, обратитесь к нам. Мы готовы взяться за любую сложность поломки и восстановить работоспособность вашего холодильника. Наша цель - обеспечить комфорт и уверенность клиентов в сохранности их продуктов питания и надежности работы холодильника.
				`
			},
			{
				h4: 'Стоимость ремонта холодильника в Минске',
				p: `Стоимость ремонта холодильника в Минске – это один из важных факторов, который влияет на выбор сервисного центра для восстановления работоспособности вашей бытовой техники. Когда вам необходимо восстановить работу холодильника, важно понимать, какие факторы могут влиять на окончательную стоимость ремонта.

				Перед тем как приступить к ремонту холодильника, необходимо провести диагностику поломки, чтобы определить причину неполадки. Стоимость диагностики может варьироваться в зависимости от сервисного центра и сложности поломки. Обычно сервисные центры предоставляют информацию о стоимости диагностики заранее, чтобы клиент мог принять решение о дальнейших действиях.
				
				Стоимость ремонта холодильника может зависеть от нескольких факторов. Во-первых, это тип поломки. Некоторые поломки могут быть легко устранены с минимальными затратами, например, замена плохо работающего термостата или поврежденного электрического провода. Однако, если поломка более серьезная и требует замены сложных компонентов, стоимость ремонта может быть выше.
				
				Во-вторых, стоимость ремонта холодильника может зависеть от марки и модели самого холодильника. Разные производители используют различные компоненты и технологии, которые могут отличаться по цене. Поэтому, стоимость ремонта может варьироваться в зависимости от конкретной марки и модели холодильника.
				
				Также стоит учитывать стоимость запчастей. Если при ремонте необходимо заменить какие-либо детали холодильника, их стоимость будет включена в общую стоимость ремонта. Важно обратить внимание на то, что некоторые сервисные центры могут использовать оригинальные запчасти, что может повысить стоимость ремонта, но гарантировать долговечность и надежность холодильника после ремонта.
				
				Дополнительные услуги, такие как доставка запчастей или вызов мастера на дом, могут также влиять на общую стоимость ремонта холодильника. Эти услуги обычно имеют отдельную стоимость, которая может быть учтена при составлении сметы на ремонт.

				Кроме того, стоимость ремонта холодильника в Минске может различаться в зависимости от выбранного сервисного центра. Каждый сервисный центр имеет свою политику ценообразования, основанную на различных факторах, таких как расходы на оборудование, обучение специалистов, аренду помещения и другие операционные издержки. Поэтому, цены на ремонт могут варьироваться от центра к центру.
				
				При выборе сервисного центра по ремонту холодильника важно обратить внимание не только на стоимость, но и на качество предоставляемых услуг. Низкая цена может быть соблазнительной, но если качество ремонта оставляет желать лучшего, то в итоге вы можете столкнуться с повторной поломкой и дополнительными затратами.
				
				Важно помнить, что стоимость ремонта холодильника в Минске – это инвестиция в продление срока службы вашей бытовой техники. Качественный ремонт с использованием оригинальных запчастей и выполненный опытными специалистами позволит вам сэкономить деньги в долгосрочной перспективе, избежать неприятностей с повторными поломками и обеспечить надежную работу вашего холодильника на долгие годы.
				
				В компании "Ремонт Холодильников Минск" мы предлагаем разумные и конкурентоспособные цены на ремонт холодильников. Мы стремимся предоставить нашим клиентам прозрачные и справедливые цены, учитывая сложность поломки, марку и модель холодильника. Наша цель - предоставить качественные услуги по разумной стоимости, чтобы удовлетворить потребности наших клиентов и обеспечить долгосрочное удовлетворение от работы их холодильников.
				
				Обратившись к нам, вы получите профессиональный ремонт холодильника в Минске по адекватной и справедливой стоимости. Наша команда специалистов обладает необходимыми знаниями и опытом для решения любых проблем, возникающих при эксплуатации холодильника. Мы осуществляем диагностику поломки, определяем причину неполадки и предлагаем эффективные решения для ее устранения.

				Наши цены формируются на основе объективных факторов, таких как сложность ремонта, затраты на необходимые запчасти и время, затраченное на выполнение работ. Мы стремимся предоставить клиентам наилучшее соотношение цены и качества. При этом, мы используем оригинальные запчасти или их аналоги высокого качества, чтобы гарантировать надежность и долговечность восстановленного холодильника.
				
				Для удобства наших клиентов мы предоставляем предварительную оценку стоимости ремонта холодильника, основанную на описании поломки или результате диагностики. Таким образом, вы сможете оценить примерную стоимость и принять информированное решение о ремонте.
				
				Важно отметить, что мы предлагаем прозрачность и честность в отношении ценообразования. Мы не взимаем скрытых или дополнительных платежей, а наши цены остаются неизменными без неприятных сюрпризов. Мы стремимся установить долгосрочные взаимовыгодные отношения с нашими клиентами, предоставляя качественные услуги по адекватной стоимости.
				
				Если вам требуется ремонт холодильника в Минске, обращайтесь в наш сервисный центр. Мы гарантируем профессиональный подход, высокое качество работ и привлекательные цены. Наша команда опытных специалистов с удовольствием поможет вам вернуть работоспособность вашего холодильника, обеспечивая комфорт и уверенность в сохранности ваших продуктов питания.

				`
			},
			{
				h4: 'Почему стоит заказать ремонт холодильника именно в нашей компании',
				p: `
				Когда дело касается ремонта холодильника в Минске, правильный выбор мастера играет ключевую роль. Важно найти опытных и профессиональных специалистов, которые смогут быстро и качественно восстановить работоспособность вашего холодильника. Вот несколько преимуществ, которыми отличаются наши мастера:

Обширный опыт и знания. Наши мастера имеют богатый опыт в ремонте холодильников различных марок и моделей. Они обладают глубокими знаниями в области бытовой техники и технических аспектов работы холодильников. Благодаря этому, они могут эффективно диагностировать поломку и предложить оптимальные решения для ее устранения.

Профессионализм и надежность. Наша команда мастеров отличается высоким профессионализмом и ответственным подходом к работе. Мы понимаем, что холодильник является важной частью вашего дома, и поэтому стремимся восстановить его работоспособность в кратчайшие сроки. Мы придерживаемся высоких стандартов качества и гарантируем долгосрочную надежность ремонта.

Комплексный подход. Наши мастера не только устраняют конкретную поломку, но и проводят комплексную проверку и обслуживание холодильника. Они обращают внимание на все аспекты работы, включая систему охлаждения, уплотнительные резинки, электрические компоненты и другие элементы. Такой подход позволяет предотвратить возможные будущие поломки и обеспечить более длительный срок службы вашего холодильника.

Использование качественных запчастей. Мы работаем с оригинальными запчастями или их высококачественными аналогами. Это позволяет нам гарантировать надежность и долговечность ремонта. Мы тщательно подбираем запчасти, чтобы они соответствовали конкретной модели и марке вашего холодильника. Это позволяет избежать повторных поломок и обеспечивает безопасность в эксплуатации.

Гарантия качеств и надежности. Мы уверены в высоком качестве нашей работы, поэтому предоставляем гарантию на выполненный ремонт холодильника. Это означает, что в случае повторной поломки или неполадки в течение определенного периода времени после ремонта, мы бесплатно устраним проблему. Наша гарантия является подтверждением нашей уверенности в качестве работы и доверии к нашим мастерам.

Удобство и клиентоориентированность. Мы стремимся предоставить нашим клиентам максимальный комфорт и удобство в процессе ремонта. Мы предлагаем гибкое расписание приема заявок и готовы выезжать к вам в удобное для вас время. Наши мастера работают профессионально, аккуратно и оставляют рабочее место чистым после завершения ремонта.

Честные и конкурентоспособные цены. Мы понимаем, что ремонт холодильника может быть неожиданной финансовой нагрузкой для вас. Поэтому мы предлагаем честные и конкурентоспособные цены на наши услуги. Мы предоставляем прозрачную оценку стоимости ремонта и не взимаем скрытых или дополнительных платежей.

Выводя наших мастеров на рынок ремонта холодильников в Минске, мы стремимся предоставить нашим клиентам высокое качество услуг, надежность и уверенность в работе их холодильников. Мы ценим доверие, которое нам доверяют наши клиенты, и делаем все возможное, чтобы оправдать их ожидания. Обратившись к нам, вы можете быть уверены, что ваш холодильник будет ремонтироваться опытными профессионалами, используя качественные запчасти и придерживаясь высоких стандартов качества.
				`
			},

		]
	},
]
export const dataHolodilnikLg = [
	{
		id: 1,
		h1: 'Ремонт холодильников LG',
		link: '/uslugi/remont-kholodilnikov-lg',
		p: 'У нас вы найдете полный спектр услуг по ремонту холодильников LG',
		description: 'Сломался холодильник LG? Наша компания - ваш надежный партнер в ремонте. Опытные мастера, оригинальные запчасти, быстрый и профессиональный сервис. Восстановим работу вашего холодильника LG быстро и качественно. Звоните нам прямо сейчас!',
		img: holodilnik,
		imgArr: [
			img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20
		],
		alt: 'ремонт холодильников LG',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта холодильника LG',
		price: [
			{
				id: 1,
				title: 'Не холодит',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не морозит',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не включается',
				price: '19 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не отключается',
				price: '20 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Намерзает',
				price: '21 руб',
				time: '2-4 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Шумит',
				price: '17 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
		],
			content: [
				 {
					  "h4": "Ремонт холодильника LG в Минске",
					  "p": "Нужен качественный ремонт холодильника LG в Минске? Обращайтесь к нам! Мы предоставляем профессиональные услуги по ремонту техники этого бренда. Наши опытные мастера быстро и качественно восстановят работоспособность вашего холодильника, используя только оригинальные запчасти."
				 },
				 {
					  "h4": "От чего зависит стоимость ремонта холодильника LG",
					  "p": "Стоимость ремонта холодильника LG может варьироваться в зависимости от нескольких факторов, включая тип неисправности, необходимость замены деталей, гарантийный срок и другие. Но мы гарантируем адекватные цены и прозрачную систему расценок. С нами вы всегда будете уверены в том, что платите справедливую цену за качественный ремонт вашего холодильника LG."
				 }
			]
	  
	  
	},
]
export const dataAvtoHolodilnikLg = [
	{
		id: 1,
		h1: 'Ремонт автомобильных холодильников',
		link: '/uslugi/remont-avto-kholodilnikov',
		p: 'Срочный ремонт автомобильных салонных холодильников',
		description: '',
		img: holodilnik,
		imgArr: [],
		alt: 'Ремонт автомобильных холодильников',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость автомобильных холодильников',
		price: [
			{
				id: 1,
				title: 'Не холодит',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не морозит',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не включается',
				price: '19 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не отключается',
				price: '20 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Намерзает',
				price: '21 руб',
				time: '2-4 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Шумит',
				price: '17 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
		],
			content: [
				 {
					  "h4": "Ремонт холодильника LG в Минске",
					  "p": "Нужен качественный ремонт холодильника LG в Минске? Обращайтесь к нам! Мы предоставляем профессиональные услуги по ремонту техники этого бренда. Наши опытные мастера быстро и качественно восстановят работоспособность вашего холодильника, используя только оригинальные запчасти."
				 },
				 {
					  "h4": "От чего зависит стоимость ремонта холодильника LG",
					  "p": "Стоимость ремонта холодильника LG может варьироваться в зависимости от нескольких факторов, включая тип неисправности, необходимость замены деталей, гарантийный срок и другие. Но мы гарантируем адекватные цены и прозрачную систему расценок. С нами вы всегда будете уверены в том, что платите справедливую цену за качественный ремонт вашего холодильника LG."
				 }
			]
	  
	  
	},
]
export const dataLedogenerator = [
	{
		id: 2,
		h1: 'Ремонт льдогенераторов в Минске',
		p: 'Ваш льдогенератор перестал работать? Не отчаивайтесь! Наша компания - ваш надежный партнер в ремонте льдогенераторов. С нашими опытными мастерами вы можете быть уверены в качественном и профессиональном обслуживании вашего устройства. Мы оперативно и точно диагностируем неисправности, используя только оригинальные запчасти и передовые технологии. Восстановим работу вашего льдогенератора в кратчайшие сроки, чтобы вы снова могли наслаждаться свежим и чистым льдом. Не теряйте время - обратитесь к нам сегодня и получите высококлассный ремонт льдогенератора! Звоните нам прямо сейчас и доверьтесь профессионалам!',
		description: 'Сломался льдогенератор? Наша компания гарантирует быстрый и качественный ремонт. Опытные мастера, оригинальные запчасти, передовые технологии. Вернем льдогенератор к жизни! Звоните прямо сейчас!',
		img: ledogenerator,
		alt: 'ремонт льдогенераторов',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта льдогенераторов',
		price: [
			{
				id: 1,
				title: 'Течёт',
				price: '16 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Сильный шум',
				price: '23 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Нет воды',
				price: '18 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не выдаёт лёд',
				price: '26 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Не выключается',
				price: '15 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},

		],
		content: [
			{
				h4: 'Ремонт льдогенератора в Минске',
				p: `
				Вы ищете надежный и качественный ремонт льдогенераторов в Минске? Наша компания специализируется на обслуживании и ремонте льдогенераторов различных марок и моделей. Независимо от того, требуется ли вам ремонт, профилактика или замена деталей, наши профессионалы готовы предоставить вам высококлассные услуги.

Ремонт льдогенераторов - это сложный процесс, который требует специальных навыков и знаний. Наши техники обладают богатым опытом в области ремонта льдогенераторов и знают все тонкости и особенности работы этих устройств. Мы используем только качественные запчасти и современное оборудование, чтобы гарантировать, что ваш льдогенератор будет работать на высшем уровне после нашего вмешательства.

Один из наиболее распространенных вопросов, с которыми сталкиваются владельцы льдогенераторов, - это неправильное формирование или отсутствие льда. Если ваш льдогенератор не производит достаточное количество льда или качество льда оставляет желать лучшего, наши специалисты проведут диагностику и определят причину проблемы. Мы тщательно изучим каждый аспект вашего льдогенератора, включая его компоненты, температуру, настройки и системы охлаждения, чтобы найти и устранить любые неисправности.

Наша компания также предлагает профилактическое обслуживание для льдогенераторов. Регулярное техническое обслуживание помогает предотвратить возможные поломки и сбои в работе вашего устройства. Мы проведем осмотр вашего льдогенератора, очистим его от накипи и грязи, проверим электрические компоненты и настройки, а также убедимся, что системы охлаждения работают эффективно. Это позволит вам быть уверенными в надежности и безопасности вашего льдогенератора.

В случае необходимости замены деталей, наши специалисты подберут оригинальные запчасти, которые идеально подойдут для вашей модели льдогенератора. Мы гарантируем качество и долговечность замененных компонентов. Мы работаем только с проверенными поставщиками, чтобы убедиться, что вы получаете только оригинальные запчасти высокого качества.

Наша компания также предлагает гибкое расписание обслуживания, чтобы удовлетворить ваши потребности. Мы понимаем, что неполадки с льдогенератором могут возникнуть в любое время, поэтому мы готовы прийти на помощь в удобное для вас время. Наша команда техников всегда готова ответить на ваши вопросы и предоставить профессиональные консультации относительно использования и ухода за вашим льдогенератором.

Когда дело касается ремонта льдогенераторов в Минске, мы предлагаем надежность, опыт и отличное обслуживание. Мы гордимся своей репутацией и стремимся удовлетворить каждого нашего клиента. Наша цель - обеспечить безупречную работу вашего льдогенератора и продлить его срок службы.

Не откладывайте ремонт своего льдогенератора на потом. Если у вас возникли проблемы с работой устройства или требуется профилактика, обратитесь к нам. Мы гарантируем качественное обслуживание, быстрый ремонт и доступные цены. Позвольте нашим опытным специалистам восстановить работоспособность вашего льдогенератора и обеспечить его надежную работу.

Свяжитесь с нами уже сегодня, чтобы запланировать ремонт или профилактику вашего льдогенератора. Наша команда с радостью примет вызов и обеспечит вас высококлассным обслуживанием, которое вы заслуживаете. Не теряйте время, обратитесь к нам и доверьте нам свой льдогенератор. Мы сделаем все возможное, чтобы вернуть его в идеальное состояние и удовлетворить все ваши потребности в ремонте и обслуживании.
`
			},
			{
				h4: 'Стоимость ремонта льдогенератора в Минске',
				p: `
				Когда речь идет о ремонте льдогенераторов в Минске, многие люди интересуются вопросом стоимости. Определение стоимости ремонта льдогенератора может варьироваться в зависимости от различных факторов, включая марку и модель устройства, характер поломки, требуемые запчасти и дополнительные услуги.

Понимая, что стоимость ремонта является важным фактором при принятии решения, мы стремимся предложить конкурентные и разумные цены на наши услуги по ремонту льдогенераторов. Наша компания ориентирована на прозрачность и честность в формировании стоимости, поэтому вы можете рассчитывать на объективную и справедливую оценку.

Для определения точной стоимости ремонта льдогенератора, наша команда опытных техников произведет диагностику и оценку поломки. Мы тщательно исследуем состояние вашего льдогенератора, выявим причину неисправности и предоставим вам подробную информацию о необходимых работах и замене запчастей, если это требуется. Это позволит нам сформировать точную стоимость ремонта, исходя из конкретных требований вашего устройства.

Однако, важно понимать, что стоимость ремонта льдогенераторов может варьироваться в зависимости от сложности поломки и доступности необходимых запчастей. Если поломка льдогенератора требует замены дорогих компонентов или специализированных запчастей, это может повлиять на окончательную стоимость ремонта. Тем не менее, наша цель - предложить вам наиболее оптимальное и экономичное решение, чтобы восстановить работоспособность вашего льдогенератора.

Кроме того, стоимость ремонта льдогенераторов также может зависеть от дополнительных услуг, которые могут быть предоставлены. Например, профилактическое обслуживание, чистка, настройка и регулировка системы охлаждения могут быть включены в общую стоимость ремонта. Эти дополнительные услуги могут помочь улучшить работу льдогенератора и предотврат ить возможные будущие поломки, обеспечивая более длительный срок службы вашего льдогенератора.

Чтобы узнать конкретную стоимость ремонта льдогенератора в Минске, рекомендуется обратиться к нашей компании для проведения диагностики. Мы предлагаем бесплатную предварительную оценку стоимости ремонта, чтобы вы могли принять информированное решение.

Как только наши техники проведут диагностику и определят проблему, мы предоставим вам детализированную смету на ремонт. В этом предложении будут указаны все расходы, включая стоимость необходимых запчастей, работы и любых дополнительных услуг, которые могут потребоваться для полного восстановления работоспособности вашего льдогенератора.

Помимо этого, мы также предлагаем гарантию на все выполненные ремонтные работы и установленные запчасти. Это гарантирует вам спокойствие и уверенность в качестве нашей работы. Если после ремонта возникнут какие-либо проблемы, вы можете обратиться к нам, и мы бесплатно исправим неполадки.

Не стоит откладывать ремонт льдогенератора из-за страха высокой стоимости. Мы стремимся предложить доступные и конкурентоспособные цены, не идя на компромисс с качеством. Ваш льдогенератор играет важную роль в повседневной жизни, поэтому важно обеспечить его правильное функционирование и сохранить долговечность.

Обращайтесь к нам, чтобы получить профессиональную помощь и узнать точную стоимость ремонта льдогенераторов в Минске. Мы с готовностью ответим на все ваши вопросы и предоставим вам высококлассное обслуживание, чтобы ваш льдогенератор снова работал на полную мощность.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт льдогенератора именно в нашей компании',
				p: `
				Одним из важных аспектов при ремонте льдогенераторов в Минске является выбор квалифицированных мастеров, которые обладают опытом и знаниями в этой области. В нашей компании мы гордимся нашей командой высококвалифицированных техников, которые специализируются на ремонте льдогенераторов и обладают всеми необходимыми навыками для эффективного восстановления работоспособности вашего устройства.

Одним из основных преимуществ наших мастеров является их опыт в ремонте льдогенераторов различных марок и моделей. У нас работают специалисты, которые имеют многолетний опыт работы с различными типами льдогенераторов, и они хорошо знакомы со всеми особенностями и проблемами, с которыми можно столкнуться в процессе ремонта. Благодаря этому, мы можем предложить качественное и профессиональное обслуживание для любой модели льдогенератора.

Еще одно преимущество наших мастеров - это их обширные знания и навыки в диагностике и ремонте льдогенераторов. Они владеют современными методиками диагностики, что позволяет им быстро и точно определить причину поломки. Это важно для минимизации времени простоя вашего льдогенератора и быстрого восстановления его работоспособности. Наши мастера также обладают навыками в работе с специализированным оборудованием и инструментами, что позволяет им проводить эффективные ремонтные работы.

Кроме того, наши мастера всегда следуют высоким стандартам качества во время ремонта льдогенераторов. Мы используем только оригинальные запчасти высокого качества, чтобы обеспечить долговечность и надежность вашего устройства после ремонта. Мы также уделяем внимание каждой детали процесса ремонта, чтобы гарантировать, что все компоненты вашего льдогенератора находятся в идеальном состоянии.

Наши мастера также предлагают профессиональные консультации по обслуживанию и эксплуатации льдогенератора. Они с радостью поделятся своими знаниями и советами о том, как правильно ухаживать за вашим устройством, чтобы предотвратить возможные поломки в будущем. Вы получите рекомендации по регулярной чистке и обслуживанию льдогенератора, оптимальной температуре и настройке, а также советы по энергосбережению.

Еще одно преимущество наших мастеров - это их внимательное и индивидуальное отношение к каждому клиенту. Мы понимаем, что проблемы с льдогенератором могут быть не только техническими, но и вызывать неудобства в повседневной жизни. Наша команда старается учесть все ваши требования и запросы, чтобы предложить наиболее подходящее решение. Мы всегда готовы выслушать ваши вопросы и предоставить исчерпывающие ответы, чтобы вы чувствовали себя уверенно и удовлетворенно результатами нашей работы.

Кроме того, мы стремимся обеспечить максимальную удобство для наших клиентов. Мы предлагаем гибкое расписание обслуживания, чтобы соответствовать вашим потребностям и графику. Вы можете выбрать удобное время для визита нашего мастера, и мы приедем к вам в назначенное время. Мы также предлагаем быстрый ремонт, чтобы минимизировать время простоя вашего льдогенератора и восстановить его работу как можно скорее.

Таким образом, выбирая нашу компанию для ремонта льдогенераторов в Минске, вы можете быть уверены в качестве и профессионализме наших мастеров. Мы предлагаем опытных специалистов, использующих передовые методы и оригинальные запчасти, чтобы обеспечить эффективный и надежный ремонт вашего льдогенератора. Свяжитесь с нами уже сегодня, и мы с радостью поможем вам восстановить работоспособность вашего льдогенератора и обеспечим его долговечность на долгие годы.
				`
			},

		]
	},
]

export const dataMorozilnik = [
	{
		id: 3,
		h1: 'Ремонт морозильников в Минске',
		p: 'Преимуществом наших мастеров по ремонту морозильников является их профессионализм и опыт. Они владеют глубокими знаниями в области техники и специализируются на ремонте морозильников различных марок и моделей. Независимо от сложности проблемы, наши мастера быстро и эффективно выявят неисправность и предложат наилучший вариант ремонта. Они оснащены современными инструментами и используют только качественные запчасти, чтобы обеспечить долгосрочную и надежную работу морозильника после ремонта. Наша команда гарантирует качественное обслуживание и стремится удовлетворить потребности каждого клиента. Доверьте ремонт своего морозильника нашим профессионалам и наслаждайтесь его бесперебойной работой!',
		description: 'Надежный ремонт морозильников с гарантией качества. Наши опытные мастера быстро устранят любые неисправности, восстановят функциональность вашего устройства. Качественные запчасти и профессиональный подход обеспечат долгосрочную работу. Доверьте свой морозильник профессионалам и наслаждайтесь его безупречной работой. Закажите ремонт сейчас и верните своему морозильнику жизнь!',
		img: morozilnik,
		alt: 'ремонт морозильников',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта морозильника',
		price: [
			{
				id: 1,
				title: 'Подтекает',
				price: '18 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Нет холода',
				price: '20 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Щёлкает',
				price: '22 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не выключается',
				price: '15 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},

		],
		content: [
			{
				h4: 'Качественный ремонт морозильника',
				p: `
				Качественный ремонт морозильника: восстановление функциональности и надежности вашего устройства

				Морозильник - это неотъемлемая часть нашей бытовой техники, которая обеспечивает сохранность и свежесть наших продуктов. Однако, со временем даже самые надежные и качественные морозильники могут столкнуться с проблемами и требовать ремонта. Как же найти надежных специалистов, которые справятся с задачей ремонта морозильника?
				
				Наша компания предлагает качественный ремонт морозильников, основанный на многолетнем опыте и профессиональном подходе. Мы понимаем, что неисправности могут возникнуть в самых разных частях морозильника - от компрессора и системы охлаждения до электронных компонентов и механизмов. Наши опытные мастера обладают глубокими знаниями в области ремонта морозильников различных марок и моделей. Они точно определят причину неисправности и предложат оптимальное решение для ее устранения.
				
				Наша компания оснащена современными инструментами и оборудованием, необходимыми для профессионального ремонта морозильников. Мы также работаем только с качественными запчастями, чтобы обеспечить долгосрочную и надежную работу вашего устройства после ремонта. Наш приоритет - обеспечить вам безупречную работу морозильника и сохранность ваших продуктов.
				
				Одним из ключевых преимуществ нашей компании является наше отношение к клиентам. Мы ценим каждого клиента и стремимся обеспечить высокий уровень обслуживания. Наша команда оперативно откликается на заявки, предлагает гибкий график ремонта, чтобы соответствовать вашим потребностям. Мы также обеспечиваем прозрачную ценовую политику, предоставляя честные и разумные цены за наши услуги. Ваше доверие - наша главная ценность, и мы делаем все возможное, чтобы оправдать его.
				
				Кроме того, мы предлагаем гарантию на выполненный ремонт морозильников. Мы уверены в качестве нашей работы и обеспечиваем, что ваш морозильник будет работать надежно и без сбоев после нашего вмешательства. Если в течение гарантийного периода возникнут какие-либо проблемы, мы с удовольствием исправим их без дополнительных затрат.

				Кроме ремонта, мы также предлагаем профилактическое обслуживание морозильников. Регулярное техническое обслуживание позволяет предотвратить возможные поломки и продлить срок службы вашего устройства. Наши эксперты проведут проверку и очистку всех важных компонентов морозильника, убедятся в правильной работе системы охлаждения и регулировке температуры.
				
				Качественный ремонт морозильников - это наша специализация, и мы гордимся своими достижениями и доверием, которое нам оказывают наши клиенты. Мы стремимся сделать ремонт морозильников максимально удобным и безопасным для вас. Наша цель - обеспечить вам комфортную и бесперебойную работу морозильника, чтобы вы могли наслаждаться свежими продуктами в любое время.
				
				Если ваш морозильник нуждается в ремонте или профилактике, не откладывайте обращение к профессионалам. Свяжитесь с нами прямо сейчас и доверьте ваш морозильник в надежные руки наших опытных мастеров. Мы гарантируем высокое качество работы, профессиональный подход и индивидуальный подход к каждому клиенту. Восстановим функциональность вашего морозильника и обеспечим его надежную работу на долгие годы.
`
			},
			{
				h4: 'Стоимость ремонта морозильника',
				p: `
				Столкнувшись с поломкой холодильника или морозильника, многие из нас задаются вопросом о стоимости ремонта и поиском надежной компании, предлагающей качественные услуги по доступной цене. В Минске существует множество сервисных центров, но не все из них гарантируют надежный ремонт по адекватной стоимости.

				Когда дело касается стоимости ремонта морозильника, следует учитывать несколько факторов. Во-первых, это сложность поломки. В зависимости от причины неисправности - проблемы с компрессором, системой охлаждения, электроникой или другими компонентами - стоимость ремонта может варьироваться. Требуется тщательный анализ и диагностика со стороны профессионалов, чтобы определить точную причину поломки и оценить объем работ.
				
				Во-вторых, марка и модель морозильника также могут влиять на стоимость ремонта. Более распространенные и популярные бренды могут иметь доступные и стандартные запчасти, что снижает стоимость ремонта. Однако, если ваш морозильник имеет редкую марку или старую модель, возможно придется заплатить больше за запчасти или затруднить поиск необходимых компонентов.
				
				Третий фактор, влияющий на стоимость ремонта морозильника, - это выбор сервисного центра. В Минске есть множество компаний, предлагающих услуги по ремонту морозильников, и каждая из них имеет свою ценовую политику. Важно выбрать надежного и опытного специалиста, который предоставит качественный ремонт по справедливой цене.
				
				Чтобы узнать стоимость ремонта морозильника в Минске, рекомендуется обратиться к нескольким сервисным центрам и запросить предварительные сметы. Объясните им проблему и предоставьте все необходимые детали, чтобы получить более точную оценку. Важно обратить внимание не только на стоимость ремонта, но и на репутацию и отзывы о компании. Выберите надежного специалиста, который предложит адеквватную стоимость и гарантирует качественный ремонт вашего морозильника.

				Наша компания предлагает профессиональный ремонт морозильников в Минске по разумным ценам. Мы понимаем важность функционирования вашего холодильника и стремимся обеспечить максимально доступные условия для восстановления его работы. Наша команда опытных техников оснащена необходимыми знаниями и инструментами, чтобы выявить и исправить любую поломку вашего морозильника.
				
				Мы предлагаем прозрачную систему ценообразования, основанную на сложности и объеме работ. Вы можете быть уверены, что стоимость ремонта морозильника будет справедливой и без скрытых дополнительных платежей. Наша цель - предоставить вам оптимальное соотношение цены и качества.
				
				Однако, низкая стоимость ремонта не означает компромисс в качестве работы. Мы не экономим на использовании оригинальных и качественных запчастей. Мы сотрудничаем с проверенными поставщиками, чтобы гарантировать долгосрочную и надежную работу вашего морозильника после ремонта.
				
				Кроме того, мы ценим ваше время и комфорт. Поэтому мы стараемся быть максимально гибкими и предлагаем удобный график работы. Наши мастера оперативно реагируют на ваши заявки и приезжают в назначенное время. Мы стараемся выполнить ремонт морозильника быстро и эффективно, чтобы вы могли воспользоваться им в кратчайшие сроки.
				
				Если вы ищете надежную компанию, предлагающую качественный ремонт морозильников по разумной стоимости в Минске, обратитесь к нам. Мы гарантируем высокий уровень профессионализма, честность в ценообразовании и оперативность в решении ваших проблем. Восстановите работу вашего морозильника с нами и наслаждайтесь свежестью ваших продуктов!
				`
			},
			{
				h4: 'Почему лучше заказать ремонт морозильника именно в нашей компании',
				p: `
				Когда ваш морозильник выходит из строя, необходимо найти надежных и опытных мастеров, которые смогут вернуть устройству его функциональность и надежность. В Минске существует множество сервисных центров, но как выбрать техников, на которых можно положиться? Давайте рассмотрим преимущества наших мастеров, осуществляющих ремонт морозильников.

Профессиональные навыки и опыт: Наши мастера обладают глубокими знаниями и большим опытом в области ремонта морозильников. Они прошли обучение и постоянно совершенствуют свои навыки, чтобы быть в курсе последних технологий и тенденций в ремонте морозильников различных марок и моделей. Благодаря своим знаниям и опыту, наши мастера быстро и точно определяют причину поломки и предлагают эффективные решения.

Использование профессионального оборудования: Для качественного ремонта морозильников необходимо иметь доступ к современному и специализированному оборудованию. Наши мастера оснащены передовыми инструментами и диагностическим оборудованием, которые позволяют проводить точную диагностику и профессиональный ремонт морозильников. Это обеспечивает высокую точность и эффективность работ, а также сокращает время ремонта.

Использование оригинальных запчастей: Мы понимаем важность качественных запчастей при ремонте морозильников. Поэтому наши мастера используют только оригинальные и сертифицированные запчасти, предоставляемые производителями. Это гарантирует надежность и долговечность после ремонта. Мы не экономим на качестве, чтобы обеспечить вам долгосрочное и безотказное функционирование вашего морозильника.

Индивидуальный подход и внимание к клиентам: В нашей компании мы ценим каждого клиента и предлагаем индивидуальный подход к его потребностям. Наша команда слушает вас внимательно, выясняет деталипроблемы с морозильником и предлагает наилучшие решения. Мы понимаем, что каждая поломка уникальна, поэтому наши мастера подходят к каждому случаю индивидуально, проводят тщательную диагностику и предлагают оптимальные варианты ремонта.

Гарантия качества: Мы уверены в качестве работы наших мастеров и предоставляем гарантию на все выполненные ремонтные работы. Если в течение гарантийного периода возникнут проблемы, связанные с ремонтом морозильника, наши мастера бесплатно устранят их. Ваше удовлетворение и доверие к нашей компании - наша первоочередная задача.

Конкурентные цены: Мы стремимся предложить нашим клиентам наиболее конкурентные цены на ремонт морозильников в Минске. Мы понимаем, что ремонт может стать неожиданной финансовой нагрузкой, поэтому мы стараемся сделать наши услуги доступными и справедливыми. Мы предоставляем прозрачные сметы, где детально указываются расценки на работы и запчасти, чтобы вы всегда знали, за что платите.

Быстрый и удобный сервис: Мы ценим ваше время и комфорт, поэтому предлагаем быстрый и удобный сервис ремонта морозильников. Мы стараемся оперативно реагировать на заявки наших клиентов и быстро оказывать помощь. Наша команда мастеров готова выехать к вам в удобное для вас время, чтобы провести ремонт на месте или забрать морозильник в сервисный центр, если это необходимо.

Если у вас возникли проблемы с морозильником, не откладывайте ремонт. Обратитесь к нам, и наши опытные мастера сделают все возможное, чтобы ваш морозильник был в отличном рабочем состоянии. Мы гарантируем высокое качество ремонта, профессионализм и индивидуальный подход к каждому клиенту. Не рискуйте и доверьте свой морозильник опытным специалистам!
				`
			},

		]
	},
]

export const dataKondicioner = [
	{
		id: 4,
		h1: 'Ремонт кондиционеров в Минске',
		h2: 'Плановое обслуживание кондиционеров в Минске',
		p: 'Преимущество наших мастеров в ремонте и обслуживании кондиционеров заключается в их профессионализме и опыте. Они оснащены необходимыми знаниями и инструментами для диагностики и исправления любых неисправностей. Наша команда оперативно реагирует на вызовы клиентов и предлагает быстрое и качественное обслуживание. Мы гарантируем точность диагностики и эффективное устранение проблемы. Благодаря нашим мастерам, ваш кондиционер будет в идеальном состоянии, обеспечивая вам комфорт и прохладу в жаркое время. Доверьте ремонт кондиционера нам и наслаждайтесь его бесперебойной работой.',
		description: 'Восстановите работу кондиционера с нами! Профессиональный ремонт, быстрая диагностика и эффективное устранение неисправностей. Гарантируем комфорт в жару. Обращайтесь прямо сейчас!',
		img: kondicioner,
		alt: 'ремонт кондиционеров',
		price_title: 'Стоимость ремонта кондиционеров',
		price: [
			{
				id: 1,
				title: 'Течёт вода',
				price: '20 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Шумит',
				price: '18 руб',
				time: '1-2.5 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Неприятный запах',
				price: '22 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Треск',
				price: '22 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Не выключается',
				price: '15 руб',
				time: '1.5-2 часа',
				warranty: 'до 2-х лет',
			},
		],
		isActive: true,
		h3: 'Проведение планового обслуживания кондиционеров',
		p2: 'Наша компания предлагает услугу планового технического обслуживания бытовых и промышленных кондиционеров. Специалисты проводят комплекс работ по поддержанию исправности и работоспособности систем кондиционирования воздуха. Регулярное обслуживание продлевает срок службы оборудования и обеспечивает энергоэффективность его работы.',
		h5: 'В плановое ТО входит:',
		text: [
			'Проверка и очистка воздушных фильтров - Фильтры нужно регулярно проверять и очищать от пыли и грязи, чтобы обеспечить эффективную работу кондиционера.',
			'Проверка дренажной системы - Нужно убедиться, что конденсат беспрепятственно отводится через дренажную трубку.',
			'Чистка внутреннего и наружного блоков - Внутренние и наружные части кондиционера необходимо прочищать от грязи и пыли.',
			'Проверка уровня фреона - Нужно следить за уровнем хладагента в системе и своевременно дозаправлять при необходимости.',
			'Проверка электрических соединений - Важно проверить надежность контактов в электрических разъемах.',
			'Чистка теплообменников - От эффективности теплообменников зависит производительность кондиционера.',
			'Диагностика компрессора - Компрессор нуждается в регулярном осмотре и диагностике.',
			'Проверка работы вентиляторов - Вентиляторы должны вращаться плавно, без лишнего шума.',
		],
		img2: [plan, plan2],
		content: [
			{
				h4: 'Качественный ремонт кондиционера',
				p: `
				В Минске найдется множество предприятий и мастерских, занимающихся ремонтом кондиционеров. Но если вам нужна надежная и профессиональная команда, способная выполнить все работы своевременно и качественно, то мы — идеальный выбор.

				Наш сервис по ремонту кондиционеров предлагает широкий спектр услуг, включая диагностику, ремонт и обслуживание. Наши опытные специалисты обладают глубокими знаниями и богатым опытом в области ремонта кондиционеров разных марок и моделей.
				
				Когда вы обращаетесь к нам, мы тщательно анализируем причину поломки и проводим точную диагностику. Затем мы предоставляем вам подробную информацию о необходимых ремонтных работах и ориентировочную стоимость.
				
				Наша команда профессионалов обладает всеми необходимыми навыками и инструментами, чтобы выполнить ремонт вашего кондиционера в срок. Мы используем только качественные запчасти и материалы, чтобы обеспечить долговечность и надежность восстановленного оборудования.
				
				Важно отметить, что мы предлагаем не только ремонт кондиционеров, но и регулярное обслуживание, которое поможет вам предотвратить поломки и продлить срок службы вашего устройства. Регулярная проверка фильтров, чистка системы и заправка хладагента позволят вашему кондиционеру работать на максимальной эффективности.
				
				Мы понимаем, что ремонт кондиционера может быть неожиданным расходом. Поэтому мы стремимся предложить нашим клиентам конкурентоспособные цены без скрытых дополнительных расходов.
				
				Наше преимущество заключается не только в высоком качестве услуг, но и в отличном обслуживании клиентов. Мы всегда готовы ответить на ваши вопросы, предоставить консультацию и сделать все возможное, чтобы удовлетворить ваши потребности.
				
				Так что, если у вас возникла необходимость в ремонте кондиционера в Минске, не стесняйтесь обращаться к нам. Мы гарантируем качественный и надежный ремонт, который вернет ваш кондиционер к жизни. Не откладывайте ремонт на потом, ведь неполадки могут привести к еще более серьезным проблемам и дополнительным расходам. Доверьтесь нашим специалистам, которые обладают глубокими знаниями и опытом в области ремонта кондиционеров.

				Мы гарантируем быстрое реагирование на заявки клиентов и оперативное выполнение ремонтных работ. Наша команда оснащена современным оборудованием, что позволяет нам диагностировать неисправности точно и быстро. Мы стремимся минимизировать время простоя вашего кондиционера, возвращая его в идеальное состояние в кратчайшие сроки.
				
				При обслуживании вашего кондиционера мы используем только качественные запчасти, чтобы обеспечить надежность и долговечность его работы. Мы также предлагаем гарантию на все выполненные работы, чтобы вы могли быть уверены в качестве нашей работы.
				
				Не забывайте, что регулярное обслуживание кондиционера играет ключевую роль в его долговечности и эффективности. Мы предлагаем программы обслуживания, которые включают чистку фильтров, проверку работоспособности компонентов и профилактическую замену деталей. Такое обслуживание поможет вам избежать возможных поломок и сэкономить на ремонте в долгосрочной перспективе.
				
				Не ждите, пока ваш кондиционер перестанет работать полностью. Обратитесь к нам уже сегодня и дайте нам возможность вернуть комфорт и прохладу в вашу жизнь. Мы готовы взяться за любую задачу по ремонту кондиционера в Минске.
`
			},
			{
				h4: 'Стоимость ремонта кондиционера',
				p: `
				Столица Беларуси, Минск, предлагает разнообразные варианты ремонта кондиционеров по доступной стоимости. Когда возникают неполадки с вашим кондиционером, первое, что волнует каждого владельца - это вопрос о стоимости ремонта. Мы понимаем эту проблему и стремимся предложить своим клиентам прозрачные и адекватные цены.

				В стоимость ремонта кондиционера в Минске входит ряд факторов, таких как тип и марка устройства, объем работ, необходимые запчасти и детали. Для начала наши опытные мастера проведут диагностику поломки, чтобы определить причину неисправности. После этого они подробно обсудят с вами обнаруженные проблемы и рекомендации по ремонту.
				
				Мы стараемся установить справедливые и конкурентоспособные цены, отражающие сложность работ и использование качественных материалов. Мы стремимся предложить клиентам оптимальное соотношение цены и качества. Важно отметить, что мы не скрываем никаких дополнительных расходов и всегда предоставляем подробную информацию о стоимости каждой части работы.
				
				Помимо ремонта, наша компания предлагает также программы обслуживания кондиционеров, что поможет вам избежать крупных поломок и увеличить срок службы вашего устройства. Стоимость таких программ будет зависеть от их содержания, включая регулярную чистку фильтров, проверку работоспособности компонентов и профилактическую замену деталей.
				
				Когда дело доходит до ремонта кондиционера, важно помнить, что качество услуг и опыт мастеров играют ключевую роль. Наши специалисты обладают не только глубокими знаниями, но и большим опытом в ремонте кондиционеров разных марок и моделей. Мы уделяем внимание каждой детали и работаем с высокой степенью внимательности и профессионализма.
				
				Если вы интересуетесь стоимостью ремонта кондиционера в Минске, обратитесь к нам для получения бесплатной консультации и оценки стоимости. Мы готовы ответить на все ваши вопросы и предоставить детальную информацию о наших услугах. Мы также предлагаем гибкую систему оплаты, чтобы сделать ремонт доступным для каждого клиента.
				
				Наша компания ценит доверие и удовлетворенность клиентов, поэтому мы гарантируем качество выполняемых работ и предоставляем гарантию на все ремонтные работы. Это позволяет вам быть уверенными в надежности и долговечности восстановленного кондиционера.
				
				Не откладывайте ремонт кондиционера на потом. Неполадки могут привести к более серьезным проблемам и повысить стоимость восстановления. Обратитесь к нам прямо сейчас, чтобы получить профессиональный ремонт кондиционера по адекватной стоимости.
				
				Мы готовы принять вызов любой сложности и вернуть вашему кондиционеру жизнь. Доверьтесь нашим опытным мастерам и наслаждайтесь прохладой и комфортом в вашем доме или офисе. Свяжитесь с нами сегодня, чтобы узнать более подробную информацию о стоимости ремонта кондиционера в Минске и сделать первый шаг к восстановлению вашего устройства.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт кондиционера именно в нашей компании',
				p: `
				Когда дело касается ремонта кондиционера в Минске, выбор опытных и надежных мастеров играет решающую роль. Наша компания гордится своей командой профессионалов, которые обладают обширными знаниями и опытом в сфере ремонта кондиционеров.

				Одним из главных преимуществ наших мастеров является их экспертиза. Они владеют глубокими знаниями о различных типах кондиционеров и понимают все аспекты их работы. Благодаря этому, они могут быстро и точно определить причину поломки и предложить наилучшие решения для ее устранения.
				
				Наша команда также обладает широким спектром навыков и умений, необходимых для выполнения различных ремонтных работ. Они в совершенстве владеют инструментами и оборудованием, чтобы эффективно и точно провести диагностику, замену деталей, чистку и ремонт системы кондиционирования.
				
				Мы ценим время наших клиентов, поэтому наши мастера работают быстро и эффективно. Они понимают, как важно иметь работающий кондиционер в жаркую летнюю погоду, поэтому они прилагают все усилия, чтобы устранить неисправности в кратчайшие сроки.
				
				Еще одним преимуществом наших мастеров является их профессионализм и преданность качеству. Они всегда следуют лучшим практикам и используют только высококачественные запчасти и материалы. Такой подход гарантирует, что ремонт будет надежным и долговечным.
				
				Мы также предлагаем гарантию на все наши работы, что демонстрирует наше доверие в качество предоставляемых услуг. Если после ремонта возникнут какие-либо проблемы, наши мастера с радостью исправят их без дополнительной оплаты.
				
				Наши мастера всегда готовы предоставить консультацию и ответить на все ваши вопросы относительно ремонта кондиционера. Мы стремимся установить доверительные отношения с нашими клиентами и создать комфортную и безопасную атмосферу.
				Безопасность и надежность - еще два преимущества, которыми отличаются наши мастера. Мы придерживаемся всех необходимых стандартов и процедур, чтобы гарантировать безопасность во время ремонта кондиционера. Наши мастера также проходят регулярные обучения и обновляют свои навыки, чтобы быть в курсе последних технологических разработок и инноваций в области ремонта кондиционеров.

Мы понимаем, что ремонт кондиционера может быть непредвиденной финансовой нагрузкой. Поэтому мы предлагаем конкурентоспособные цены и гибкую систему оплаты. Мы стремимся предложить клиентам максимально выгодные условия и адекватные цены, не идя на компромисс с качеством предоставляемых услуг.

Кроме того, мы ценим каждого нашего клиента и предлагаем индивидуальный подход к каждой ситуации. Мы всегда слушаем ваши потребности и ожидания, чтобы предложить наилучшее решение для ремонта кондиционера. Наша цель - обеспечить полное удовлетворение и комфорт каждого клиента.

Если вы ищете профессиональный и надежный ремонт кондиционера в Минске, наши мастера готовы помочь вам. Не стоит откладывать ремонт на потом, ведь неполадки могут привести к более серьезным проблемам. Обратитесь к нам сегодня и доверьте ремонт вашего кондиционера опытным профессионалам. Мы гарантируем качество, безопасность и надежность наших услуг.
				`
			},

		]
	},
]

export const dataStiralnyeMashiny = [
	{
		id: 5,
		h1: 'Ремонт стиральной машины в Минске',
		p: 'Преимущество наших мастеров в ремонте стиральных машин заключается в их опыте и профессионализме. Мы понимаем, что неработающая стиральная машина может создать много неудобств в вашей повседневной жизни, поэтому наши мастера всегда приезжают вовремя и оперативно находят причину поломки. Они обладают глубокими знаниями и навыками, чтобы провести диагностику, ремонт и обслуживание любых моделей стиральных машин. Наша команда также предлагает честные цены, прозрачные сметы и гарантию на все выполненные работы. Доверьте ремонт своей стиральной машины нам и наслаждайтесь безупречным сервисом!',
		description: 'Восстановите работу своей стиральной машины с нами! Мы - профессиональная компания, специализирующаяся на ремонте всех типов стиральных машин. Наши опытные мастера быстро и надежно устранят любые поломки. Мы предлагаем конкурентные цены, прозрачные условия и гарантию на все работы. Не теряйте время, свяжитесь с нами прямо сейчас и верните своей стиральной машине бесперебойную работу!',
		img: stiralnyemashiny,
		alt: 'ремонт стиральной машины',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта стиральной машины',
		price: [
			{
				id: 1,
				title: 'Не греет воду',
				price: '20 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не сливает',
				price: '18 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Шумит',
				price: '21 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не крутит барабан',
				price: '25 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Прыгает',
				price: '19 руб',
				time: '2-4 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Не начинает стирку',
				price: '20 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
		],
		content: [
			{
				h4: 'Качественный ремонт стиральной машины',
				p: `
				Качественный ремонт стиральной машины в Минске: восстановите надежность вашей техники!

				Время от времени каждому из нас приходится сталкиваться с поломками домашней бытовой техники, и в особенности с ремонтом стиральных машин. Это может быть настоящей головной болью, особенно если у вас нет опыта и знаний в этой области. Однако, не отчаивайтесь! Команда наших профессионалов готова предложить вам качественный ремонт стиральной машины в Минске.
				
				Наша компания специализируется на ремонте стиральных машин всех типов и моделей. Мы понимаем, что стиральная машина - это необходимый атрибут повседневной жизни и ее поломка может сильно нарушить обычный ритм домашних дел. Поэтому мы стремимся предоставить быструю, эффективную и надежную услугу ремонта, чтобы вы могли вернуться к комфортному использованию своей стиральной машины как можно скорее.
				
				Наша команда состоит из опытных и профессиональных мастеров, которые обладают глубокими знаниями в области ремонта стиральных машин. Они оснащены современным инструментом и технологиями, что позволяет им быстро обнаруживать и устранять неисправности. Независимо от того, нужна ли замена деталей, чистка системы, исправление электрических проблем или диагностика сложных поломок, наши мастера готовы решить любую задачу с высоким качеством и профессионализмом.
				
				Мы предлагаем широкий спектр услуг по ремонту стиральных машин. Наша компания обладает всем необходимым для проведения ремонта: запасными частями, инструментами и оборудованием. Мы сотрудничаем только с надежными поставщиками и поставляем только оригинальные запчасти, чтобы обеспечить долгосрочную надежность и функциональность вашей стиральной машины после ремонта.
				
				Важно отметить, что мы ценим ваше время и комфорт. Поэтому наши мастера готовы выехать к вам на дом в удобное для вас время. Мы осознаем, что ремонт стиральной машины может быть срочным и требовать быстрой реакции. Поэтому, наша команда старается обеспечить гибкость в расписании, чтобы найти подходящее время для ремонта, удобное именно для вас.

				В процессе работы наши мастера выполняют полную диагностику проблемы, чтобы точно определить причину поломки. Мы понимаем, что ремонт должен быть выполнен качественно и долговечно, поэтому мы не ограничиваемся простым устранением симптомов, а проводим комплексные меры по предотвращению повторных поломок.
				
				Наша компания гарантирует прозрачность и конкурентные цены на все виды ремонтных работ. Перед началом ремонта, мы предоставляем вам детальную оценку стоимости, чтобы вы могли принять информированное решение. Мы стремимся установить долгосрочные отношения с нашими клиентами, поэтому ваше доверие и удовлетворение являются для нас приоритетом.
				
				Если вам требуется ремонт стиральной машины в Минске, не стесняйтесь обращаться к нам. Мы гарантируем качественное обслуживание, профессиональный подход и быстрое исправление поломок. Восстановите работоспособность своей стиральной машины с помощью нашей надежной команды мастеров. Обратитесь к нам уже сегодня и верните своей стиральной машине бесперебойную работу!
`
			},
			{
				h4: 'Стоимость ремонта стиральной машины',
				p: `
				Столкнувшись с поломкой стиральной машины, одним из главных вопросов, который возникает у владельцев, является стоимость ремонта. Ведь, безусловно, желание каждого - получить качественное исправление проблемы по разумной цене. В Минске, наша компания предлагает прозрачные и конкурентоспособные цены на ремонт стиральных машин.

				Стоимость ремонта стиральной машины зависит от нескольких факторов, включая тип поломки, необходимость замены запчастей и времени, затраченного на работу. При обращении к нам, наши профессиональные мастера проведут полную диагностику проблемы, чтобы точно определить причину поломки и объяснить вам возможные варианты ремонта.
				
				Важно понимать, что дешевый ремонт не всегда означает качественный результат. Некоторые компании могут предложить низкие цены, но в итоге использовать непроверенные запчасти или неквалифицированный персонал. Это может привести к недолговечности ремонта и повторным поломкам в будущем. Мы же, в свою очередь, стремимся предоставить вам оптимальное соотношение цены и качества.
				
				Мы работаем напрямую с надежными поставщиками оригинальных запчастей, что гарантирует надежность и долговечность восстановленной стиральной машины после ремонта. Вместе с тем, наши мастера обладают богатым опытом и профессиональными навыками, позволяющими им быстро и точно устранять поломки различной сложности.
				
				Мы ценим доверие и удовлетворение наших клиентов, поэтому стараемся предоставить прозрачную оценку стоимости ремонта. Перед началом работ мы подробно обсудим с вами предварительную смету, чтобы вы были осведомлены о расходах и могли принять решение, соответствующее вашему бюджету.
				
				Важно отметить, что стоимость ремонта стиральной машины также зависит от срочности работ. Если вам требуется экстренный ремонт, возможно, будет взиматься дополнительная плата за оперативное рвыполнение работ. Однако, мы всегда стремимся найти оптимальное решение, чтобы удовлетворить потребности и возможности каждого клиента.

				Помимо этого, мы также предлагаем гарантию на все выполненные работы. Это означает, что в случае повторной поломки в течение определенного периода времени, мы бесплатно исправим проблему. Мы уверены в качестве наших услуг и стремимся обеспечить долгосрочную работу вашей стиральной машины.
				
				Наша компания ценит ваше время и комфорт, поэтому наши мастера готовы оперативно реагировать на вашу заявку. Мы стараемся установить гибкое расписание, чтобы найти удобное время для ремонта, соответствующее вашим потребностям.
				
				В итоге, стоимость ремонта стиральной машины в Минске зависит от множества факторов, но мы гарантируем, что наши цены будут честными и конкурентоспособными. Мы предлагаем профессиональный и качественный ремонт, используя оригинальные запчасти и опытных мастеров, чтобы ваша стиральная машина работала как новая.
				
				Не откладывайте ремонт стиральной машины на потом, ведь независимо от размера поломки, она может привести к еще более серьезным последствиям. Обращайтесь к нам сегодня и получите надежное и профессиональное восстановление вашей стиральной машины. Наша команда с удовольствием окажет вам помощь и обеспечит ваше спокойствие и комфорт в повседневной жизни.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт стиральной машины именно в нашей компании',
				p: `
				Когда речь заходит о ремонте стиральной машины в Минске, выбор надежных и опытных мастеров играет решающую роль. Ведь вам нужны профессионалы, которые смогут оперативно и качественно восстановить работоспособность вашей стиральной машины. Наша компания гордится своей командой мастеров, которые предлагают ряд преимуществ, делающих нас лучшим выбором для ремонта стиральной машины в Минске.

Первое и главное преимущество наших мастеров - это их богатый опыт в ремонте стиральных машин различных марок и моделей. Они обладают глубокими знаниями в области стиральной техники и понимают особенности каждого типа поломок. Благодаря этому, они могут быстро и точно определить причину неисправности и предложить эффективное решение.

Второе преимущество - это использование современного оборудования и инструментов. Мы постоянно обновляем наше техническое оснащение, чтобы быть в курсе последних технологий и методов ремонта. Это позволяет нашим мастерам проводить диагностику и исправление поломок с высокой точностью и эффективностью.

Еще одно преимущество наших мастеров - это доступность и гибкость. Мы понимаем, что поломка стиральной машины может возникнуть в любой момент, поэтому мы предлагаем быстрые и оперативные услуги ремонта. Наша команда готова выехать к вам на дом в удобное для вас время, чтобы минимизировать неудобства и восстановить работоспособность вашей стиральной машины как можно скорее.

Одно из ключевых преимуществ наших мастеров - это их профессионализм и ответственный подход к работе. Мы уделяем внимание каждой детали, чтобы убедиться, что ремонт выполнен правильно и долговечно. Наши мастера не только устраняют симптомы поломки, но и проводят комплексные меры по предотвращению повторных проблем, чтобы ваша стиральная машина продолжала работать без сбоев на долгое время.

Кроме того, мы гарантируем прозрачность и 
открытость в отношении стоимости ремонта. Перед началом работ, наши мастера предоставят вам детальную оценку затрат, чтобы вы точно знали, сколько будет стоить восстановление вашей стиральной машины. Мы стремимся предложить конкурентоспособные цены, при этом не снижая качества услуг.

Еще одно преимущество наших мастеров - это их доброжелательность и умение работать с клиентами. Мы понимаем, что ремонт стиральной машины может вызывать беспокойство и стресс, поэтому наши мастера всегда готовы выслушать ваши вопросы и проблемы. Мы стремимся создать доверительные отношения с нашими клиентами и обеспечить их удовлетворенность результатом.

Не откладывайте ремонт стиральной машины на потом. Обратитесь к нам сегодня, и наши опытные мастера с радостью помогут вам восстановить работоспособность вашей стиральной машины. Мы гарантируем качественный ремонт, доступные цены и превосходное обслуживание. Доверьте свою стиральную машину профессионалам и наслаждайтесь ее безупречной работой вновь.
				`
			},

		]
	},
]

export const dataSushilnyeMashiny = [
	{
		id: 6,
		h1: 'Ремонт сушильных машины в Минске',
		p: 'Преимущество наших мастеров в ремонте сушильных машин заключается в их профессионализме и опыте. Они обладают глубокими знаниями о технических аспектах сушилок и могут быстро определить и устранить любую неисправность. Благодаря своим навыкам и умениям, они эффективно восстанавливают работоспособность вашей сушилки, сокращая время простоя. Наши мастера также оснащены передовым инструментарием и имеют доступ к высококачественным запасным частям, что гарантирует долгосрочное и надежное восстановление вашей сушильной машины. Доверьтесь нашим профессионалам и наслаждайтесь безупречной работой вашей сушилки снова!',
		description: 'Надежный ремонт сушильных машин. Опытные мастера, качественные запчасти, быстрая работа. Восстановите функциональность вашей сушилки уже сегодня. Запишитесь на бесплатную консультацию!',
		img: sushilnyemashiny,
		alt: 'ремонт сушильной машины',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта сушильной машины',
		price: [
			{
				id: 1,
				title: 'Выдаёт ошибку',
				price: '18 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не начинает сушку',
				price: '20 руб',
				time: '1.5-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не сушит',
				price: '22 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не крутит барабан',
				price: '25 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
		],
		content: [
			{
				h4: 'Качественный ремонт сушильной машины',
				p: `
				
Вы и ваша сушильная машина столкнулись с проблемой? Не беспокойтесь, в Минске у вас есть надежный партнер для качественного ремонта сушильных машин. Наш сервисный центр специализируется на ремонте и обслуживании сушильных машин, обладая богатым опытом и экспертизой в этой области.

Наш подход к ремонту сушильной машины основан на индивидуальном и профессиональном обслуживании каждого клиента. Мы полностью осознаем, что неисправности могут возникнуть в самый неподходящий момент, поэтому наша цель - оперативно и эффективно восстановить работоспособность вашей сушилки.

Когда вы обращаетесь к нам, наша команда высококвалифицированных мастеров проводит тщательный анализ проблемы. Мы используем передовое оборудование и технологии, чтобы точно определить неисправность и предложить наилучшие решения.

Важно понимать, что каждая сушильная машина уникальна, и у нее могут быть различные проблемы. Независимо от того, возникли ли у вас проблемы с нагревом, вентиляцией, сенсорным экраном или другими компонентами, наши мастера обладают глубокими знаниями и опытом, чтобы справиться с любой неисправностью.

Мы осознаем, что качество ремонта является нашим приоритетом. Поэтому мы используем только оригинальные запчасти высокого качества, чтобы обеспечить долгосрочную и надежную работу вашей сушильной машины. Мы понимаем, что ремонт может вызывать тревогу, поэтому мы стараемся максимально упростить процесс для наших клиентов.

Кроме того, наш сервисный центр предлагает конкурентоспособные цены на ремонт сушильных машин. Мы стремимся предоставить нашим клиентам оптимальное соотношение цены и качества.

Если вам требуется ремонт сушильной машины в Минске, не стесняйтесь обратиться к нам. Мы гарантируем, что ваша сушильная машина будет находиться 
в надежных руках опытных и профессиональных мастеров. Мы стремимся обеспечить высокий уровень удовлетворенности каждого клиента, предоставляя качественный и надежный ремонт сушильных машин.

Наша компания также предлагает гарантию на выполненные работы, что подтверждает наше доверие к качеству и долговечности проведенного ремонта. Мы ценим ваше время и стараемся минимизировать простой сушильной машины, чтобы вы могли вернуться к обычному режиму работы как можно скорее.

Для вашего удобства мы предлагаем гибкие графики обслуживания, включая вечерние и выходные дни, чтобы соответствовать вашим потребностям. Мы ценим каждого клиента и стремимся установить долгосрочные отношения, основанные на взаимном доверии и профессионализме.

Не откладывайте ремонт сушильной машины на потом. Обращайтесь к нам уже сегодня и доверьте вашу сушильную машину нашим опытным мастерам. Мы готовы помочь вам вернуть комфорт и эффективность вашей сушилки.
`
			},
			{
				h4: 'Стоимость ремонта сушильной машины',
				p: `
				Когда ваша сушильная машина нуждается в ремонте, одним из главных вопросов, который вас интересует, является стоимость восстановления ее работоспособности. В Минске мы предлагаем прозрачную и конкурентоспособную стоимость ремонта сушильных машин, чтобы облегчить ваш выбор и предоставить вам высококачественное обслуживание по доступной цене.

Важно отметить, что стоимость ремонта сушильной машины может варьироваться в зависимости от нескольких факторов, включая тип неисправности, марку и модель вашей сушилки, а также доступность запчастей. Однако, наша компания стремится предложить наиболее привлекательные цены, чтобы сделать ремонт доступным для всех наших клиентов.

При обращении к нам с проблемой вашей сушильной машины, мы предоставляем бесплатную консультацию, в ходе которой наши опытные мастера проведут диагностику и определение неисправности. Исходя из этой информации, мы предоставим вам прозрачную оценку стоимости ремонта.

Мы ценим ваше время и соблюдаем строгие сроки выполнения работ, поэтому вы можете быть уверены, что ремонт будет проведен в минимально возможное время. Мы также используем только оригинальные запчасти, чтобы гарантировать долгосрочную надежность работы вашей сушильной машины.

Кроме того, наша компания предлагает гибкую систему оплаты, чтобы сделать ремонт еще более доступным для наших клиентов. Мы стремимся предоставить вам лучшее соотношение цены и качества, чтобы вы получили максимальную пользу от своей инвестиции в ремонт сушильной машины.

Мы понимаем, что каждая ситуация уникальна, поэтому мы призываем вас обратиться к нам для получения индивидуальной оценки стоимости ремонта вашей сушильной машины. Наша команда готова ответить на все ваши вопросы и предоставить вам подробную информацию о стоимости и объеме работ.

Независимо от проблемы, с которой вы столкнулись, наша цель - предоставить вам надежный и профессиональный ремонт сушильной машины по разумной цене. Мы предлагаем прозрачность и честность во всех аспектах нашей работы, включая стоимость ремонта.

Наша команда профессионалов стремится сделать процесс определения стоимости ремонта простым и понятным для вас. Мы проводим тщательную диагностику неисправности вашей сушильной машины и основываясь на результате, составляем подробную оценку стоимости работ. Мы учитываем все необходимые запчасти и необходимые трудозатраты, чтобы предоставить вам объективную и справедливую цену.

Однако, помимо стоимости ремонта, мы также гарантируем качество и надежность нашей работы. Мы используем только высококачественные оригинальные запчасти, чтобы обеспечить долгосрочную работу вашей сушильной машины. Наши опытные мастера обладают глубокими знаниями и профессиональными навыками, чтобы быстро и эффективно устранить любые неисправности.

Мы понимаем, что ремонт сушильной машины может быть неожиданным расходом, и поэтому мы предлагаем различные варианты оплаты и гибкую систему финансирования. Наша цель - сделать ремонт доступным и без лишнего финансового бремени для наших клиентов.

Если вы ищете надежную компанию для ремонта сушильной машины в Минске, обратитесь к нам. Мы гарантируем высокое качество работы, прозрачность в стоимости и отличное обслуживание. Не откладывайте ремонт, свяжитесь с нами сейчас и восстановите работоспособность вашей сушильной машины.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт сушильной машины именно в нашей компании',
				p: `
				Наша компания предлагает вам непревзойденное качество и надежность при ремонте сушильных машин в Минске. У нас работает команда опытных и профессиональных мастеров, специализирующихся именно на ремонте сушильных машин.

				Одним из наших основных преимуществ является глубокое понимание работы и устройства сушильных машин различных марок и моделей. Мы постоянно обновляем наши знания и следим за последними тенденциями и инновациями в индустрии, чтобы быть в курсе всех новых разработок и технических особенностей.
				
				Мы осознаем, что ремонт сушильной машины может быть сложной задачей, поэтому мы обеспечиваем наших мастеров передовым инструментом и оборудованием. Это позволяет нам проводить точную диагностику и эффективно устранять любые неисправности, с которыми вы столкнулись.
				
				Наша команда мастеров обладает обширным опытом в области ремонта сушильных машин. Мы регулярно проходим обучение и повышаем квалификацию, чтобы быть на переднем крае технологий и методов ремонта. Благодаря этому, мы можем предложить вам оптимальные и эффективные решения для восстановления работоспособности вашей сушильной машины.
				
				Мы понимаем, что ваша сушильная машина является важным элементом вашей бытовой техники, и вы хотите, чтобы ремонт был проведен быстро и безопасно. Мы гарантируем оперативное и своевременное выполнение работ, чтобы вы могли как можно скорее вернуться к обычной жизни без неудобств.
				
				Помимо качества работы, мы также обращаем особое внимание на обслуживание клиентов. Наша команда всегда готова выслушать ваши требования и вопросы, предоставить подробные консультации и рекомендации. Мы стремимся установить долгосрочные отношения с нашими клиентами, основанные на доверии и взаимном понимании.
				
				Когда вы обращаетесь к нам для ремонта сушильной машины, вы можете быть уверены, что получите высочайший уровень профессионализма и надежности. Мы гарантируем, что каждый ремонт сушильной машины будет проведен с максимальной тщательностью и вниманием к деталям.

				Одной из наших ключевых ценностей является прозрачность. Мы предоставляем честную оценку стоимости ремонта сушильной машины с учетом всех необходимых запчастей и трудозатрат. Никаких скрытых платежей или неприятных сюрпризов - мы стремимся к открытому и честному взаимодействию с нашими клиентами.
				
				Кроме того, мы осознаем значимость времени для наших клиентов. Поэтому мы стараемся выполнить ремонт сушильной машины в минимально возможные сроки, не затрачивая лишнее время. Мы понимаем, что вы полагаетесь на свою сушильную машину в повседневной жизни, поэтому наша цель - вернуть ее к вам как можно скорее, полностью функционирующей и готовой к использованию.
				
				Наша компания предлагает гарантию на выполненные работы, чтобы вы могли быть уверены в качестве и надежности ремонта сушильной машины. Если в течение гарантийного периода возникнут проблемы, мы с удовольствием исправим их без дополнительной оплаты.
				
				Не рискуйте своей сушильной машиной, обратитесь к нашим профессиональным мастерам, и мы обеспечим ее качественный ремонт. Сделайте правильный выбор и доверьте нам ремонт сушильной машины в Минске. Мы гарантируем высокий уровень сервиса, профессиональный подход и максимальную долговечность восстановленной техники. Свяжитесь с нами уже сегодня и восстановите работоспособность вашей сушильной машины в кратчайшие сроки!
				`
			},

		]
	},
]

export const dataPosudomoechnayaMashina = [
	{
		id: 7,
		h1: 'Ремонт посудомоечной машины в Минске',
		p: 'Нанимая профессиональных мастеров для ремонта посудомоечной машины, вы получаете ряд неоспоримых преимуществ. Во-первых, опытные специалисты обладают глубокими знаниями и навыками в области ремонта бытовой техники, что позволяет им быстро и точно определить причину неисправности. Во-вторых, мастера используют современное оборудование и высококачественные запчасти, что гарантирует долгосрочное функционирование вашей посудомоечной машины. В-третьих, профессионалы предоставляют гарантию на выполненные работы, что дает вам уверенность в качестве и надежности их услуг. Доверьте ремонт посудомоечной машины опытным мастерам и наслаждайтесь комфортом и безупречной работой вашей техники.',

		description: 'Восстановите работу вашей посудомоечной машины с нами. Опытные мастера, надежность и качество ремонта. Сделайте правильный выбор – свяжитесь с нами прямо сейчас!',
		img: posudomoechnayaMashina,
		alt: 'ремонт посудомоечной машины',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта посудомоечной машины',
		price: [
			{
				id: 1,
				title: 'Не начинает мойку',
				price: '20 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Плохо моет',
				price: '18 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Постоянно сливает',
				price: '24 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не греет воду',
				price: '29 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт посудомоечной машины',
				p: `
				В наше быстро развивающееся время все больше людей стремятся к удобству и комфорту в быту. Одним из неотъемлемых помощников в кухне является посудомоечная машина. Она позволяет значительно сократить время, затрачиваемое на мытье посуды, и освободить его для более приятных занятий.

Однако, как и любая техника, посудомоечная машина может временами нуждаться в ремонте. И здесь важно обратиться к профессионалам, которые гарантируют качественное выполнение работ и максимально короткие сроки восстановления.

Если вы находитесь в Минске и вам требуется ремонт посудомоечной машины, то мы готовы стать вашим надежным партнером в этом вопросе. Наша компания специализируется на ремонте и обслуживании посудомоечных машин различных марок и моделей. Мы предлагаем профессиональные услуги по ремонту, которые позволят вам снова наслаждаться безупречной работой вашей посудомоечной машины.

Одним из наших главных преимуществ является опытный персонал. Наши мастера обладают большим опытом работы с посудомоечными машинами различных производителей. Они тщательно изучают каждую модель и знают все особенности работы и ремонта. Благодаря этому, мы можем оперативно и точно определить причину неисправности и провести качественный ремонт.

Наша компания оснащена современным оборудованием, что позволяет нам производить ремонт посудомоечных машин на самом высоком уровне. Мы используем только оригинальные запчасти, чтобы обеспечить долгую и надежную работу вашей посудомоечной машины после ремонта. При этом, мы следим за доступностью наших услуг и предлагаем клиентам конкурентоспособные цены.

Кроме того, мы осуществляем комплексное обслуживание посудомоечных машин. Регулярное техническое обслуживание позволяет предотвратить многие неисправности и увеличить срок службы вашей посудомоечной машины. Нанашем сервисном центре мы проводим профилактические работы, которые включают чистку и смазку механизмов, проверку электрической цепи, а также диагностику и устранение возможных проблем.

Наша команда гарантирует быстрый ремонт посудомоечной машины. Мы ценим ваше время и стараемся минимизировать простой и дискомфорт, связанные с неисправностью техники. Поэтому, после обращения к нам, вы можете рассчитывать на оперативную диагностику проблемы и быстрое устранение неисправности.

Мы также осознаем, что ремонт посудомоечной машины может вызывать определенные затруднения в повседневной жизни. Поэтому мы предлагаем гибкую систему графиков работы и возможность вызова мастера на дом. Наш специалист приедет к вам в удобное время, произведет диагностику и выполнит ремонт непосредственно на месте.

Мы гарантируем качество наших услуг и долгосрочную надежность работы посудомоечной машины после ремонта. Поэтому, если вы столкнулись с проблемами, такими как шум, неполное мытье, протечки или другие неисправности, не откладывайте ремонт на потом. Обратитесь к нам, и мы сделаем все возможное, чтобы ваша посудомоечная машина снова работала безупречно.

Запишите наш номер телефона и сохраните его на видном месте, чтобы в случае необходимости вы могли незамедлительно связаться с нами. Мы готовы оказать вам помощь и решить любые проблемы, связанные с ремонтом посудомоечной машины. Доверьтесь опыту и профессионализму нашей команды и наслаждайтесь безупречной работой вашей посудомоечной машины вновь!
`
			},
			{
				h4: 'Стоимость ремонта посудомоечной машины',
				p: `
				Столкнувшись с неисправностью посудомоечной машины, многие владельцы задаются вопросом о стоимости ремонта. Понимая важность этой информации, мы готовы предоставить прозрачную и конкурентоспособную ценовую политику в нашем сервисном центре в Минске.

				Стоимость ремонта посудомоечной машины может варьироваться в зависимости от нескольких факторов. Прежде всего, это тип неисправности. Мелкие поломки, такие как замена кнопок или переключателей, обычно обходятся недорого. Однако, если требуется замена более сложных компонентов, например, насоса или электронного модуля, стоимость ремонта может быть выше.
				
				Также важно учитывать марку и модель посудомоечной машины. Каждый производитель имеет свои особенности в конструкции и запчастях, что может отразиться на стоимости ремонта. Мы работаем с посудомоечными машинами разных брендов и обеспечиваем доступность запчастей для большинства моделей.
				
				Дополнительный фактор, влияющий на стоимость, это сложность работы и время, затрачиваемое на ремонт. Некоторые неисправности могут быть устранены быстро и просто, в то время как другие требуют более тщательной диагностики и ремонтных процедур. Наша команда опытных мастеров проведет тщательную проверку вашей посудомоечной машины и предоставит подробную оценку стоимости работ.
				
				Несмотря на эти факторы, мы стремимся предложить конкурентоспособные и адекватные цены на ремонт посудомоечных машин. Мы понимаем, что ремонт может быть неожиданной финансовой нагрузкой, поэтому стараемся держать наши цены в разумных пределах. Мы также работаем только с оригинальными запчастями, что гарантирует долгосрочную надежность и качество работы после ремонта.
				
				Для того чтобы получить точную оценку стоимости ремонта посудомоечной машины, рекомендуется обратиться к нам для диагностики. Наши специалисты произведут осмотр и определение неисправ
				ности вашей посудомоечной машины, после чего предоставят вам подробную информацию о стоимости ремонта.
				
				Помимо основной стоимости ремонта, важно также учитывать гарантию на выполненные работы и замененные запчасти. В нашем сервисном центре мы предоставляем гарантию на ремонт посудомоечной машины, что означает, что в случае возникновения проблем в течение определенного периода времени, мы бесплатно устраняем неисправности.
				
				Для нас важно установить прозрачные и доверительные отношения с нашими клиентами. Поэтому, перед началом ремонта, мы предоставляем полную информацию о стоимости и обсуждаем ее с вами, чтобы вы имели ясное представление о расходах. Наша команда также готова ответить на все ваши вопросы относительно стоимости, объяснить все составляющие и предоставить детальный расчет.
				
				Мы понимаем, что ремонт посудомоечной машины может вызывать некоторую финансовую нагрузку. Однако, не рекомендуется откладывать ремонт, так как незначительная проблема может привести к серьезным повреждениям, что в итоге потребует более дорогостоящего ремонта или даже замены машины.
				
				Обращайтесь к нам для ремонта посудомоечной машины в Минске, и мы гарантируем высокое качество работ по адекватной стоимости. Мы стремимся обеспечить удовлетворение наших клиентов и восстановить полноценную работу вашей посудомоечной машины в кратчайшие сроки.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт посудомоечной машины именно в нашей компании',
				p: `
Наша компания предлагает профессиональный ремонт посудомоечных машин в Минске. Однако, что делает наших мастеров особенно выдающимися и привлекательными для клиентов? Рассмотрим несколько ключевых преимуществ, которые отличают нас от других сервисных центров.

Во-первых, наши мастера обладают обширным опытом в области ремонта посудомоечных машин. Они прошли специализированное обучение и имеют практический опыт работы с различными моделями и марками посудомоечных машин. Благодаря этому, они знакомы с особенностями каждой модели и могут быстро и точно определить причину неисправности. Это позволяет нам оперативно реагировать на проблему и проводить качественный ремонт.

Во-вторых, мы оснастили наших мастеров современным инструментом и оборудованием. Это позволяет им проводить ремонтные работы на высоком уровне и добиваться отличных результатов. Мы постоянно следим за последними технологическими новинками и обновляем наше оборудование, чтобы быть в курсе всех современных методов ремонта посудомоечных машин.

В-третьих, мы гарантируем использование только оригинальных запчастей в процессе ремонта. Мы понимаем, что качество и надежность запчастей играют важную роль в долгосрочной работе посудомоечной машины. Поэтому мы сотрудничаем с надежными поставщиками и используем только оригинальные комплектующие. Это обеспечивает не только высокую производительность вашей посудомоечной машины после ремонта, но и долгий срок службы.

В-четвертых, наши мастера отличаются профессионализмом и ответственным отношением к своей работе. Они тщательно проводят диагностику, чтобы установить все неисправности и предлагают оптимальное решение. Мы также стараемся сохранить ваше время и комфорт, предлагая гибкие графики работы и возможность выезда мастера на дом. Это позволяет вам избежать лишних хлопот и быстро восстановить работоспособность вашей посудомоечной машины.

Еще одним преимуществом наших мастеров является их вежливость и дружелюбное отношение к клиентам. Мы понимаем, что неисправность посудомоечной машины может быть стрессовым и неприятным событием. Поэтому наши мастера стремятся создать комфортную атмосферу и обеспечить вас профессиональной поддержкой на каждом этапе ремонта. Они ответят на все ваши вопросы, предоставят необходимые рекомендации по эксплуатации и обеспечат полное понимание процесса ремонта.

Не менее важным преимуществом нашего сервисного центра является доступная стоимость ремонта. Мы ценим каждого клиента и стараемся предложить адекватные и конкурентоспособные цены на наши услуги. При этом мы не снижаем качество работ и гарантируем надежность восстановленной посудомоечной машины.

Мы также стремимся обеспечить удобство клиентов, поэтому предлагаем различные варианты оплаты, включая наличные и безналичные расчеты. Мы ценим ваше время, поэтому делаем все возможное, чтобы процесс ремонта был максимально простым и беззаботным для вас.

Если у вас возникла необходимость в ремонте посудомоечной машины в Минске, обратитесь к нам с доверием. Наши опытные мастера сделают все возможное, чтобы ваша посудомоечная машина работала безупречно. Мы ценим доверие наших клиентов и гарантируем высокое качество и профессионализм в каждой детали работы.
				`
			},

		]
	},
]

export const dataVarochnyePaneli = [
	{
		id: 8,
		h1: 'Ремонт варочной панели в Минске',
		p: 'Преимущество наших мастеров в ремонте варочной панели заключается в их опыте и профессионализме. Они владеют глубокими знаниями в области технического обслуживания и ремонта, что позволяет им оперативно и точно определить причину поломки и найти эффективное решение. Благодаря нашим мастерам, вы можете быть уверены в качественном восстановлении вашей варочной панели, сохранении ее функциональности и долговечности. Мы стремимся к максимальной удовлетворенности клиентов, предлагая надежный и профессиональный сервис на каждом этапе ремонта.',

		description: 'Восстановите работу вашей варочной панели с нами. Опытные мастера, профессиональный ремонт и надежность. Вернем функциональность вашей панели. Запишитесь на ремонт сейчас!',
		img: varochnyePaneli,
		alt: 'ремонт варочной панели',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта варочной панели',
		price: [
			{
				id: 1,
				title: 'Не выключается',
				price: '18 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не греет',
				price: '15 руб',
				time: '1.5-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Выбивает автомат',
				price: '17 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не отключается',
				price: '22 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт варочной панели',
				p: `
				
Вы ищете качественный ремонт варочной панели в Минске? Ваш поиск завершен! Мы являемся одной из ведущих компаний, специализирующихся на ремонте варочных панелей в вашем регионе.

Наша команда экспертов обладает обширным опытом и профессиональными навыками в области ремонта варочных панелей различных марок и моделей. Мы оснащены передовыми инструментами и используем только оригинальные запчасти, чтобы обеспечить надежность и долговечность восстановленных панелей.

Когда вы обращаетесь к нам, вы можете быть уверены, что ваша варочная панель находится в надежных руках. Наша команда тщательно диагностирует проблему и предоставляет четкие рекомендации по ремонту. Мы стремимся к быстрому и эффективному исправлению неисправностей, чтобы вы могли вновь наслаждаться безупречной работой вашей варочной панели.

Наш подход к ремонту варочных панелей основан на принципе индивидуального подхода к каждому клиенту. Мы понимаем, что каждая ситуация уникальна, поэтому мы предлагаем решения, которые наилучшим образом соответствуют вашим потребностям и бюджету. Наша команда всегда готова ответить на ваши вопросы и предоставить подробную информацию о процессе ремонта.

Мы также ценим ваше время и комфорт. Поэтому мы предлагаем гибкие варианты графика ремонта, чтобы соответствовать вашим потребностям. Мы стараемся завершить ремонт варочной панели в кратчайшие сроки, не жертвуя при этом качеством работы.

Кроме того, наша компания гарантирует прозрачность и честность во всех аспектах работы. Мы предоставляем четкую оценку стоимости ремонта заранее, чтобы вы знали, что ожидать. Никаких скрытых платежей или неприятных сюрпризов – только честная и прозрачная ценовая политика.

Итак, если вам требуется ремонт варочной панели в Минске, не стесняйтесь 
обратиться к нам. Мы гарантируем, что ваша варочная панель будет отремонтирована высококвалифицированными специалистами, которые вложат все свои знания и опыт для восстановления ее функциональности.

Кроме того, мы осознаем важность быстрого решения проблемы, особенно когда речь идет о кухонной технике. Поэтому мы стараемся провести ремонт варочной панели в кратчайшие сроки, чтобы вы могли снова пользоваться ею без промедления.

Наша компания также предлагает доступные цены на ремонт варочных панелей, чтобы сделать его доступным для каждого клиента. Мы понимаем, что ремонт может быть неожиданной финансовой нагрузкой, поэтому мы стараемся предложить конкурентоспособные тарифы без ущерба для качества работы.

Не откладывайте ремонт варочной панели на потом. Обратитесь к нам сегодня и доверьте свою технику профессионалам. Мы обещаем, что ваша варочная панель будет отремонтирована в срок, с качеством и заботой, которые заслуживает.
`
			},
			{
				h4: 'Стоимость ремонта варочной панели',
				p: `
				
Когда речь заходит о ремонте варочной панели, одним из главных вопросов, который волнует владельцев, является стоимость. Понимая это, наша компания в Минске стремится предложить клиентам прозрачную и конкурентоспособную ценовую политику.

Стоимость ремонта варочной панели может варьироваться в зависимости от различных факторов, включая марку и модель панели, характер поломки, необходимость замены запчастей и трудозатраты специалистов. Поэтому, чтобы определить точную стоимость, рекомендуется обратиться к нашим специалистам для диагностики.

Наша компания предлагает честную и прозрачную оценку стоимости ремонта варочной панели перед началом работ. Мы понимаем, что клиенты хотят знать заранее, сколько им придется заплатить, и поэтому стараемся предоставить подробную информацию о затратах. Мы уверены в качестве нашей работы и в долгосрочных результатах, которые она обеспечивает.

Несмотря на то, что качество ремонта варочной панели является нашим приоритетом, мы также ценим доступность наших услуг. Поэтому мы стараемся предложить конкурентоспособные цены, чтобы ремонт был доступным для всех клиентов. Мы понимаем, что ремонт может стать непредвиденной финансовой нагрузкой, поэтому мы предлагаем различные варианты оплаты и гибкую систему ценообразования.

Кроме того, стоимость ремонта варочной панели также связана с использованием оригинальных запчастей. Мы убеждены в важности использования качественных и надежных запчастей, чтобы гарантировать долговечность восстановленной панели. Мы сотрудничаем с надежными поставщиками, чтобы обеспечить нашим клиентам только оригинальные комплектующие.

Наша команда специалистов всегда готова проконсультировать вас по вопросам стоимости ремонта варочной панели и предоставить подробную информацию о наших услугах. Мы стремимся к полному удовлетворению клиентов и делаем все возможное, чтобы предоставить им доступные цены без скрытых дополнительных затрат. Мы также предлагаем гибкие варианты оплаты, чтобы сделать процесс оплаты ремонта более удобным для вас.

Когда вы выбираете нашу компанию для ремонта варочной панели, вы можете быть уверены, что получите высокое качество работы по адекватной цене. Наша команда специалистов обладает глубокими знаниями и опытом в области ремонта варочных панелей разных производителей. Мы постоянно обновляем наши навыки и следим за последними технологическими трендами, чтобы предоставлять вам лучшие решения.

Кроме того, мы также обеспечиваем гарантию на выполненные работы и замененные запчасти. Это демонстрирует наше доверие к качеству и надежности нашего ремонта. Если в течение гарантийного срока возникнут какие-либо проблемы, мы бесплатно устраним их.

Чтобы узнать точную стоимость ремонта вашей варочной панели в Минске, свяжитесь с нами сегодня. Наша дружелюбная и профессиональная команда ответит на все ваши вопросы, проведет диагностику и предложит наилучшие решения. Не откладывайте ремонт на потом - обращайтесь к нам для надежного и качественного восстановления вашей варочной панели по разумной стоимости.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт варочной панели именно в нашей компании',
				p: `
				Когда дело касается ремонта варочной панели в Минске, наша компания предлагает ряд неоспоримых преимуществ, которые делают нас лучшим выбором для восстановления работоспособности вашей техники.

				Прежде всего, наши мастера обладают обширным опытом и экспертизой в области ремонта варочных панелей. Они прошли специальное обучение и имеют глубокие знания о различных марках и моделях варочных панелей. Наша команда способна эффективно диагностировать неисправности, определить причины поломки и предложить наилучшие решения для их устранения.
				
				Мы осознаем, что ремонт варочной панели - это задача, требующая навыков и внимательного подхода. Поэтому наши мастера обладают высоким профессионализмом и вниманием к деталям. Они тщательно анализируют каждую составляющую панели, проводят проверки и тестирование, чтобы гарантировать безупречное восстановление ее функциональности.
				
				Одним из наших ключевых преимуществ является использование только качественных запчастей и материалов в процессе ремонта варочной панели. Мы сотрудничаем с надежными поставщиками, чтобы убедиться, что заменяемые компоненты соответствуют оригинальным стандартам производителя. Это гарантирует долговечность и надежность работы восстановленной панели.
				
				Мы также понимаем, что время играет важную роль при ремонте варочной панели. Поэтому наша команда стремится к оперативности и эффективности в проведении ремонтных работ. Мы делаем все возможное, чтобы ремонт был завершен в кратчайшие сроки, чтобы вы могли снова пользоваться своей варочной панелью без промедления.
				
				Наша компания гарантирует прозрачность и надежность во всех аспектах ремонта варочной панели. Мы предоставляем четкую оценку стоимости и подробное объяснение работ, которые будут выполнены. Никаких скрытых платежей или неприятных сюрпризов - только честные и четкие условия сотрудничества.
				
				Доверье свою варочную панель нашим мастерам - это выбор профессионализма и надежности. Мы не только гарантируем качественный ремонт, но и предоставляем дополнительные преимущества.

				У нас вы можете рассчитывать на оперативное реагирование и гибкий график работы. Наша команда стремится удовлетворить потребности клиентов и предлагает гибкие варианты записи на ремонт, чтобы подстроиться под ваше расписание. Мы ценим ваше время и делаем все возможное, чтобы минимизировать простои и доставить вашу варочную панель в идеальное состояние в кратчайшие сроки.
				
				Еще одно преимущество нашей компании - индивидуальный подход к каждому клиенту. Мы внимательно слушаем ваши требования и ожидания, учитываем особенности вашей варочной панели и предлагаем наиболее эффективные и экономически выгодные решения. Наша цель - не просто выполнить ремонт, а превзойти ваши ожидания и обеспечить полную удовлетворенность результатом.
				
				Кроме того, мы обеспечиваем гарантию на проведенные работы. Это подтверждение нашей уверенности в качестве и надежности ремонта варочной панели. В случае каких-либо проблем, вы можете обратиться к нам, и мы оперативно решим их без дополнительных затрат для вас.
				
				Не рискуйте своей безопасностью и функциональностью варочной панели, доверьте ее ремонт нашим профессиональным мастерам. Мы гарантируем высокое качество работы, оперативность, прозрачность и индивидуальный подход. Обратитесь к нам сегодня и верните свою варочную панель в идеальное состояние!
				`
			},

		]
	},
]
export const dataIndukcionnyePlity = [
	{
		id: 9,
		h1: 'Ремонт индукционных плит в Минске',
		p: 'Преимущество наших мастеров в ремонте индукционной плиты заключается в их опыте и экспертизе. Мы понимаем сложности, с которыми может столкнуться плита, и предлагаем индивидуальный подход к каждому ремонту. Наши мастера владеют всеми необходимыми знаниями и навыками, чтобы быстро обнаружить и исправить любую неисправность. Мы используем современное оборудование и оригинальные запчасти, чтобы гарантировать качество работы и долговечность ремонта. Кроме того, наша команда обеспечивает профессиональное обслуживание и дружелюбное отношение к клиентам. Полагайтесь на нас для качественного ремонта индукционной плиты и наслаждайтесь ее бесперебойной работой!',

		description: 'Нужен ремонт индукционной плиты? Мы - надежная компания! Опытные мастера, оригинальные запчасти, современное оборудование. Гарантируем качественный ремонт и бесперебойную работу плиты. Закажите ремонт прямо сейчас и наслаждайтесь безупречной функциональностью плиты!',
		img: indukcionnyePlity,
		alt: 'ремонт индукционных плит',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта индукционной плиты',
		price: [
			{
				id: 1,
				title: 'Не видит посуду',
				price: '32 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Выдает ошибку',
				price: '34 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не включается вентилятор',
				price: '28 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Плохо греет',
				price: '29 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт индукционной плиты',
				p: `
				Наша компания предлагает профессиональный и качественный ремонт индукционных плит в Минске. Мы понимаем, что индукционная плита является неотъемлемой частью современной кухни и ее неполадки могут вызывать неудобства и проблемы.

				Наша команда опытных мастеров специализируется на ремонте индукционных плит различных марок и моделей. Мы оснащены самым современным оборудованием и используем только оригинальные запчасти, чтобы гарантировать качество и долговечность наших работ.
				
				Оказывая услуги по ремонту индукционных плит, мы проводим комплексную диагностику, чтобы точно определить причину неисправности. Наша команда имеет обширные знания и опыт, позволяющие нам оперативно обнаруживать и устранять любые проблемы, связанные с индукционными плитами.
				
				Мы предлагаем широкий спектр услуг по ремонту индукционных плит, включая замену поврежденных деталей, чистку и обслуживание, устранение неисправностей электроники и сбоев в работе. Наша цель - вернуть вашей индукционной плите полную функциональность и продлить ее срок службы.
				
				Когда дело доходит до ремонта, мы понимаем, что время имеет большое значение. Поэтому мы стараемся оперативно реагировать на заявки и предлагаем гибкий график работы, чтобы удовлетворить потребности наших клиентов.
				
				Наша компания гордится своей репутацией надежного провайдера услуг ремонта индукционных плит. Мы стремимся к высокому уровню профессионализма, предоставляя клиентам исключительное качество обслуживания. Мы ценим каждого клиента и обеспечиваем индивидуальный подход к решению его проблем.
				
				Качественный ремонт индукционной плиты в Минске - это наша специализация. Обратившись к нам, вы можете быть уверены, что ваша индукционная плита будет в надежных руках профессионалов.
				
				Не откладывайте ремонт индукционной плиты на потом. Свяжитесь с нами прямо сейчас, чтобы получить бесплатную консультацию и договориться о визите нашего специалиста. Мы гарантируем эффективный и быстрый ремонт, чтобы вы снова могли наслаждаться работой вашей индукционной плиты. Не теряйте время и доверьтесь нам для качественного обслуживания вашей индукционной плиты в Минске.
`
			},
			{
				h4: 'Стоимость ремонта индукционной плиты',
				p: `
				Когда речь заходит о ремонте индукционных плит в Минске, один из важных факторов, на который обращают внимание клиенты, - это стоимость услуг. Мы понимаем, что цена является важным аспектом при выборе компании для ремонта индукционной плиты. Поэтому мы стремимся предложить конкурентоспособные и разумные цены, сочетающиеся с высоким качеством работы.

Строить стоимость ремонта индукционной плиты не всегда легко, так как она может варьироваться в зависимости от марки и модели плиты, а также от конкретной проблемы, требующей ремонта. Наша компания предлагает прозрачную и честную политику ценообразования, чтобы клиенты могли четко представлять себе стоимость работ.

Перед началом ремонта индукционной плиты мы проводим бесплатную диагностику, чтобы определить причину неисправности и оценить объем работ. После этого мы предоставляем вам подробную смету, в которой указаны все затраты на запчасти и работы.

Стоимость ремонта индукционной плиты включает в себя не только замену деталей, но и работу специалистов, которые обладают опытом и знаниями в области ремонта индукционных плит. Мы не экономим на качестве и используем оригинальные запчасти, чтобы гарантировать долговечность и надежность вашей плиты.

Кроме того, стоимость ремонта индукционной плиты может варьироваться в зависимости от того, требуется ли ремонт электронных компонентов, чистка или обслуживание плиты. Мы предлагаем широкий спектр услуг, и каждая из них имеет свою собственную стоимость.

Мы всегда стремимся предложить нашим клиентам наиболее конкурентоспособные цены на ремонт индукционных плит в Минске, несмотря на высокое качество наших услуг. Мы понимаем, что ремонт индукционной плиты может быть непредвиденной финансовой нагрузкой, поэтому мы готовы предложить гибкие варианты оплаты и акции для наших клиентов.

Однако стоит помнить, что выбор компании для ремонта индукционной плиты не должен основываться только на самой низкой цене. Важно учитывать и качество работы, опыт мастеров и репутацию компании. Компромисс между стоимостью и качеством является здесь ключевым фактором.

Мы гарантируем, что стоимость ремонта индукционной плиты в нашей компании будет справедливой и соответствующей качеству предоставляемых услуг. Мы предлагаем прозрачность в ценообразовании, несколько вариантов расчета и детальное объяснение каждой статьи затрат.

При выборе нашей компании вы можете быть уверены, что вы получите высококлассный ремонт индукционной плиты по адекватной цене. Наша команда специалистов обладает большим опытом в ремонте индукционных плит различных марок и моделей. Мы используем современное оборудование и оригинальные запчасти, что гарантирует долговечность и надежность выполненных работ.

Важно отметить, что мы также предлагаем гарантию на выполненные нами работы, что означает, что в случае повторной неисправности в течение определенного периода времени, мы бесплатно устраним проблему.

Так что если вам требуется ремонт индукционной плиты в Минске, обратитесь к нам. Мы предлагаем конкурентоспособные цены, высокое качество работы и гарантию на ремонт. Свяжитесь с нами уже сегодня, чтобы получить бесплатную консультацию и договориться о визите нашего специалиста. Мы сделаем все возможное, чтобы ваша индукционная плита снова стала идеально функционировать, и вы могли наслаждаться ее комфортом и удобством в вашей кухне.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт индукционной плиты именно в нашей компании',
				p: `
				Когда дело доходит до ремонта индукционных плит в Минске, наши мастера являются лучшим выбором. Мы гордимся опытными и профессиональными специалистами, которые обладают глубокими знаниями в области ремонта индукционных плит различных марок и моделей.

Одним из главных преимуществ наших мастеров является их навык быстрого и точного диагностирования неисправностей в индукционных плитах. Благодаря богатому опыту и техническому знанию, они могут быстро определить причину проблемы и разработать наиболее эффективный план ремонта.

Кроме того, наши мастера обладают доступом к самому современному оборудованию и используют только оригинальные запчасти. Это гарантирует высокое качество ремонта и долговечность восстановленной индукционной плиты. Мы понимаем, что каждая деталь имеет значение, и поэтому мы уделяем внимание каждой мелочи, чтобы ваша плита работала безупречно.

Еще одно преимущество наших мастеров - это их профессиональное отношение к клиентам. Мы стремимся предоставить нашим клиентам положительный опыт обслуживания, начиная с первого контакта и продолжая вплоть до завершения ремонта. Наша команда всегда готова выслушать ваши потребности и ответить на все ваши вопросы.

Мы также гарантируем оперативность выполнения работ. Мы понимаем, что индукционная плита является важной частью вашей кухни, и любая задержка в ремонте может вызвать неудобства. Поэтому мы стараемся выполнить работы в кратчайшие сроки, не ущемляя качество и точность ремонта.

Кроме того, наши мастера не только проводят ремонт индукционных плит, но и предлагают полезные советы и рекомендации по их эксплуатации и обслуживанию. Мы поможем вам узнать о правильном использовании и уходе за индукционной плитой, чтобы минимизировать риск возникновения будущих проблем.

Если вам требуется качественный и надежный ремонт индукционной плиты в Минске, наши мастера всегда готовы оказать вам помощь. Мы уделяем внимание каждой детали и предлагаем полный спектр услуг, включая замену деталей, чистку, настройку и обслуживание плиты.

Еще одним преимуществом наших мастеров является их профессиональное обучение и постоянное развитие. Они всегда в курсе последних тенденций и технологий в области ремонта индукционных плит, что позволяет им эффективно решать самые сложные проблемы.

Наша компания также гарантирует конкурентоспособные цены на ремонт индукционной плиты. Мы предлагаем прозрачное ценообразование, без скрытых платежей или непредвиденных расходов. Вы всегда будете знать, за что вы платите, и уверены, что получаете отличное соотношение цены и качества.

При обращении к нам, вы можете рассчитывать на быстрый реагирование на вашу заявку и гибкий график работы. Наша команда мастеров готова приехать к вам в удобное для вас время, чтобы провести диагностику и выполнить ремонт непосредственно у вас на месте.

Доверьте ремонт индукционной плиты в Минске нашим профессионалам, и вы получите качественные услуги, надежность и уверенность в работе вашей плиты. Мы ценим каждого клиента и гарантируем полное удовлетворение ваших потребностей. Свяжитесь с нами сейчас, и наши мастера сделают все возможное, чтобы вернуть вашу индукционную плиту в отличное состояние.
				`
			},

		]
	},
]
export const dataDuhovyeShkafy = [
	{
		id: 10,
		h1: 'Ремонт духового шкафа в Минске',
		p: 'Преимущество наших мастеров в ремонте духового шкафа заключается в их опыте и профессионализме. Они обладают глубокими знаниями в области ремонта кухонной техники и способны эффективно и быстро восстановить работу вашего духового шкафа. Независимо от сложности поломки или неисправности, наши мастера найдут наилучшее решение и предложат качественные ремонтные услуги. Вы можете полностью доверить им свой шкаф, зная, что они сделают все возможное, чтобы он снова стал полноценно функционировать. Наша команда мастеров гарантирует профессиональный подход, надежность и отличный результат.',

		description: 'Восстановите функциональность и стиль своего духового шкафа с нашей компанией. Наши опытные мастера обеспечат быстрый и профессиональный ремонт. Обновите кухню и наслаждайтесь удовольствием от готовки. Свяжитесь с нами сегодня и превратите свой шкаф в центр внимания на вашей кухне!',
		img: duhovyeShkafy,
		alt: 'ремонт духового шкафа',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта духового шкафа',
		price: [
			{
				id: 1,
				title: 'Перегревает',
				price: '24 руб',
				time: '1-1.5 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Запах гари',
				price: '32 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Шумит',
				price: '28 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: ' Выбивает автомат',
				price: '29 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт духового шкафа',
				p: `
				Качественный ремонт духового шкафа в Минске: сохраняйте вкус готовки

				Духовой шкаф – незаменимый элемент кухни, который играет важную роль в приготовлении вкусной и здоровой пищи. Однако со временем он может потребовать ремонта из-за поломок, износа или устаревшей техники. В таком случае, качественный ремонт духового шкафа становится необходимостью.
				
				Наша компания предлагает профессиональные услуги по ремонту духовых шкафов в Минске. Мы понимаем, что ваш духовой шкаф – это не только удобство, но и важная часть интерьера кухни. Поэтому мы стремимся не только восстановить его функциональность, но и вернуть ему эстетическое привлекательность.
				
				Наши опытные мастера обладают глубокими знаниями в области ремонта духовых шкафов различных марок и моделей. Мы работаем с использованием современных инструментов и оригинальных запчастей, чтобы обеспечить долговечность и надежность восстановленного шкафа.
				
				В ходе ремонта духового шкафа мы предлагаем следующие услуги:
				
				Диагностика и оценка состояния шкафа: наши мастера проведут тщательную проверку, выявят причины неисправностей и составят детальную смету работ.
				
				Замена деталей и комплектующих: мы используем только оригинальные запчасти, чтобы гарантировать качество и надежность ремонта.
				
				Ремонт электроники и панели управления: в случае неисправности электронных компонентов или кнопок управления, наши мастера проведут ремонт или замену необходимых элементов.
				
				Обслуживание и чистка: регулярное обслуживание и профилактика помогут продлить срок службы вашего духового шкафа и предотвратить возникновение поломок.
				
				Устранение неисправностей и поломок: будь то неработающая духовка, неправильная температура, треснувшее стекло или шумные вентиляторы, наши мастера справятся с любыми проблемами вашего шкафафа и восстановят его полноценную работу.

				При обращении к нам вы получите ряд преимуществ:
				
				Профессионализм и опыт: Наша компания имеет многолетний опыт в ремонте духовых шкафов различных марок и моделей. Наши мастера постоянно совершенствуют свои навыки и следят за последними тенденциями в области ремонта техники.
				
				Индивидуальный подход: Мы понимаем, что каждый духовой шкаф уникален, поэтому мы учитываем особенности каждого заказчика и предлагаем индивидуальные решения. Мы готовы выслушать ваши пожелания и сделать все возможное, чтобы ваш шкаф стал идеально работающим.
				
				Быстрое обслуживание: Мы ценим ваше время и стремимся выполнять ремонт духового шкафа в кратчайшие сроки. Наши мастера работают оперативно и эффективно, чтобы вы могли как можно скорее насладиться полноценной работой вашего шкафа.
				
				Гарантия качества: Мы уверены в качестве своих услуг и предоставляем гарантию на выполненные работы и установленные запчасти. Это доказательство нашей ответственности и доверия к своей работе.
				
				Не откладывайте ремонт духового шкафа на потом. Обратитесь к нам прямо сейчас и доверьте восстановление вашего шкафа профессионалам. Мы с радостью приведем его в отличное состояние, чтобы вы снова могли наслаждаться приготовлением вкусных блюд без каких-либо проблем и неудобств.
`
			},
			{
				h4: 'Стоимость ремонта духового шкафа',
				p: `
				
Когда речь идет о ремонте духового шкафа в Минске, одним из главных вопросов, который интересует каждого клиента, является стоимость ремонта. Определение точной стоимости ремонта духового шкафа зависит от нескольких факторов, включая тип поломки, марку и модель шкафа, а также доступность запчастей.

В нашей компании мы стремимся предоставить честную и прозрачную стоимость ремонта духовых шкафов. Для этого наши специалисты проводят диагностику, чтобы оценить состояние вашего шкафа и определить причину поломки. На основе этой информации мы предоставляем вам детальную смету, которая включает в себя расчет стоимости работ и затрат на необходимые запчасти.

Стоимость ремонта духового шкафа может варьироваться в зависимости от характера поломки. Если это незначительная поломка, такая как замена лампочки или кнопки управления, стоимость ремонта будет относительно невысокой. Однако, если требуется замена сложных компонентов, например, нагревательного элемента или панели управления, стоимость может быть выше.

Важно отметить, что мы ценим качество и долговечность проведенного ремонта. Мы используем только оригинальные запчасти, чтобы обеспечить надежность и долговечность работы вашего духового шкафа после ремонта. Это может отразиться на стоимости, но гарантирует высокое качество обслуживания и минимизацию риска повторных поломок в будущем.

Кроме того, у нас нет скрытых платежей или непредвиденных дополнительных расходов. Мы стремимся предоставить максимально прозрачную информацию о стоимости ремонта, чтобы вы могли принять информированное решение.

Если вам интересует стоимость ремонта духового шкафа, рекомендуем обратиться к нам для проведения диагностики. Наши специалисты внимательно изучат проблему и предоставят вам подробную информацию о стоимости и возможных вариантах ремонта.

Помимо этого, мы также предлагаем гибкую систему оплаты и различные варианты расчета, чтобы сделать ремонт доступным для каждого клиента. Мы понимаем, что ремонт духового шкафа может быть неожиданным расходом, поэтому мы готовы предложить различные финансовые варианты, включая оплату поэтапно или в рассрочку.

Наша команда мастеров обладает богатым опытом в ремонте духовых шкафов различных марок и моделей. Мы постоянно обновляем свои знания и следим за последними технологическими разработками, чтобы быть в курсе всех новых моделей и их особенностей. Это позволяет нам эффективно и точно диагностировать поломки и предлагать наиболее оптимальные решения для их устранения.

Наша цель - обеспечить качественный и надежный ремонт духового шкафа по адекватной стоимости. Мы понимаем, что ваш духовой шкаф - важная часть вашей кухни, поэтому мы придаем особое внимание деталям и стремимся восстановить его работоспособность в полном объеме. Мы не только устраняем поломки, но и проводим профилактические мероприятия, чтобы предотвратить возникновение проблем в будущем.

Обратившись к нам, вы можете быть уверены, что стоимость ремонта духового шкафа будет соответствовать его качеству. Мы предлагаем честную и прозрачную ценовую политику, а также гарантию на все выполненные работы и установленные запчасти. Это является подтверждением нашей ответственности и уверенности в качестве предоставляемых услуг.

Не откладывайте ремонт духового шкафа на потом. Обратитесь к нам уже сегодня, и наши опытные мастера с радостью помогут восстановить функциональность и долговечность вашего духового шкафа. Позвольте себе наслаждаться готовкой в полной мере и доверьте свой ремонт профессионалам.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт духового шкафа именно в нашей компании',
				p: `
				Преимущества наших мастеров при ремонте духового шкафа в Минске

При выборе мастера для ремонта духового шкафа важно учесть множество факторов, включая опыт работы, профессионализм, качество услуг и скорость выполнения работ. В Минске есть много мастерских и сервисных центров, которые предлагают услуги по ремонту духовых шкафов, но наши мастера выделяются на фоне конкурентов по нескольким ключевым преимуществам.

Первое преимущество наших мастеров - это опыт и профессионализм. Мы работаем в сфере ремонта духовых шкафов уже много лет и за это время накопили огромный опыт в решении самых разнообразных проблем. Независимо от того, с чем вы столкнулись - поломка нагревательного элемента, проблемы с терморегулятором или неисправность вентилятора, наши мастера обладают необходимыми знаниями и навыками, чтобы решить любую проблему.

Второе преимущество наших мастеров - это качество работы. Мы понимаем, что духовой шкаф является важным элементом вашей кухни, и любая поломка может вызывать неудобство. Поэтому мы всегда стремимся к выполнению работ на самом высоком уровне качества. Наши мастера используют только профессиональные инструменты и запчасти, чтобы обеспечить долгосрочную надежность и функциональность вашего духового шкафа после ремонта.

Третье преимущество - это оперативность и эффективность. Мы полностью осознаем, что ремонт духового шкафа может нарушить вашу повседневную жизнь, поэтому стремимся к максимально быстрому выполнению работ. Наши мастера приезжают к вам в удобное для вас время и быстро диагностируют проблему. Мы имеем широкий ассортимент запасных частей на складе, что позволяет нам оперативно заменить неисправные детали. Мы ценим ваше время и делаем все возможное, чтобы минимизировать простои и сделать ремонт духового шкафа как можно более эфффективным процессом.

Еще одно преимущество наших мастеров - это индивидуальный подход к каждому клиенту. Мы понимаем, что каждый духовой шкаф имеет свои особенности и требует индивидуального подхода к ремонту. Наши мастера тщательно изучают проблему, проводят детальную диагностику и предлагают оптимальное решение, учитывая ваши пожелания и бюджет.

Кроме того, мы предлагаем гарантию на все выполненные работы. Мы уверены в качестве наших услуг и в надежности используемых запчастей. Поэтому, если после ремонта возникнут какие-либо проблемы, наши мастера бесплатно исправят их в кратчайшие сроки.

Наша компания также отличается конкурентоспособными ценами. Мы понимаем, что ремонт духового шкафа может быть непредвиденной финансовой нагрузкой, поэтому мы предлагаем разумные и прозрачные цены на наши услуги. Мы также стараемся предоставить клиентам различные варианты оплаты, чтобы сделать ремонт доступным и удобным для всех.

Наконец, наши мастера всегда готовы оказать дополнительную помощь и консультацию. Мы делаем все возможное, чтобы обеспечить вас полной поддержкой на всех этапах ремонта и после него. Если у вас возникнут вопросы или потребуется дополнительная информация, наши специалисты с радостью окажут вам необходимую помощь.

В итоге, выбирая наших мастеров для ремонта духового шкафа в Минске, вы получаете опытных и профессиональных специалистов, высокое качество работ, оперативность, индивидуальный подход, гарантию, доступные цены и надежную поддержку. Доверьте ремонт своего духового шкафа нам, и мы сделаем все возможное, чтобы вернуть его в отличное рабочее состояние.
				`
			},

		]
	},
]
export const dataTelevizory = [
	{
		id: 11,
		h1: 'Ремонт телевизоров в Минске',
		p: 'Наши мастера - настоящие эксперты в ремонте телевизоров. Они обладают глубокими знаниями и многолетним опытом работы, что позволяет им быстро и точно определить проблему и найти эффективное решение. Мы используем только оригинальные запчасти высокого качества, чтобы обеспечить долгосрочную работу вашего телевизора. Благодаря нашим мастерам, ваш телевизор будет восстановлен к исходному состоянию с минимальными затратами и максимальным удобством для вас. Доверьте свой телевизор профессионалам и наслаждайтесь яркими и четкими изображениями снова.',

		description: 'Восстановите радость просмотра с нами! Профессиональный ремонт телевизоров, оригинальные запчасти, опытные мастера. Вернем яркость и четкость вашим программам. Запишитесь на ремонт прямо сейчас и наслаждайтесь восстановленным телевизором!',
		img: televizory,
		alt: 'ремонт телевизоров',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта телевизора',
		price: [
			{
				id: 1,
				title: 'Нет звука',
				price: '34 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не включается ',
				price: '37 руб',
				time: '1.5-2.5 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не горит индикация',
				price: '35 руб',
				time: '1-1.5 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт телевизора',
				p: `
				Качественный ремонт телевизора с гарантией в Минске

В современном мире телевизоры стали неотъемлемой частью нашей жизни. Мы проводим множество часов перед экраном, наслаждаясь любимыми фильмами, сериалами и спортивными событиями. Однако, что делать, если ваш телевизор вышел из строя? Не спешите расстраиваться, ведь у нас есть идеальное решение для вас - качественный ремонт телевизора с гарантией в Минске!

Наша компания специализируется на профессиональном ремонте телевизоров различных марок и моделей. Мы понимаем, насколько важно для вас быстро и эффективно восстановить работу вашего телевизора, поэтому предлагаем быстрый и надежный сервис, который отличается высоким качеством и доступными ценами.

Наша команда состоит из опытных и квалифицированных мастеров, обладающих глубокими знаниями в области ремонта телевизоров. Мы постоянно следим за последними технологическими новинками и оборудованием, чтобы предоставить нашим клиентам наилучший сервис. Независимо от того, какая проблема у вас возникла - от поломки экрана до проблем с звуком или питанием, наши мастера быстро и точно определят причину и предложат эффективное решение.

Мы работаем только с оригинальными запчастями и комплектующими, чтобы гарантировать долгосрочное и надежное восстановление вашего телевизора. Все наши работы выполняются с соблюдением высоких стандартов качества, и мы предоставляем гарантию на каждый выполненный ремонт. Это означает, что вы можете быть уверены в том, что ваш телевизор будет работать стабильно и без сбоев после нашего вмешательства.

Кроме того, мы ценим ваше время и удобство. Мы предлагаем гибкий график работы и быстрое обслуживание, чтобы минимизировать ваше ожидание. Наша клиентоориентированность является одной из основных причин, почему нас выбирают множество довольных клиентов из Минска и его окрестностей.

Когда вы обращаетесь к нам, вы получаете не только высококлассный ремонт телевизора, но и заботу о вашем комфорте. Наша дружелюбная и внимательная команда всегда готова ответить на все ваши вопросы и предоставить вам необходимую поддержку на каждом этапе ремонта.

Мы также предлагаем конкурентноспособные цены, чтобы сделать ремонт телевизора доступным для всех. Мы понимаем, что никто не хочет переплачивать за качество, поэтому мы стремимся предложить нашим клиентам лучшую цену на рынке без компромисса в качестве работы.

Не откладывайте ремонт своего телевизора на потом. Обратитесь к нам уже сегодня и доверьте ваш телевизор настоящим профессионалам. Мы гарантируем, что ваш телевизор будет восстановлен к исходному состоянию, и вы сможете наслаждаться яркими и четкими изображениями своих любимых программ. Запишитесь на ремонт прямо сейчас и дайте вашему телевизору новую жизнь!
`
			},
			{
				h4: 'Стоимость ремонта телевизора',
				p: `
				Столкнувшись с поломкой телевизора, каждый из нас задается вопросом о стоимости ремонта. Ведь никто не хочет переплачивать или оказаться в ситуации, когда стоимость ремонта превышает стоимость нового устройства. Если вы находитесь в Минске и ищете надежную компанию для ремонта телевизора по приемлемой цене, мы готовы предложить вам свои услуги.

				Мы осознаем важность прозрачности и понятности вопроса о стоимости ремонта телевизора. Поэтому, когда вы обращаетесь к нам, мы предлагаем бесплатную диагностику вашего устройства. Наши опытные мастера проведут тщательную проверку и определение неисправности, чтобы предоставить вам точную оценку стоимости ремонта. Мы стремимся быть максимально честными и объективными, поэтому предоставляем подробную информацию о необходимых запчастях и работе, которая должна быть выполнена.
				
				У нас нет скрытых платежей или непредвиденных дополнительных расходов. Мы ценим доверие наших клиентов и стараемся установить долгосрочные отношения, основанные на честности и прозрачности. Мы гарантируем, что стоимость ремонта телевизора будет адекватной и соответствовать рыночным ценам.
				
				Помимо этого, мы предлагаем гибкую систему оплаты. Мы понимаем, что каждый клиент имеет свои возможности и предпочтения. Поэтому мы предоставляем несколько вариантов оплаты, чтобы вы могли выбрать наиболее удобный для себя способ.
				
				Наши мастера обладают высокой квалификацией и опытом в ремонте телевизоров различных марок и моделей. Мы используем только качественные запчасти, чтобы обеспечить долгосрочное функционирование вашего телевизора. Кроме того, мы строго следим за сроками ремонта, чтобы минимизировать ваше время ожидания.
				
				Мы понимаем, что ремонт телевизора может быть неожиданной финансовой нагрузкой. Поэтому мы рекомендуем нашим клиентам обратить внимание на возможность гарантии на ремонт. Мы 
				предлагаем гарантию на выполненную работу, чтобы вы чувствовали себя защищенными. Это означает, что в случае повторной поломки в течение гарантийного срока, мы будем бесплатно устранять неисправность.
				
				Когда вы выбираете нас для ремонта телевизора, вы получаете не только высокое качество работы, но и профессиональное обслуживание. Наша команда всегда готова ответить на ваши вопросы, дать рекомендации и консультации по уходу за вашим телевизором.
				
				Мы понимаем, что каждая ситуация индивидуальна, и стоимость ремонта телевизора может варьироваться в зависимости от сложности поломки, модели устройства и затраченного времени на ремонт. Поэтому мы рекомендуем вам обратиться к нам для получения бесплатной диагностики и точной оценки стоимости ремонта.
				
				Мы гарантируем, что наша стоимость ремонта телевизора в Минске будет конкурентоспособной и приемлемой для вас. Мы ценим каждого клиента и стремимся предоставить высокий уровень сервиса по доступной цене.
				
				Не откладывайте ремонт телевизора на потом. Свяжитесь с нами уже сегодня, чтобы восстановить работу вашего телевизора по выгодной стоимости. Мы гарантируем качество, прозрачность и надежность в каждой нашей работе. Доверьте свой телевизор нам и наслаждайтесь качественным просмотром любимых программ без лишних забот о стоимости ремонта.
				`
			},
			{
				h4: 'Почему лучше заказать ремонт телевизора именно в нашей компании',
				p: `
				
Преимущества наших мастеров при ремонте телевизора в Минске

Когда дело касается ремонта телевизора в Минске, мы гордимся своей командой опытных и квалифицированных мастеров, которые предлагают множество преимуществ нашим клиентам.

Профессионализм и опыт: Наши мастера обладают богатым опытом в ремонте телевизоров различных марок и моделей. Они тщательно изучают каждую поломку, быстро и точно определяют причину неполадки, что позволяет провести эффективный и качественный ремонт.

Знания и техническая экспертиза: Мы постоянно обновляем наши знания о последних технологических разработках и трендах в сфере ремонта телевизоров. Наши мастера обладают глубокими знаниями в области электроники и технической экспертизы, что позволяет им успешно справляться с самыми сложными поломками.

Использование качественных запчастей: Мы понимаем, что качество запчастей является ключевым фактором в долгосрочной работе телевизора. Поэтому мы используем только оригинальные и сертифицированные запчасти, чтобы обеспечить надежность и стабильность работы вашего телевизора после ремонта.

Персонализированный подход: Мы понимаем, что каждая поломка уникальна и требует индивидуального подхода. Наши мастера тщательно изучают проблему, консультируют вас и предлагают наилучшие решения, учитывая ваши потребности и бюджет.

Быстрота и эффективность: Мы ценим ваше время и стараемся минимизировать время ремонта. Наши мастера работают профессионально и оперативно, чтобы восстановить ваш телевизор и вернуть вам радость просмотра любимых программ.

Гарантия на выполненную работу: Мы полностью уверены в качестве нашего ремонта и предоставляем гарантию на выполненную работу. Это означает, что вы можете быть спокойны и уверены, что ваш телевизор будет работать стабильно и надежно. В случае возникновения повторной поломки в течение гарантийного срока, мы бесплатно устраняем неисправность.

Ценовая доступность: Мы стремимся предложить конкурентноспособные цены на ремонт телевизора без компромисса в качестве работы. Мы понимаем, что ремонт может быть непредвиденной финансовой нагрузкой, поэтому мы стараемся предоставить вам наиболее выгодное предложение. При этом, стоимость ремонта телевизора всегда прозрачна и согласовывается с вами заранее.

Широкий спектр услуг: Наша компания предлагает полный спектр услуг по ремонту телевизоров. Мы занимаемся заменой разбитых экранов, ремонтом электрических схем, исправлением проблем с аудио- и видеовыходами, а также другими поломками. Независимо от типа поломки, вы можете рассчитывать на нас.

Клиентоориентированный сервис: Мы ценим каждого нашего клиента и стараемся предоставить высокий уровень обслуживания. Наша дружелюбная и внимательная команда всегда готова ответить на ваши вопросы, консультировать и помочь вам с любыми вопросами, связанными с ремонтом телевизора.

Не откладывайте ремонт вашего телевизора на потом. Обратитесь к нам уже сегодня и доверьте ваше устройство опытным и надежным мастерам. Мы гарантируем качественный ремонт телевизора с использованием оригинальных запчастей и долгосрочной гарантией на работу. Позвольте нам вернуть вам яркое и четкое изображение на вашем телевизоре.
				`
			},

		]
	},
]

export const dataRobotPylesos = [
	{
		id: 12,
		h1: 'Ремонт робот-пылесосов в Минске',
		p: 'Преимущество наших мастеров в ремонте роботов-пылесосов состоит в их глубоких знаниях и опыте в данной области. Они обладают экспертным пониманием технических особенностей и специфики работы этих устройств. Наша команда мастеров профессионально решит любые проблемы с вашим роботом-пылесосом – от замены неисправных деталей до настройки программного обеспечения. Мы стремимся обеспечить быстрый и качественный ремонт, чтобы вы снова могли наслаждаться безупречной чистотой в своем доме. Доверьте свой робот-пылесос нам, и мы вернем его к жизни в кратчайшие сроки.',

		description: 'Восстановите своего робота-пылесоса с нами! Мы гарантируем быстрый и надежный ремонт, замену деталей и оптимизацию производительности. Верните ему жизнь сегодня и наслаждайтесь идеальной чистотой. Свяжитесь с нами для экспертной помощи прямо сейчас!',
		img: robotPpylesosy,
		alt: 'ремонт робот-пылесосов',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта робот-пылесоса',
		price: [
			{
				id: 1,
				title: 'Крутится на месте',
				price: '32 руб',
				time: '1.5-2.5 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Плохо моет',
				price: '35 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не запускается',
				price: '34 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт робот-пылесоса',
				p: `
				Качественный ремонт робот-пылесоса в Минске: надежность и профессионализм

				В современном мире, где время является одним из самых ценных ресурсов, робот-пылесосы стали незаменимым помощником в поддержании чистоты в доме. Они освобождают нас от рутинных задач уборки и позволяют сосредоточиться на более важных делах. Однако, как и любая другая техника, роботы-пылесосы могут требовать ремонта в случае поломок или износа компонентов.
				
				Когда дело касается ремонта робота-пылесоса, важно обратиться к опытным специалистам, способным предоставить качественное обслуживание. В Минске существует ряд компаний, предлагающих услуги по ремонту роботов-пылесосов, однако, не все они могут гарантировать высокое качество и надежность.
				
				Одной из ключевых особенностей качественного ремонта робота-пылесоса является опытность мастеров. Это связано с тем, что робот-пылесос - это сложное устройство с различными компонентами и электроникой. Опытные мастера обладают глубокими знаниями о работе роботов-пылесосов, их структуре и принципе работы. Они понимают, как правильно диагностировать неисправности и производить ремонт, чтобы восстановить полную функциональность устройства.
				
				Одной из наиболее распространенных причин, требующих ремонта робота-пылесоса, является износ щеток. Щетки робота-пылесоса активно контактируют с поверхностью пола и подвержены естественному износу. Опытные мастера предлагают замену изношенных щеток на новые, чтобы восстановить идеальное качество уборки. Кроме того, ремонт робота-пылесоса может включать замену аккумулятора, если его емкость снижена или зарядное устройство, если оно перестало функционировать правильно.
				
				Важным аспектом ремонта робота-
				пылесоса является также программное обеспечение. Роботы-пылесосы работают на основе сложных алгоритмов и программ, которые управляют их движением и уборкой. В случае проблем с программным обеспечением, опытные мастера могут обновить или перепрошить программу, чтобы восстановить нормальную работу робота-пылесоса.
				
				Однако, качественный ремонт робота-пылесоса в Минске не ограничивается только устранением неисправностей. Он также включает предоставление гарантии на выполненную работу и использование высококачественных запасных частей. Доверие мастерам, которые используют оригинальные комплектующие, обеспечит надежность и долговечность ремонта.
				
				Если у вас возникла необходимость в ремонте робота-пылесоса в Минске, обратитесь к проверенным и надежным специалистам. Наша компания предлагает качественный ремонт роботов-пылесосов, с использованием оригинальных запасных частей и гарантией на выполненную работу. Наши опытные мастера обладают глубокими знаниями и профессиональным подходом, чтобы вернуть вашему роботу-пылесосу полную функциональность и эффективность.
				
				Не откладывайте ремонт робота-пылесоса, ведь он является вашим надежным помощником в поддержании чистоты в доме. Обратитесь к нам прямо сейчас и доверьте свой робот-пылесос опытным специалистам. Мы обеспечим высококачественный ремонт, который продлит жизнь вашего робота-пылесоса и позволит наслаждаться безупречной чистотой каждый день.
`
			},
			{
				h4: 'Стоимость ремонта робот-пылесоса',
				p: `
				Столкнувшись с неисправностью своего робота-пылесоса, одной из первых вопросов, которые приходят на ум, является стоимость ремонта. В Минске существует ряд компаний, предлагающих услуги по ремонту роботов-пылесосов, и цены на эти услуги могут варьироваться в зависимости от различных факторов.

				Стоимость ремонта робота-пылесоса зависит от нескольких ключевых факторов, включая характер поломки, марку и модель робота-пылесоса, а также комплексность проводимых работ. Обычно, для определения точной стоимости ремонта, необходима диагностика устройства. Мастер проводит тщательный осмотр и определяет неисправности, после чего может предоставить точную информацию о стоимости ремонта.
				
				Стоимость ремонта робота-пылесоса также зависит от используемых запасных частей. В случае необходимости замены изношенных или поврежденных деталей, стоимость ремонта может варьироваться в зависимости от их марки и качества. Компании, использующие оригинальные запасные части, могут предложить более высокую стоимость ремонта по сравнению с теми, кто использует аналоги или компоненты низкого качества.
				
				Однако, при выборе компании для ремонта робота-пылесоса, стоимость не должна быть единственным фактором, определяющим ваш выбор. Важно учитывать и качество предоставляемых услуг. Подходящая компания предложит адекватные цены, соответствующие качеству их работы.
				
				Чтобы определить оптимальную стоимость ремонта робота-пылесоса в Минске, рекомендуется обратиться к нескольким компаниям и запросить у них предварительные оценки стоимости. Также следует обратить внимание на репутацию и отзывы о компании, чтобы удостовериться в их профессионализме и надежности.
				
				Опытные специалисты в ремонте роботов-пылесосов обычно предлагают прозрачность в ценообразовании и подробно объясняют, какие работы и запасные части входят в общую стоимость ремонта. Это поможет вам понять, за что именно вы платите.

				Кроме того, некоторые компании предлагают гарантию на свои услуги. Это дополнительное преимущество, которое стоит учитывать при выборе мастерской для ремонта робота-пылесоса. Гарантия обеспечивает вас от возможных повторных поломок и дает уверенность в качестве проделанной работы.
				
				Важно отметить, что стоимость ремонта робота-пылесоса в Минске может варьироваться в разных компаниях. Однако, при выборе между самой низкой ценой и качеством работы, рекомендуется отдать предпочтение надежности и профессионализму. Ведь некачественный ремонт может привести к дополнительным затратам и временным неудобствам в будущем.
				
				Если вы ищете надежную компанию для ремонта робота-пылесоса в Минске, обратитесь к нам. Мы предлагаем профессиональные услуги по ремонту роботов-пылесосов с прозрачной ценовой политикой и использованием качественных запасных частей. Наши опытные мастера гарантируют качественный ремонт, который вернет вашему роботу-пылесосу его эффективность и продлит его срок службы.
				
				Не откладывайте ремонт робота-пылесоса на потом. Обратитесь к нам прямо сейчас, и мы предоставим вам информацию о стоимости и возможности быстрого ремонта. Восстановите работоспособность своего робота-пылесоса и наслаждайтесь идеальной чистотой в вашем доме снова!
				`
			},
			{
				h4: 'Почему лучше заказать ремонт робот-пылесоса именно в нашей компании',
				p: `
			
Преимущества наших мастеров при ремонте роботов-пылесосов в Минске

Когда речь заходит о ремонте роботов-пылесосов в Минске, наша компания предлагает опытных мастеров, способных обеспечить высококачественное обслуживание и решить любые проблемы с вашим устройством. Вот несколько преимуществ, которые вы получите, обратившись к нам.

Глубокие знания и опыт: Наши мастера обладают глубокими знаниями о ремонте роботов-пылесосов и многолетним опытом работы. Они хорошо знакомы со структурой и принципом работы различных моделей роботов-пылесосов, что позволяет им эффективно диагностировать и устранять неисправности.

Комплексный подход: Мы предлагаем комплексный подход к ремонту роботов-пылесосов. Наши мастера проводят тщательную диагностику, чтобы выявить все проблемы устройства. Они не только устраняют обнаруженные неисправности, но и проверяют другие компоненты, чтобы предотвратить возможные поломки в будущем.

Использование оригинальных запасных частей: Мы применяем только оригинальные запасные части при ремонте роботов-пылесосов. Это гарантирует их совместимость с устройством и обеспечивает долговечность и надежность восстановленного робота-пылесоса.

Быстрый и эффективный ремонт: Наши мастера работают оперативно и эффективно. Мы понимаем, что ваш робот-пылесос является важным помощником в уборке дома, поэтому мы стараемся выполнить ремонт в кратчайшие сроки, чтобы вы смогли вновь наслаждаться его работой.

Гарантия на выполненную работу: Мы уверены в качестве своей работы, поэтому предоставляем гарантию на выполненные ремонтные работы. Если в течение определенного периода времени после ремонта возникнут проблемы, мы готовы бесплатно их устранить.

Конкурентная стоимость: Мы предлагаем конкурентную стоимость рем
ChatGPT
онта роботов-пылесосов в Минске. Мы стремимся предложить клиентам оптимальное соотношение цены и качества. Наша ценовая политика является прозрачной, и мы стараемся предоставить клиентам разумные расценки на ремонт.

Индивидуальный подход к каждому клиенту: Мы ценим каждого клиента и предлагаем индивидуальный подход к каждому случаю ремонта. Наши мастера внимательно выслушивают проблему, с которой вы столкнулись, и предлагают наиболее эффективные решения, учитывая ваши потребности и бюджет.

Компетентный и дружелюбный персонал: Наша команда состоит из профессионалов, которые не только обладают техническими навыками, но и отличаются дружелюбным и вежливым отношением к клиентам. Мы всегда готовы ответить на ваши вопросы, предоставить консультацию и рассказать о процессе ремонта.

Безопасность и конфиденциальность: Мы придаем большое значение безопасности и конфиденциальности информации клиентов. Все данные, связанные с ремонтом вашего робота-пылесоса, хранятся в защищенном режиме, и мы обеспечиваем полную конфиденциальность ваших личных данных.

Долгосрочные отношения с клиентами: Мы стремимся установить долгосрочные отношения с нашими клиентами. Наша цель - не только решить текущую проблему с роботом-пылесосом, но и быть вашим надежным партнером в обслуживании и ремонте в будущем.

Если у вас возникли проблемы с роботом-пылесосом, не откладывайте ремонт на потом. Обратитесь к нам, и наши опытные мастера с радостью помогут вам восстановить работоспособность вашего устройства. Доверьте свой робот-пылесос надежным профессионалам и наслаждайтесь чистотой в вашем доме снова!
				`
			},

		]
	},
]

export const dataPylesosy = [
	{
		id: 13,
		h1: 'Ремонт пылесосов в Минске',
		p: 'Преимуществом наших мастеров по ремонту пылесосов является их высокая квалификация и опыт. Они владеют глубокими знаниями о работе различных моделей и марок пылесосов, что позволяет им быстро и точно определить причину поломки и произвести качественный ремонт. Мастера используют только оригинальные запчасти и современное оборудование, чтобы гарантировать долгосрочное и эффективное восстановление вашего пылесоса. Благодаря своему профессионализму и ответственному отношению к работе, наши мастера обеспечивают высокий уровень сервиса и удовлетворение потребностей каждого клиента. Не тратьте время на самостоятельные ремонты, доверьте свой пылесос профессионалам и наслаждайтесь его безупречной работой.',

		description: 'Восстановите функциональность вашего пылесоса с помощью нашей компании! Опытные мастера, оригинальные запчасти, высокий уровень сервиса. Запишитесь на ремонт сейчас и наслаждайтесь чистотой в своем доме снова!',
		img: pylesosy,
		alt: 'ремонт пылесосов',
		h3: '',
		list: [
			''
		],
		price_title: 'Стоимость ремонта пылесоса',
		price: [
			{
				id: 1,
				title: 'Плохо втягивает воздух',
				price: '32 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не запускается',
				price: '30 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Сильно шумит',
				price: '28 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Выбивает автомат',
				price: '25 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		content: [
			{
				h4: 'Качественный ремонт пылесоса',
				p: `
				Вы ищете качественный ремонт пылесоса в Минске? Поздравляем, вы попали по адресу! Мы предлагаем профессиональные услуги по ремонту пылесосов, где каждая поломка решается с большим вниманием к деталям и качественным подходом.

Наша команда состоит из опытных мастеров, которые обладают глубокими знаниями и навыками в области ремонта пылесосов различных марок и моделей. Они тщательно диагностируют проблему вашего пылесоса и предлагают наиболее эффективное и надежное решение.

При ремонте пылесоса мы используем только оригинальные запчасти, чтобы гарантировать долгосрочное и надежное восстановление работы устройства. Наша компания тесно сотрудничает с проверенными поставщиками, что позволяет нам получать качественные детали непосредственно от производителей.

Мы оснащены современным оборудованием и инструментами, что позволяет нам проводить ремонт пылесосов с высокой точностью и эффективностью. Независимо от поломки - замена мотора, чистка фильтров, починка шланга или замена щетки, наши мастера профессионально выполнят любую работу.

Когда вы обращаетесь к нам, вы можете быть уверены, что получите не только качественный ремонт, но и отличный уровень обслуживания. Мы ценим каждого клиента и стремимся превзойти ожидания своей работы.

Кроме того, наша компания предлагает удобные условия обслуживания. Мы гарантируем быстрый оборот времени ремонта, чтобы вы могли как можно скорее воспользоваться своим пылесосом. Также мы предлагаем конкурентные цены на наши услуги, чтобы сделать ремонт доступным для всех.

Если вы ищете качественный ремонт пылесоса в Минске, обратитесь к нам. Мы готовы помочь вам восстановить работоспособность вашего пылесоса и обеспечить чистоту в вашем доме. Сделайте правильный выбор и доверьте свой пылесос опытным специалистам!
`
			},
			{
				h4: 'Стоимость ремонта пылесоса',
				p: `
				Когда речь заходит о стоимости ремонта пылесоса в Минске, понимание того, сколько это может стоить, является важным вопросом для каждого владельца пылесоса. Разумеется, стоимость ремонта может варьироваться в зависимости от нескольких факторов, включая характер поломки, марку и модель пылесоса, а также регион, в котором вы проживаете. Однако, мы стремимся предложить конкурентоспособные цены и прозрачную политику ценообразования для наших клиентов.

При обращении к нам для ремонта пылесоса, первым шагом будет проведение диагностики, чтобы определить причину поломки и необходимый объем работ. Команда наших опытных мастеров, специализирующихся на ремонте пылесосов, проведет тщательное обследование вашего устройства и предоставит вам подробный отчет о выявленных проблемах и предлагаемых решениях.

Наши цены на ремонт пылесосов в Минске формируются на основе объективных факторов, включая стоимость необходимых запчастей, трудозатраты и сложность работы. Мы предлагаем прозрачную систему ценообразования, где каждый этап и каждая услуга четко прописаны и оценены, чтобы вы могли оценить стоимость ремонта заранее. Наша цель - обеспечить справедливую и конкурентоспособную стоимость ремонта пылесоса, сохраняя при этом высокое качество работы.

Стоимость ремонта пылесоса может быть разной в зависимости от вида поломки. Небольшие и простые поломки, такие как замена фильтра или ремонт переключателя, могут обойтись вам в более доступную цену. В случае более сложных проблем, таких как замена мотора или ремонт электронной платы, стоимость может быть выше, но мы всегда стремимся предложить конкурентоспособные цены в соответствии с рыночными условиями.

Однако, стоит помнить, что ремонт пылесоса является инвестицией в его продолжительное использование и экономию средств на покупке нового устройства. 
Когда вы принимаете решение о ремонте пылесоса, помимо стоимости, также важно учитывать другие факторы. Во-первых, ремонт может занять меньше времени, чем покупка нового пылесоса, что позволит вам быстро вернуться к обычным делам и наслаждаться чистотой в своем доме. Кроме того, ремонт может быть экологически более ответственным выбором, поскольку вы продлеваете жизнь вашего пылесоса, вместо того чтобы выбрасывать его и увеличивать объемы отходов.

Мы стремимся предложить нашим клиентам оптимальное соотношение цены и качества. Наша компания работает непосредственно с поставщиками запчастей, что позволяет нам получать их по конкурентоспособным ценам. Мы также постоянно обновляемся в области технологий и методов ремонта, чтобы обеспечить эффективное и надежное восстановление работы вашего пылесоса.

Не стоит экономить на качестве ремонта, выбирая непроверенные и недобросовестные сервисные центры с низкими ценами. Качественный ремонт пылесоса требует опыта, профессиональных навыков и использования оригинальных запчастей. В нашей компании вы получите не только гарантированное качество работы, но и индивидуальный подход к каждому клиенту.

Так что, если вам требуется ремонт пылесоса в Минске, обратитесь к нам. Мы предлагаем честную и конкурентоспособную стоимость ремонта, профессиональное обслуживание и качественный результат. Доверьте ваш пылесос настоящим экспертам и получите надежное восстановление его работы. Запишитесь на ремонт уже сейчас и наслаждайтесь его безупречной производительностью!
				`
			},
			{
				h4: 'Почему лучше заказать ремонт пылесоса именно в нашей компании',
				p: `
				Когда дело доходит до ремонта пылесоса в Минске, важно найти опытных и надежных мастеров, которые смогут решить любую проблему, с которой вы столкнулись. И вот почему наши мастера являются идеальным выбором.

				Во-первых, наши мастера обладают богатым опытом в области ремонта пылесосов. Они прошли тщательное обучение и имеют глубокие знания о работе различных моделей и марок пылесосов. Благодаря своему опыту, они быстро и точно определяют причину поломки и производят эффективный ремонт. Независимо от того, требуется замена мотора, починка электроники или восстановление всасывающей силы, наши мастера решат задачу с профессионализмом и мастерством.
				
				Во-вторых, мы гарантируем использование только оригинальных запчастей при ремонте пылесосов. Мы тесно сотрудничаем с надежными поставщиками, чтобы обеспечить нашим клиентам высокое качество и надежность запчастей. Это гарантирует долгосрочное восстановление работы вашего пылесоса и предотвращает возможные проблемы в будущем.
				
				В-третьих, наши мастера оснащены современным оборудованием и инструментами, что позволяет им проводить ремонт пылесосов с высокой точностью и эффективностью. Они следуют последним технологическим тенденциям в отрасли и постоянно обновляют свои навыки, чтобы быть в курсе последних разработок. Это позволяет нам предоставлять нашим клиентам лучший уровень обслуживания и гарантировать, что их пылесос будет отремонтирован с высокой степенью профессионализма.
				
				Кроме того, наши мастера обладают широким спектром знаний и навыков, позволяющих им решать самые разнообразные проблемы с пылесосами. Они знают, как работает каждая деталь и компонент пылесоса, и могут эффективно диагностировать и устранять неисправности. Независимо от марки или модели вашего пылесоса, наши мастера имеют все необходимые знания и опыт, чтобы выполнить ремонт качественно и в срок.

				Помимо профессионализма и опыта, наши мастера также отличаются внимательным и индивидуальным подходом к каждому клиенту. Мы понимаем, что поломка пылесоса может вызывать неудобства и нарушать обычный ритм вашей жизни. Поэтому мы стремимся обеспечить быстрый и эффективный ремонт, чтобы вы могли воспользоваться своим пылесосом в кратчайшие сроки. Наша команда готова выслушать ваши проблемы и ответить на все ваши вопросы, чтобы предоставить вам полную информацию о ремонте и стоимости работ.
				
				Важно отметить, что стоимость ремонта пылесоса в Минске зависит от характера поломки, марки и модели пылесоса, а также от необходимости замены запчастей. Мы гарантируем прозрачность и адекватность ценообразования. При обращении к нам, вы получите четкую оценку стоимости ремонта, и мы не взимаем скрытых платежей. Мы ценим наших клиентов и предлагаем разумные и конкурентоспособные цены за качественный ремонт.
				
				Так что, если у вас возникли проблемы с пылесосом и вам требуется его ремонт в Минске, не стесняйтесь обратиться к нам. Наши опытные мастера готовы вернуть ваш пылесос к жизни, обеспечивая высокое качество работы и индивидуальный подход. Доверьте свой пылесос профессионалам и наслаждайтесь его бесперебойной работой. Свяжитесь с нами прямо сейчас и убедитесь в преимуществах нашего сервиса ремонта пылесосов.
				`
			},

		]
	},
]