import img1 from '../../images/articles/molniya.webp'
import img2 from '../../images/articles/shot.webp'

export const dataListArticles = [
	{
		id: 1,
		h2: 'Удар молнии или скачок напряжения: Поломки, Ремонт и Возмещение Убытков',
		img: img1,
		alt: '',
		view: 54,
		like: 37,
		link:'/article/udar-molnii-ili-skachok-napryazheniya'
	},
	{
		id: 2,
		h2: 'Как предотвратить ремонт холодильника и поддерживать его в отличной форме',
		img: img2,
		alt: '',
		view: 77,
		like: 49,
		link:'/article/kak-predotvratit-remont-holodilnika'
	},
]