import holod from '../../images/servicePremium/holod.webp'
import stiralka from '../../images/servicePremium/stiralka.webp'
import posudamojka from '../../images/servicePremium/posudamojka.webp'
import kofemashina from '../../images/servicePremium/kofemashina.webp'

export const dataHolod = [
	{
		id: 1,
		h1: 'Ремонт холодильников премиум брендов',
		description: 'Ремонт холодильников Hitachi, Neff, Liebherr, Sharp, Mitsubishi и других премиум брендов. Используем только оригинальные запчасти. Высококвалифицированные специалисты с многолетним опытом. Быстрый выезд и качественный ремонт в день обращения. Гарантия на все виды работ.',
		img: holod,
		alt: 'ремонт холодильников премиум брендов',
		list: `	• Hitachi
		• Neff
		• Liebherr
		• Sharp
		• Mitsubishi
		• Toshiba
		• Siemens
		• Smeg
		• Asko
		• Vestfrost
		• AEG
		• Weissgauff
		• Miele
		• Kupersbusch
		• Bauknecht
		• Gaggenau
		• Gorenje
		и других премиальных брендов`,
		h3: `Мы предоставляем высококачественный ремонт, используя исключительно оригинальные запчасти, что обеспечивает восстановление полной работоспособности вашей техники.

		Наши процессы ремонта включают строгое и полное тестирование оборудования, что гарантирует безупречную работу вашего холодильника.

		Наши специалисты регулярно проходят обучение и повышение квалификации на заводах-изготовителях. Это позволяет нам обеспечивать профессиональную диагностику и успешно справляться с любыми, даже самыми сложными неисправностями.

		К вам приедет не просто мастер, а опытный инженер, специализирующийся на работе с премиальной техникой ведущих брендов. Он обладает богатым опытом и глубоким знанием тонкостей устройства таких холодильников, что позволяет нам предложить вам высококачественный и надежный ремонт.

		Мы ценим доверие наших клиентов и гарантируем, что ваш холодильник будет в надежных руках, получив профессиональный ремонт.`,
		price_title: 'Стоимость ремонта холодильника',
		price: [
			{
				id: 1,
				title: 'Не холодит',
				price: '29 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не морозит',
				price: '29 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Не включается',
				price: '29 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не отключается',
				price: '29 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Намерзает',
				price: '31 руб',
				time: '2-4 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Шумит',
				price: '27 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
		],
		priziv: 'Не спешите покупать новый холодильник, если ваш сломался. Давайте вместе подумаем, как отремонтировать его с минимальными затратами. Это поможет сэкономить деньги и продлить срок службы прибора. Воспользуйтесь услугами профессиональных мастеров по ремонту бытовой техники. Они быстро найдут и устранят неисправность. А вы сможете продолжить использовать проверенный холодильник.',
		content: [
			{
				h4: 'Особенности ремонта холодильников премиальных брендов',
				p: `Холодильники премиальных брендов – это истинные произведения искусства среди бытовой техники. Они объединяют в себе инновационные технологии, превосходные материалы и впечатляющий дизайн. Владение холодильником премиум-класса – это не только повседневное удобство, но и знак статуса, который подчеркивает изысканный вкус и стремление к качеству.

				Как и любая другая техника, холодильники премиум-класса могут подвергаться поломкам и требовать ремонта. Особенности ремонта данных устройств отличаются от обычных не премиальных холодильников, и это связано с несколькими факторами.
				
				Качество запчастей: Одной из основных отличительных черт холодильников премиум-класса является использование высококачественных материалов и технологий при их производстве. В связи с этим, ремонтные комплектующие также должны быть оригинальными и идентичными тем, что были установлены изначально. Использование неоригинальных деталей может повлиять на производительность устройства и сократить срок его службы.
				
				Специалисты с опытом: Ремонт холодильников премиум-класса требует опытных и квалифицированных специалистов, обладающих знаниями о сложных технологиях и особенностях работы данных устройств. Производители премиальных холодильников обычно предоставляют специальные обучающие программы и сертификации для сервисных центров, чтобы обеспечить высокий уровень обслуживания.
				
				Гарантия и долгосрочная поддержка: Производители премиальных брендов часто предлагают более длительные гарантийные сроки на свою технику, включая и ремонтные работы. Это демонстрирует уверенность в качестве продукции и стремление предоставить клиентам беззаботный опыт использования. Важно обращаться только в официальные сервисные центры или уполномоченные сервисные партнеры, чтобы не нарушить гарантийные обязательства.
				
				Стоимость ремонта: Ремонт холодильников премиум-класса обычно немного дороже по сравнению с обычными моделями. Это обусловлено использованием оригинальных запчастей и более сложным устройством данных устройств. Кроме того, специализированные технические навыки и опыт, требующиеся для ремонта таких холодильников, также отражаются в стоимости услуг.
				
				Комплексный подход: Ремонт холодильников премиальных брендов требует комплексного подхода. Это включает не только устранение непосредственной поломки, но и анализ работы всей системы, чтобы исключить возможность повторных проблем. Кроме того, специалисты часто предоставляют рекомендации по правильному использованию и уходу за холодильником, чтобы максимизировать его производительность и срок службы.
				
				В заключение, ремонт холодильников премиальных брендов – это ответственное и деликатное мероприятие, которое требует специализированного подхода и высококвалифицированных специалистов. Несмотря на немного более высокую стоимость по сравнению с ремонтом обычных холодильников, инвестиции в качественное обслуживание и оригинальные запчасти позволят продлить срок службы вашего премиального холодильника и сохранить его отличную производительность на долгие годы.`
			},

		]

	},
]
export const dataStiralka = [
	{
		id: 1,
		h1: 'Ремонт стиральных машин премиум брендов',
		description: 'Ремонт стиральных машин Miele, AEG, Siemens, Hyundai, Weissgauff и других премиум брендов. Используем только оригинальные запчасти. Высококвалифицированные специалисты с многолетним опытом. Быстрый выезд и качественный ремонт в день обращения. Гарантия на все виды работ.',
		img: stiralka,
		alt: 'ремонт стиральных машин премиум брендов',
		list: `• Miele
		• AEG
		• Siemens 
		• Hyundai
		• Weissgauff
		• Neff
		• V-ZUG
		• Smeg
		и других премиальных брендов`,
		h3: `Мы предоставляем безукоризненный ремонт высочайшего качества, используя исключительно оригинальные запчасти, которые обеспечивают полное восстановление работоспособности вашей техники.

		Наши процессы ремонта включают строгие и полные тестирования оборудования, что гарантирует бесперебойную работу вашей стиральной машины.
		
		Наша команда специалистов постоянно проходит обучение и повышение квалификации на заводах-изготовителях. Это позволяет нам обеспечивать профессиональную диагностику и успешно справляться с любыми, даже самыми сложными неисправностями.
		
		К вам приедет не просто мастер, а опытный инженер, специализирующийся на работе с премиальной техникой ведущих брендов. Он обладает богатым опытом и глубоким знанием тонкостей устройства таких стиральных машин, что позволяет нам предложить вам безукоризненный и надежный ремонт.
		
		Мы ценим доверие, которое наши клиенты оказывают нам, и гарантируем, что ваша стиральная машина окажется в надежных руках, получив профессиональный ремонт.`,
		price_title: 'Стоимость ремонта стиральной машины',
		price: [
			{
				id: 1,
				title: 'Не греет воду',
				price: '29 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не сливает',
				price: '28 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Шумит',
				price: '31 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не крутит барабан',
				price: '35 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Прыгает',
				price: '29 руб',
				time: '2-4 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Не начинает стирку',
				price: '29 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',
			},
		],
		priziv: 'Не спешите расставаться со своей стиральной машиной только потому, что она сломалась. Давайте вместе подумаем, как можно было бы починить ее, потратив при этом совсем немного. Ремонт позволит сэкономить немалые деньги и продлит жизнь вашему верному помощнику. Обратитесь к профессионалам, опытные мастера быстро найдут неисправность и устранят ее. Вы сможете и дальше пользоваться своей качественной стиральной машиной, не тратя лишних средств на покупку новой. Поверьте, ремонт - разумнее, чем покупка. Вместе мы найдем оптимальное решение!',
		content: [
			{
				h4: 'Особенности ремонта стиральных машин премиальных брендов',
				p: `Ремонт стиральных машин премиальных брендов, таких как Miele, Siemens, Bosch, имеет ряд особенностей. Во-первых, стоимость запчастей для такой техники значительно выше, чем для бюджетных моделей. Это объясняется использованием дорогих комплектующих из Германии, Австрии, Швейцарии - именно там расположены заводы ведущих производителей.

				Качественные оригинальные запчасти позволяют надолго забыть о поломках и наслаждаться бесперебойной работой техники. Ремонт, выполненный с их использованием, гарантирует долгий срок службы отремонтированной машинки. Конечно, стоимость ремонта при этом несколько выше, чем при установке дешёвых неоригинальных комплектующих, зато результат значительно надежнее.
				
				Еще одна важная особенность - высокая квалификация специалистов по ремонту. Работа с импортной премиальной техникой требует от мастера опыта, знаний, внимательности. Не каждый сервис может предложить таких профессионалов. Мы же гордимся нашими опытными мастерами, прошедшими специальное обучение за рубежом.
				
				И конечно, мы предлагаем особое отношение к каждому клиенту. Понимаем, что поломка дорогой импортной машинки - всегда стресс для владельца. Поэтому стараемся максимально оперативно устранить неисправность и свести время простоя техники к минимуму. Работаем и в выходные, и без обеда - лишь бы успеть быстрее! Вежливое и внимательное отношение наших сотрудников также очень ценится клиентами.
				
				Вот почему все больше владельцев импортных стиральных машин доверяют их ремонт именно нам! Премиальное обслуживание для премиальной техники!`
			},

		]

	},
]
export const dataPosudamojka = [
	{
		id: 1,
		h1: 'Ремонт посудомоечных машин премиум брендов',
		description: 'Ремонт посудомоечных машин премиум брендов. Используем только оригинальные запчасти. Высококвалифицированные специалисты с многолетним опытом. Быстрый выезд и качественный ремонт в день обращения. Гарантия на все виды работ.',
		img: posudamojka,
		alt: 'ремонт посудомоечных машин премиум брендов',
		list: `• Miele
		• Waissgauff 
		• Neff
		• AEG
		• SMEG
		• Siemens
		• Gaggenau
		• Bauknecht
		• ILVE
		• Kupersbusch 
		и других премиальных брендов`,
		h3: `Мы предоставляем безупречный ремонт посудомоечных машин высочайшего качества, используя исключительно оригинальные запчасти, которые обеспечивают полное восстановление работоспособности вашей техники.
		
		Наши процессы ремонта включают тщательные тестирования оборудования, что гарантирует бесперебойную работу вашей посудомойки. 
		
		Наша команда регулярно проходит обучение на заводах-изготовителях. Это позволяет нам успешно справляться с любыми неисправностями.
		
		К вам приедет опытный инженер, специализирующийся на ремонте посудомоечных машин ведущих брендов. Его знания и опыт позволяют нам предложить вам профессиональный и надежный ремонт.
		
		Мы ценим доверие наших клиентов и гарантируем, что ваша посудомойка получит качественный ремонт от профессионалов.`,
		price_title: 'Стоимость ремонта посудомоечной машины',
		price: [
			{
				id: 1,
				title: 'Не начинает мойку',
				price: '29 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Плохо моет',
				price: '28 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Постоянно сливает',
				price: '35 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не греет воду',
				price: '39 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},


		],
		priziv: 'Не спешите расставаться со своей посудомоечной машиной только потому, что она сломалась. Давайте вместе подумаем, как можно было бы починить ее, потратив при этом совсем немного. Ремонт позволит сэкономить немалые деньги и продлит жизнь вашему верному помощнику. Обратитесь к профессионалам, опытные мастера быстро найдут неисправность и устранят ее. Вы сможете и дальше пользоваться своей качественной посудомоечной машиной, не тратя лишних средств на покупку новой. Поверьте, ремонт - разумнее, чем покупка. Вместе мы найдем оптимальное решение!',
		content: [
			{
				h4: 'Особенности ремонта посудомоечных машин премиальных брендов',
				p: `Ремонт техники известных брендов - задача не из легких. Особенно если речь идет о таком сложном устройстве, как посудомоечная машина премиум-класса. Но если доверить ее починку профессионалам своего дела, можно не переживать за результат.

				Наши специалисты регулярно проходят повышение квалификации непосредственно на заводах Miele, Bosch, Siemens, AEG, Gaggenau, поэтому знают все тонкости устройства этой техники. Опытный инженер тщательно диагностирует неисправность, а затем устраняет ее так, что посудомоечная машина заработает как новая.
				
				Мы используем для ремонта исключительно фирменные запчасти от производителя. Это гарантирует полную взаимозаменяемость с оригинальными комплектующими и полное восстановление функционала. Посудомойка после качественного ремонта прослужит вам еще долгие годы, радуя безупречным результатом.
				
				Мы понимаем, насколько дорога владельцу его премиальная техника. Поэтому относимся к каждой машине с особым трепетом - как к своей собственной. Бережно демонтируем, чистим и собираем каждый узел. Тщательно тестируем и исключаем даже малейшую вероятность повторной поломки.
				
				Согласитесь, ремонт выгоднее покупки новой посудомойки премиум-класса. Он обойдется ненамного дороже, чем ремонт бюджетных моделей. Зато вы сохраните свою любимую технику, сэкономите средства и будете уверены в результате.
				
				Доверьте свой дорогой агрегат профессионалам! Мы возвратим вашу посудомоечную машину к жизни с гарантией качества. Бережное отношение и безупречный результат - вот наш девиз. Звоните прямо сейчас!`
			},

		]

	},
]
export const dataKofemashina = [
	{
		id: 1,
		h1: 'Ремонт кофемашин премиум брендов',
		description: 'Ремонт  кофемашин премиум брендов. Используем только оригинальные запчасти. Высококвалифицированные специалисты с многолетним опытом. Быстрый выезд и качественный ремонт в день обращения. Гарантия на все виды работ.',
		img: kofemashina,
		alt: 'ремонт  кофемашин премиум брендов',
		list: `• Siemens
		• Delonghi
		• Miele
		• Gorenje
		• Jura
		• Nivona
		• Bosch
		• Gaggia Academia
		• La Pavoni
		• Franke
		• Dalla Corte
		• Victoria Arduino
		• Kupersbusch 
		• Smeg
		• AEG
		• KitchenAid
		• Graude
		и других премиальных брендов`,
		h3: `Мы предоставляем безупречный ремонт кофемашин высочайшего качества, используя исключительно оригинальные запчасти, которые обеспечивают полное восстановление работоспособности вашей техники.

		Наши процессы ремонта включают тщательные тестирования оборудования, что гарантирует бесперебойную работу вашей кофемашины.
		
		К вам приедет не простой мастер, а опытный инженер, прошедший стажировку на заводе производителе и знающий все тонкости ремонта премиальных кофемашин. Его глубокие знания и большой опыт позволяют нам предложить вам профессиональный и надежный ремонт.
		
		Мы ценим доверие наших клиентов и гарантируем, что ваша кофемашина получит качественный ремонт от профессионалов.`,
		price_title: 'Стоимость ремонта кофемашины',
		price: [
			{
				id: 1,
				title: 'Сервисное обслуживание',
				price: '49 руб',
				time: '2-3 часа',
				warranty: 'до 2-х лет',

			},
			{
				id: 2,
				title: 'Не вынимается заварное устройство',
				price: '25 руб',
				time: '1 час',
				warranty: 'до 2-х лет',
			},
			{
				id: 3,
				title: 'Неравномерный налив',
				price: '35 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 4,
				title: 'Не доливает кофе',
				price: '29 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 5,
				title: 'Шумит во время налива',
				price: '39 руб',
				time: '1 час',
				warranty: 'до 2-х лет',
			},
			{
				id: 6,
				title: 'Кофе слишком горячий или холодный',
				price: '29 руб',
				time: '1-3 часа',
				warranty: 'до 2-х лет',
			},
			{
				id: 7,
				title: 'Не перемалывает кофе',
				price: '35 руб',
				time: '1-2 часа',
				warranty: 'до 2-х лет',
			},
		],
		priziv: `Не спешите расставаться со своей кофемашиной только потому, что она сломалась. Давайте вместе подумаем, как можно было бы починить ее, потратив при этом совсем немного. Ремонт позволит сэкономить немалые деньги и продлит жизнь вашему верному помощнику.

		У вас качественная импортная кофемашина, а покупка новой обойдется недешево. Но отремонтировав ее у профессионалов, она может прослужить вам еще долгие годы.
		
		Обратитесь к специалистам, опытные мастера быстро найдут неисправность и устранят ее. Вы сможете и дальше пользоваться своей кофемашиной, не тратя лишних средств на новую.
		
		Поверьте, ремонт - разумнее, чем покупка. Вместе мы найдем оптимальное решение для восстановления работоспособности вашего любимого агрегата!`,
		content: [
			{
				h4: 'Особенности ремонта кофемашин премиальных брендов',
				p: `Кофемашины известных брендов - это настоящее произведение инженерного искусства. Miele, Jura, Smeg, Krups, DeLonghi - каждая модель уникальна и требует особого подхода при ремонте. 
				
				Во-первых, мы используем запчасти исключительно от производителя. Каждая деталь проходит тщательный контроль качества, полностью совместима с оригиналом и восстанавливает работоспособность кофемашины. Это позволяет избежать повторных поломок и продлить срок службы техники.
				
				Во-вторых, ремонт осуществляет высококвалифицированный инженер, регулярно проходящий повышение квалификации на заводах изготовителя. Он знает конструкцию каждой модели вдоль и поперек, вплоть до самых мелких деталей. Это гарантирует постановку точного диагноза и профессиональное устранение любой неисправности.
				
				Мы относимся к каждой машине премиум-класса с особым трепетом, ведь понимаем, насколько дорога она для владельца. Поэтому стремимся максимально быстро возвратить к жизни ваш любимый агрегат и избавить от необходимости покупки нового.
				
				Наш подход - не просто устранить поломку, а обеспечить дальнейшую безупречную работу. Поэтому после ремонта мы тщательно тестируем кофемашину, настраиваем и доводим до идеального состояния. Вы сразу почувствуете разницу!
				
				Согласитесь, ремонт выгоднее покупки дорогостоящей новой техники премиум-класса. А наши профессионалы сделают это настолько качественно, что ваша кофемашина прослужит еще долгие годы. 
				
				Ценим каждого клиента и гордимся тем, что владельцы техники премиальных брендов выбирают именно наш сервис! Убедитесь сами в нашем профессионализме.`
			},

		]

	},
]