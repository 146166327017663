import s1 from '../../images/podazha/s-1.webp'
import s2 from '../../images/podazha/s-2.webp'
import s3 from '../../images/podazha/s-3.webp'
import s4 from '../../images/podazha/s-4.webp'
import s5 from '../../images/podazha/s-5.webp'
import s6 from '../../images/podazha/s-6.webp'
import s7 from '../../images/podazha/s-7.webp'
import s8 from '../../images/podazha/s-8.webp'
import s9 from '../../images/podazha/s-9.webp'
import s10 from '../../images/podazha/s-10.webp'
import s11 from '../../images/podazha/s-11.webp'
import s12 from '../../images/podazha/s-12.webp'
import s13 from '../../images/podazha/s-13.webp'
import s14 from '../../images/podazha/s-14.webp'
import s15 from '../../images/podazha/s-15.webp'
import s16 from '../../images/podazha/s-16.webp'
import s17 from '../../images/podazha/s-17.webp'
import s18 from '../../images/podazha/s-18.webp'
import s28 from '../../images/podazha/s-28.webp'
import s29 from '../../images/podazha/s-29.webp'
import s30 from '../../images/podazha/s-30.webp'
import s31 from '../../images/podazha/s-31.webp'
import s32 from '../../images/podazha/s-32.webp'
import s33 from '../../images/podazha/s-33.webp'
import s34 from '../../images/podazha/s-34.webp'
import s35 from '../../images/podazha/s-35.webp'
import s36 from '../../images/podazha/s-36.webp'
import s37 from '../../images/podazha/s-37.webp'
import s38 from '../../images/podazha/s-38.webp'
import s39 from '../../images/podazha/s-39.webp'
import s40 from '../../images/podazha/s-40.webp'
import s41 from '../../images/podazha/s-41.webp'
import s42 from '../../images/podazha/s-42.webp'
import s44 from '../../images/podazha/s-44.webp'
import s45 from '../../images/podazha/s-45.webp'
import s46 from '../../images/podazha/s-46.webp'
import s47 from '../../images/podazha/s-47.webp'
import s48 from '../../images/podazha/s-48.webp'
import s49 from '../../images/podazha/s-49.webp'
import s50 from '../../images/podazha/s-50.webp'
import s51 from '../../images/podazha/s-51.webp'
import s52 from '../../images/podazha/s-52.webp'
import s53 from '../../images/podazha/s-54.webp'
import s54 from '../../images/podazha/s-55.webp'
import s55 from '../../images/podazha/s-55.webp'
import s56 from '../../images/podazha/s-56.webp'
import s57 from '../../images/podazha/s-58.webp'
import s58 from '../../images/podazha/s-58.webp'
import s59 from '../../images/podazha/s-59.webp'
import s60 from '../../images/podazha/s-60.webp'
import s61 from '../../images/podazha/s-61.webp'
import s62 from '../../images/podazha/s-62.webp'
import s63 from '../../images/podazha/s-63.webp'
import s64 from '../../images/podazha/s-64.webp'
import s65 from '../../images/podazha/s-65.webp'
import s66 from '../../images/podazha/s-66.webp'

import s84 from '../../images/podazha/s-84.webp'
import s85 from '../../images/podazha/s-85.webp'
import s86 from '../../images/podazha/s-86.webp'
import s87 from '../../images/podazha/s-87.webp'
import s89 from '../../images/podazha/s-89.webp'
import s90 from '../../images/podazha/s-90.webp'

import h19 from '../../images/podazha/h-19.webp'
import h20 from '../../images/podazha/h-20.webp'
import h21 from '../../images/podazha/h-21.webp'
import h23 from '../../images/podazha/h-23.webp'
import h24 from '../../images/podazha/h-24.webp'
import h25 from '../../images/podazha/h-25.webp'
import h26 from '../../images/podazha/h-26.webp'
import h27 from '../../images/podazha/h-27.webp'
import h69 from '../../images/podazha/h-69.webp'
import h70 from '../../images/podazha/h-70.webp'
import h71 from '../../images/podazha/h-71.webp'
import h72 from '../../images/podazha/h-72.webp'
import h73 from '../../images/podazha/h-73.webp'
import h74 from '../../images/podazha/h-74.webp'
import h75 from '../../images/podazha/h-75.webp'
import h76 from '../../images/podazha/h-76.webp'
import h77 from '../../images/podazha/h-77.webp'
import h78 from '../../images/podazha/h-78.webp'
import h79 from '../../images/podazha/h-79.webp'
import h80 from '../../images/podazha/h-80.webp'
import h81 from '../../images/podazha/h-81.webp'
import h82 from '../../images/podazha/h-82.webp'
import h83 from '../../images/podazha/h-83.webp'
import h00 from '../../images/podazha/h-00.webp'
import p67 from '../../images/podazha/p-67.webp'
import p68 from '../../images/podazha/p-68.webp'



export const product = [
	{
		id: 1,
		group: 'Холодильники',
		box: [
			{
				id: 1,
				title: '',
				children: [
					{
						id: 1,
						name: 'Холодильник AEG',
						images: [
							h19,
							h20,
							h21
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник AEG',
						price: 1150,
						discount: 15,
						description: [
							'Тип: встраиваемый',
							'Модель: SCR81911TS'
						]
					},
					{
						id: 2,
						name: 'Холодильник Электролюкс',
						images: [
							h23,
							h24,
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Электролюкс',
						price: 480,
						discount: 15,
						description: [
							'NO FROST '
						]
					},
					{
						id: 3,
						name: 'Холодильник LG',
						images: [
							h25,
							h26,
							h27,
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник LG',
						price: 570,
						discount: 10,
						description: [
							'Модель: QA-479UBA'
						]
					},
					{
						id: 4,
						name: 'Холодильник Indesit',
						images: [
							h69
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Indesit',
						price: 750,
						discount: 10,
						description: [
							'Цвет: белый'
						]
					},
					{
						id: 5,
						name: 'Холодильник Daewoo',
						images: [
							h70,
							h71
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Daewoo',
						price: 500,
						discount: 10,
						description: [
							'Цвет: черный',
							'Маленький'
						]
					},
					{
						id: 6,
						name: 'Холодильник Shiwaki',
						images: [
							h72,
							h73
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Shiwaki',
						price: 420,
						discount: 10,
						description: [
							'Цвет: белый',
							'Маленький'
						]
					},
					{
						id: 7,
						name: 'Холодильник Bosch',
						images: [
							h74,
							h75
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Bosch',
						price: 750,
						discount: 10,
						description: [
							'Цвет: серебро',
							'Маленький'
						]
					},
					{
						id: 8,
						name: 'Холодильник Самсунг',
						images: [
							h76,
							h77
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Самсунг',
						price: 480,
						discount: 10,
						description: [
							'Цвет: белый',
							'Маленький'
						]
					},
					{
						id: 9,
						name: 'Холодильник Стинол',
						images: [
							h00
							
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Стинол',
						price: 400,
						discount: 10,
						description: [
							'Цвет: белый',
							'Маленький'
						]
					},
					{
						id: 10,
						name: 'Холодильник Атлант',
						images: [
							h78,
							h79
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Атлант',
						price: 380,
						discount: 10,
						description: [
							'Цвет: белый',
							'Маленький'
						]
					},
					{
						id: 11,
						name: 'Холодильник Whirpool',
						images: [
							h81,
							
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник Whirpool',
						price: 440,
						discount: 10,
						description: [
							'Цвет: белый',
							'Маленький'
						]
					},
					{
						id: 12,
						name: 'Холодильник LG',
						images: [
							h82,
							h83
						],
						dost1: 30,
						dost2: 40,
						dost3: 60,
						alt: 'Холодильник LG',
						price: 400,
						discount: 10,
						description: [
							'Цвет: серебро',
							'торг'
						]
					},
				],
			},
		]
	},
	{
		id: 2,
		group: 'Стиральные машины',
		box: [
			{
				id: 1,
				title: '',
				children: [
					{
						id: 1,
						name: 'Стиральная машина SAMSUNG Diamond',
						images: [
							s1,
							s2,
							s3
						],
						alt: 'Стиральная машина SAMSUNG',
						price: 670,
						discount: 12,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'6 кг.',
							'Отжим 1200 об. мин.',
							'Цвет: серебро',
						]
					},
					{
						id: 2,
						name: 'Стиральная машина BEKO',
						images: [
							s4,
							s5,
							s6
						],
						alt: 'Стиральная машина BEKO',
						price: 370,
						discount: 12,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Отжим 1200 об. мин.',
							'Цвет: белый',
						]
					},
					{
						id: 3,
						name: 'Стиральная машина Zanussi ZWSG7121V',
						images: [
							s7,
							s8,
							s9
						],
						alt: 'Стиральная машина Zanussi',
						price: 450,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'6 кг.',
							'Отжим 1200 об. мин.',
							'Цвет: белый',
						]
					},
					{
						id: 4,
						name: 'Стиральная машина Gorenje',
						images: [
							s10,
							s11,
							s12
						],
						alt: 'Стиральная машина Gorenje',
						price: 720,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5.5 кг.',
							'Отжим 1200 об. мин.',
							'Цвет: белый',
							'МодельSensoCare',
							'Гарантия: 6 месяцев'
						]
					},
					{
						id: 5,
						name: 'Стиральная машина Атлант',
						images: [
							s13,
							s14,
							s15
						],
						alt: 'Стиральная машина Атлант',
						price: 350,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Отжим 1200 об. мин.',
							'Цвет: белый',
							'Модель СМА 50У87 (MULTI FUNCTION)',
						]
					},
					{
						id: 6,
						name: 'Стиральная машина LG',
						images: [
							s16,
							s17,
							s18
						],
						alt: 'Стиральная машина LG',
						price: 370,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Отжим 1200 об. мин.',
							'Цвет: белый',
							'Модель: WD-10160N',
							'Размеры 60(ш)х44(г)х85(в)',
						]
					},
					{
						id: 7,
						name: 'Стиральная машина Атлант',
						images: [
							s28,
							s29,
							s30
						],
						alt: 'Стиральная машина Атлант',
						price: 400,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'6 кг.',
							'Multi Function',
							'Цвет: белый',
						]
					},
					{
						id: 8,
						name: 'Стиральная машина LG',
						images: [
							s31,
							s32,
							s33
						],
						alt: 'Стиральная машина LG',
						price: 370,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 9,
						name: 'Стиральная машина Самсунг Diamond ',
						images: [
							s34,
							s35,
							s36
						],
						alt: 'Стиральная машина Самсунг Diamond ',
						price: 580,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: серебро',
						]
					},
					{
						id: 10,
						name: 'Стиральная машина Самсунг',
						images: [
							s37,
							s38,
							s39
						],
						alt: 'Стиральная машина Самсунг',
						price: 400,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'4.5 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 11,
						name: 'Стиральная машина  LG',
						images: [
							s40,
							s41,
						],
						alt: 'Стиральная машина  LG',
						price: 370,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 12,
						name: 'Стиральная машина  LG',
						images: [
							s42
						],
						alt: 'Стиральная машина  LG',
						price: 370,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 13,
						name: 'Стиральная машина  Самсунг',
						images: [
							s44,
							s45
						],
						alt: 'Стиральная машина  Самсунг',
						price: 520,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 14,
						name: 'Стиральная машина LG',
						images: [
							s46,
							s47
						],
						alt: 'Стиральная машина LG',
						price: 580,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'6 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 15,
						name: 'Стиральная машина LG',
						images: [
							s48
						],
						alt: 'Стиральная машина LG',
						price: 550,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
						]
					},
					{
						id: 16,
						name: 'Стиральная машина Bosch',
						images: [
							s49,
							s50
						],
						alt: 'Стиральная машина Bosch',
						price: 550,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'7 кг.',
							'Цвет: белый',
							'Польша'
						]
					},
					{
						id: 17,
						name: 'Стиральная машина LG',
						images: [
							s51,
							s52
						],
						alt: 'Стиральная машина LG',
						price: 370,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: золото',
							''
						]
					},
					{
						id: 18,
						name: 'Стиральная машина LG',
						images: [
							s53,
							s54
						],
						alt: 'Стиральная машина LG',
						price: 480,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 19,
						name: 'Стиральная машина Candy',
						images: [
							s55,
							s56
						],
						alt: 'Стиральная машина Candy',
						price: 420,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 20,
						name: 'Стиральная машина Indesit',
						images: [
							s57,
							s58
						],
						alt: 'Стиральная машина Indesit',
						price: 400,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 21,
						name: 'Стиральная машина Атлант',
						images: [
							s59,
							s60
						],
						alt: 'Стиральная машина Атлант',
						price: 350,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 22,
						name: 'Стиральная машина Gorenje',
						images: [
							s61,
							s62
						],
						alt: 'Стиральная машина Gorenje',
						price: 400,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'4.5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 23,
						name: 'Стиральная машина Самсунг',
						images: [
							s63,
							s64
						],
						alt: 'Стиральная машина Самсунг',
						price: 370,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'3.5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 24,
						name: 'Стиральная машина Атлант',
						images: [
							s65,
							s66
						],
						alt: 'Стиральная машина Атлант',
						price: 320,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'4.5 кг.',
							'Цвет: белый',
							''
						]
					},
					{
						id: 25,
						name: 'Стиральная машина Candy',
						images: [
							s84,
							s85
						],
						alt: 'Стиральная машина Candy',
						price: 340,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый'
						]
					},
					{
						id: 26,
						name: 'Стиральная машина Gorenje',
						images: [
							s86,
							s87
						],
						alt: 'Стиральная машина Gorenje',
						price: 470,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'6 кг.',
							'Цвет: белый'
						]
					},
					{
						id: 27,
						name: 'Стиральная машина Indesit',
						images: [
							s89,
							s90
						],
						alt: 'Стиральная машина Indesit',
						price: 340,
						discount: 10,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'5 кг.',
							'Цвет: белый'
						]
					},
				]
			},
		]
	},
	{
		id: 3,
		group: 'Посудомоечные машины',
		box: [
			{
				id: 1,
				title: '',
				children: [
					{
						id: 1,
						name: 'Посудомоечная машина Самсунг',
						images: [
							p67,
							p68
						],
						alt: '',
						price: 550,
						discount: 8,
						dost1: 20,
						dost2: 30,
						dost3: 50,
						description: [
							'встраиваемая',
							'узкая 45см'
						]
					},
				]
			},
		]
	},
]



