export const stiralkaData = [
	{
		id: 1,
		h1: 'Онлайн-диагностика неисправности стиральной машины',
		p:'Заполните форму, чтобы узнать вероятную причину неисправности и ориентировочную цену ремонта стиральной машины!',
		description: 'Онлайн диагностика неисправности и расчет стоимости ремонта  стиральной машины. Доступные цены. Закажите ремонт прямо сейчас!',
		dataForm:
		{
			name: [
				{
					value: 'LG',
					label: 'LG',
				},
				{
					value: 'AEG',
					label: 'AEG',
				},
				{
					value: 'Samsung',
					label: 'Samsung',
				},
				{
					value: 'Ariston',
					label: 'Ariston',
				},
				{
					value: 'Атлант',
					label: 'Атлант',
				},
				{
					value: 'Beko',
					label: 'Beko',
				},
				{
					value: 'Bosch',
					label: 'Bosch',
				},
				{
					value: 'Candy',
					label: 'Candy',
				},
				{
					value: 'Electrolux',
					label: 'Electrolux',
				},
				{
					value: 'Indesit',
					label: 'Indesit',
				},
				{
					value: 'Siemens',
					label: 'Siemens',
				},
				{
					value: 'Whirlpool',
					label: 'Whirlpool',
				},
				{
					value: 'Zanussi',
					label: 'Zanussi',
				},
			],
			age: [
				{
					value: 'до 3 лет',
					label: 'до 3 лет',
				},
				{
					value: 'от 3 до 8 лет',
					label: 'от 3 до 8 лет',
				},
				{
					value: 'более 8 лет',
					label: 'более 8 лет',
				}
			],
			priznak: [
				{
					id:1,
					value: 'Не открывается люк',
					label: 'Не открывается люк',
				},
				{
					id:2,
					value: 'Не сливает воду (долго сливает)',
					label: 'Не сливает воду (долго сливает)',
				},
				{
					id:3,
					value: 'Протекает вода',
					label: 'Протекает вода',
				},
				{
					id:4,
					value: 'Не греет воду',
					label: 'Не греет воду',
				},
				{
					id:5,
					value: 'Не вращается барабан',
					label: 'Не вращается барабан',
				},
				{
					id:6,
					value: 'Долго стирает',
					label: 'Долго стирает',
				},
				{
					id:7,
					value: 'Стучит, шумит при отжиме',
					label: 'Стучит, шумит при отжиме',
				},
				{
					id:8,
					value: 'Не выключается',
					label: 'Не выключается',
				},
				{
					id:9,
					value: 'Выбивает автомат',
					label: 'Выбивает автомат',
				},
				{
					id:10,
					value: 'Постоянно набирает воду',
					label: 'Постоянно набирает воду',
				},
				{
					id:11,
					value: 'Долго отжимает',
					label: 'Долго отжимает',
				},
				{
					id:12,
					value: 'Не переходит на полоскание',
					label: 'Не переходит на полоскание',
				},
				{
					id:13,
					value: 'Много пены',
					label: 'Много пены',
				},
				{
					id:14,
					value: 'Набирает слишком много воды',
					label: 'Набирает слишком много воды',
				},
			],
			result: [
				{
					id: 1,
					value: 'Не открывается люк',
					info: [
						{
							id: 1,
							title: 'Сломана ручка двери',
							percent: '50%',
							remont: 'Замена ручки двери',
							price:'от 105 BYN'
						},
						{
							id: 2,
							title: 'Неисправно устройство блокировки люка',
							percent: '25%',
							remont: 'Замена устройства блокировки люка',
							price:'от 120 BYN'
						},
						{
							id: 3,
							title: 'Неисправна плата управления',
							percent: '10%',
							remont: 'Ремонт или замена платы управления',
							price:'от 150 BYN'
						},
						{
							id: 3,
							title: 'Не вышло время автоматической разблокировки люка',
							percent: '10%',
							remont: 'Подождать 2-4 минуты или вынуть вилку из розетки и подождать 2-4 минуты',
							price:'-'
						},
						{
							id: 4,
							title: 'Аварийная остановка машины с водой',
							percent: '5%',
							remont: 'Слить воду через аварийный шланг или сливное отверстие и открыть дверь люка',
							price:'-'
						},
					]
				},
				{
					id: 2,
					value: 'Не сливает воду (долго сливает)',
					info: [
						{
							id: 1,
							title: 'Возникновение засора в системе слива',
							percent: '60%',
							remont: 'Открыть, почистить сливной фильтр, отсоединить сливной гофрошланг от канализации и проверить работоспособность машины при сливе самостоятельно или вызов мастера',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Поломка сливного насоса',
							percent: '30%',
							remont: 'Замена устройства блокировки люка',
							price:'от 125 BYN'
						},
						{
							id: 3,
							title: 'Неисправна плата управления',
							percent: '10%',
							remont: 'Ремонт или замена платы управления',
							price:'от 150 BYN'
						}
					]
				},
				{
					id: 3,
					value: 'Протекает вода',
					info: [
						{
							id: 1,
							title: 'Повреждены шланги, патрубки отвода/забора воды',
							percent: '35%',
							remont: 'Заменить шланг подачи воды в машину самостоятельно или вызов мастера для устранения повреждений шлангов, патрубков отвода/забора воды',
							price:'от 120 BYN'
						},
						{
							id: 2,
							title: 'Порвана манжета люка',
							percent: '25%',
							remont: 'Замена манжеты люка',
							price:'от 125 BYN'
						},
						{
							id: 3,
							title: 'Негерметичен бункер для моющих средств',
							percent: '20%',
							remont: 'Герметизация бункера для моющих средств',
							price:'от 100 BYN'
						},
						{
							id: 4,
							title: 'Неисправен подшипниковый узел',
							percent: '10%',
							remont: 'Ремонт подшипникового узла',
							price:'от 120 BYN'
						},
						{
							id: 5,
							title: 'Поврежден бак',
							percent: '5%',
							remont: 'Герметизация бака',
							price:'от 70 BYN'
						},
						{
							id: 6,
							title: 'Сломана дверца люка',
							percent: '5%',
							remont: 'Ремонт или замена дверцы люка',
							price:'от 105 BYN'
						},
					]
				},
				{
					id: 4,
					value: 'Не греет воду',
					info: [
						{
							id: 1,
							title: 'Не работает ТЭН',
							percent: '60%',
							remont: 'Замена ТЭНа',
							price:'от 125 BYN'
						},
						{
							id: 2,
							title: 'Поломка модуля управления',
							percent: '25%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 150 BYN'
						},
						{
							id: 3,
							title: 'Неисправен прессостат',
							percent: '8%',
							remont: 'Замена прессостата',
							price:'от 70 BYN'
						},
						{
							id: 4,
							title: 'Повреждение электропроводки, контактов',
							percent: '5%',
							remont: 'Ремонт электропроводки, восстановление контактов',
							price:'от 90 BYN'
						},
						{
							id: 5,
							title: 'Неисправен термодатчик',
							percent: '2%',
							remont: 'Замена термодатчика',
							price:'от 100 BYN'
						},
					]
				},
				{
					id: 5,
					value: 'Не вращается барабан',
					info: [
						{
							id: 1,
							title: 'Неисправен двигатель привода',
							percent: '40%',
							remont: 'Ремонт или замена двигателя привода',
							price:'от 150 BYN'
						},
						{
							id: 2,
							title: 'Проблемы с модулем управления',
							percent: '30%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 150 BYN'
						},
						{
							id: 3,
							title: 'Вышли из строя подшипники',
							percent: '15%',
							remont: 'Замена подшипников, сальника',
							price:'от 120 BYN'
						},
						{
							id: 4,
							title: 'Сломана крестовина/опора барабана',
							percent: '10%',
							remont: 'Замена крестовины/опоры барабана',
							price:'от 200 BYN'
						},
						{
							id: 5,
							title: 'Порван или слетел ремень привода',
							percent: '4%',
							remont: 'Замена или установка ремня привода',
							price:'от 95 BYN'
						},
						{
							id: 6,
							title: 'Попадание в бак инородного тела',
							percent: '1%',
							remont: 'Устранение инородного тела из бака',
							price:'от 80 BYN'
						},
					]
				},
				{
					id: 6,
					value: 'Долго стирает',
					info: [
						{
							id: 1,
							title: 'Поломка модуля управления',
							percent: '21%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 150 BYN'
						},
						{
							id: 2,
							title: 'Не работает ТЭН',
							percent: '20%',
							remont: 'Замена ТЭНа',
							price:'от 125 BYN'
						},
						{
							id: 3,
							title: 'Засоры в шлангах, патрубках, фильтрах забора/отвода воды',
							percent: '15%',
							remont: 'Устранить засор самостоятельно или вызов мастера для устранения засора',
							price:'от 85 BYN'
						},
						{
							id: 4,
							title: 'Неисправен двигатель привода',
							percent: '14%',
							remont: 'Ремонт или замена двигателя',
							price:'от 150 BYN'
						},
						{
							id: 5,
							title: 'Неисправность сливного насоса',
							percent: '10%',
							remont: 'Замена сливного насоса',
							price:'от 125 BYN'
						},
						{
							id: 6,
							title: 'Слишком мало или много белья',
							percent: '9%',
							remont: 'Самостоятельно перераспределить белье',
							price:'-'
						},
						{
							id: 7,
							title: 'Неисправен прессостат',
							percent: '5%',
							remont: 'Замена прессостата',
							price:'от 70 BYN'
						},
						{
							id: 8,
							title: 'Растянут ремень привода',
							percent: '5%',
							remont: 'Замена ремня',
							price:'от 95 BYN'
						},
						{
							id: 9,
							title: 'Замена термодатчика',
							percent: '1%',
							remont: 'Замена термодатчика',
							price:'от 100 BYN'
						},
					]
				},
				{
					id: 7,
					value: 'Стучит, шумит при отжиме',
					info: [
						{
							id: 1,
							title: 'Неисправен подшипниковый узел',
							percent: '55%',
							remont: 'Ремонт подшипникового узла',
							price:'от 120 BYN'
						},
						{
							id: 2,
							title: 'Неисправны амортизаторы, пружины',
							percent: '35%',
							remont: 'Замена амортизаторов, пружин',
							price:'от 90 BYN'
						},
						{
							id: 3,
							title: 'Поломана крестовина/опора барабана',
							percent: '15%',
							remont: 'Замена крестовины/опоры',
							price:'от 200 BYN'
						},
						{
							id: 4,
							title: 'Неисправность модуля управления',
							percent: '7%',
							remont: 'Ремонт модуля управления',
							price:'от 150 BYN'
						},
						{
							id: 5,
							title: 'Не зафиксированы элементы, проводка',
							percent: '2%',
							remont: 'Фиксация элементов',
							price:'от 35 BYN'
						},
						{
							id: 6,
							title: 'Попало инородное тело в бак',
							percent: '1%',
							remont: 'Устранение инородного тела',
							price:'от 80 BYN'
						}
					]
				},
				{
					id: 8,
					value: 'Не выключается',
					info: [
						{
							id: 1,
							title: 'Неисправна электронная плата управления',
							percent: '65%',
							remont: 'Ремонт электронной платы или замена',
							price:'от 150 BYN'
						},
						{
							id: 2,
							title: 'Повреждена проводка, контакты',
							percent: '10%',
							remont: 'Восстановление проводки',
							price:'от 90 BYN'
						},
						{
							id: 3,
							title: 'Поврежден сетевой шнур',
							percent: '10%',
							remont: 'Замена шнура',
							price:'от 125 BYN'
						},
						{
							id: 4,
							title: 'Неисправность розетки или домашней электросети',
							percent: '10%',
							remont: 'Вызвать электрика, заменить розетку',
							price:'-'
						},
						{
							id: 5,
							title: 'Неисправность конденсатора',
							percent: '5%',
							remont: 'Замена конденсатора',
							price:'от 125 BYN'
						}
					
					]
				},
				{
					id: 9,
					value: 'Не набирает воду',
					info: [
						{
							id: 1,
							title: 'Возникновение засора в фильтре, патрубках',
							percent: '35%',
							remont: 'Устранение засора в фильтре самостоятельно или вызов мастера для устранения засора',
							price:'от 85 BYN'
						},
						{
							id: 2,
							title: 'Неисправность электронного модуля',
							percent: '35%',
							remont: 'Ремонт или замена модуля',
							price:'от 150 BYN'
						},
						{
							id: 3,
							title: 'Поломка устройства блокировки люка (УБЛ)',
							percent: '15%',
							remont: 'Замена УБЛ',
							price:'от 120 BYN'
						},
						{
							id: 4,
							title: 'Сломана дверца люка',
							percent: '15%',
							remont: 'Ремонт или замена дверцы',
							price:'от 105 BYN'
						}
					]
				},
				{
					id: 10,
					value: 'Выбивает автомат',
					info: [
						{
							id: 1,
							title: 'Вышел из строя ТЭН',
							percent: '45%',
							remont: 'Замена ТЭНа',
							price:'от 125 BYN'
						},
						{
							id: 2,
							title: 'Неисправна электронная плата',
							percent: '25%',
							remont: 'Ремонт или замена платы',
							price:'от 150 BYN'
						},
						{
							id: 3,
							title: 'Неисправен электромотор привода',
							percent: '20%',
							remont: 'Ремонт или замена мотора',
							price:'от 150 BYN'
						},
						{
							id: 4,
							title: 'Повреждена проводка, контакты',
							percent: '10%',
							remont: 'Восстановление контактов',
							price:'от 90 BYN'
						}
					]
				},
				{
					id: 11,
					value: 'Постоянно набирает воду',
					info: [
						{
							id: 1,
							title: 'Неисправна плата управления',
							percent: '70%',
							remont: 'Ремонт или замена платы',
							price:'от 150 BYN'
						},
						{
							id: 2,
							title: 'Вышел из строя ТЭНа',
							percent: '20%',
							remont: 'Замена ТЭНа',
							price:'от 125 BYN'
						},
						{
							id: 3,
							title: 'Выход из строя прессостата',
							percent: '10%',
							remont: 'Замена прессостата',
							price:'от 70 BYN'
						}
					]
				},
				{
					id: 12,
					value: 'Долго отжимает',
					info: [
						{
							id: 1,
							title: 'Вышел из строя таходатчик на электродвигателе',
							percent: '50%',
							remont: 'Замена таходатчика на двигателе',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Засор в системе слива воды',
							percent: '20%',
							remont: 'Устранение засора самостоятельно или вызов мастера',
							price:'от 70 BYN'
						},
						{
							id: 3,
							title: 'Неисправен насос слива воды',
							percent: '20%',
							remont: 'Замена насоса',
							price:'от 125 BYN'
						},
						{
							id: 4,
							title: 'Неисправна электронная плата управления',
							percent: '10%',
							remont: 'Ремонт или замена платы',
							price:'от 150 BYN'
						},
					]
				},
				{
					id: 13,
					value: 'Не переходит на полоскание',
					info: [
						{
							id: 1,
							title: 'Засор в системе слива воды',
							percent: '30%',
							remont: 'Устранение засора самостоятельно или вызов мастера',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Не работает ТЭН',
							percent: '25%',
							remont: 'Замена ТЭНа',
							price:'от 125 BYN'
						},
						{
							id: 3,
							title: 'Неисправен насос слива воды',
							percent: '25%',
							remont: 'Замена насоса',
							price:'от 125 BYN'
						},
						{
							id: 4,
							title: 'Неисправна электронная плата управления',
							percent: '10%',
							remont: 'Ремонт или замена платы',
							price:'от 150 BYN'
						},
					]
				},
				{
					id: 14,
					value: 'Много пены',
					info: [
						{
							id: 1,
							title: 'Засор в патрубках, фильтре',
							percent: '55%',
							remont: 'Устранение засора самостоятельно или вызвать мастера',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Передозирован порошок, использовано ненадлежащее моющее средство',
							percent: '45%',
							remont: 'Дождаться осаждения пены, очистить с помощью специальных средств',
							price:'-'
						}
					]
				},
				{
					id: 15,
					value: 'Набирает слишком много воды',
					info: [
						{
							id: 1,
							title: 'Неисправен прессостат',
							percent: '50%',
							remont: 'Замена прессостата',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Неисправны клапаны набора воды',
							percent: '30%',
							remont: 'Замена клапанов',
							price:'от 120 BYN'
						},
						{
							id: 3,
							title: 'Отказ в работе электронной платы',
							percent: '20%',
							remont: 'Ремонт или замена платы',
							price:'от 150 BYN'
						}
					]
				},
			]
		},
	}
]

export const holodilnikData = [
	{
		id: 1,
		h1: 'Онлайн-диагностика неисправности холодильника',
		p:'Заполните форму, чтобы узнать вероятную причину неисправности и ориентировочную цену ремонта холодильника!',
		description: 'Онлайн диагностика неисправности и расчет стоимости ремонта холодильника. Доступные цены. Закажите ремонт выгодно!',
		dataForm:
		{
			name: [
				{
					value: 'LG',
					label: 'LG',
				},
				{
					value: 'AEG',
					label: 'AEG',
				},
				{
					value: 'Samsung',
					label: 'Samsung',
				},
				{
					value: 'Ariston',
					label: 'Ariston',
				},
				{
					value: 'Атлант',
					label: 'Атлант',
				},
				{
					value: 'Beko',
					label: 'Beko',
				},
				{
					value: 'Bosch',
					label: 'Bosch',
				},
				{
					value: 'Candy',
					label: 'Candy',
				},
				{
					value: 'Electrolux',
					label: 'Electrolux',
				},
				{
					value: 'Indesit',
					label: 'Indesit',
				},
				{
					value: 'Siemens',
					label: 'Siemens',
				},
				{
					value: 'Whirlpool',
					label: 'Whirlpool',
				},
				{
					value: 'Zanussi',
					label: 'Zanussi',
				},
				{
					value: 'Hitachi',
					label: 'Hitachi',
				},
				{
					value: 'Neff',
					label: 'Neff',
				},
				{
					value: 'Liebherr',
					label: 'Liebherr',
				},
				{
					value: 'Sharp',
					label: 'Sharp',
				},
				{
					value: 'Mitsubishi',
					label: 'Mitsubishi',
				},
				{
					value: 'Toshiba',
					label: 'Toshiba',
				},
				{
					value: 'Smeg',
					label: 'Smeg',
				},
				{
					value: 'Asko',
					label: 'Asko',
				},
				{
					value: 'Vestfrost',
					label: 'Vestfrost',
				},
				{
					value: 'Weissgauff',
					label: 'Weissgauff',
				},
				{
					value: 'Miele',
					label: 'Miele',
				},
				{
					value: 'Kupersbusch',
					label: 'Kupersbusch',
				},
				{
					value: 'Bauknecht',
					label: 'Bauknecht',
				},
				{
					value: 'Gaggenau',
					label: 'Gaggenau',
				},
				{
					value: 'Gorenje',
					label: 'Gorenje',
				},
			],
			age: [
				{
					value: 'до 3 лет',
					label: 'до 3 лет',
				},
				{
					value: 'от 3 до 8 лет',
					label: 'от 3 до 8 лет',
				},
				{
					value: 'более 8 лет',
					label: 'более 8 лет',
				}
			],
			priznak: [
				{
					id:1,
					value: 'Не запускается двигатель холодильника (не включается мотор)',
					label: 'Не запускается двигатель холодильника (не включается мотор)',
				},
				{
					id:2,
					value: 'Морозилка работает, а холодильная камер нет',
					label: 'Морозилка работает, а холодильная камер нет',
				},
				{
					id:3,
					value: 'Не морозит морозильная камера холодильника',
					label: 'Не морозит морозильная камера холодильника',
				},
				{
					id:4,
					value: 'Не размораживается задняя стенка холодильника',
					label: 'Не размораживается задняя стенка холодильника',
				},
				{
					id:5,
					value: 'Греется компрессор холодильника и не морозит',
					label: 'Греется компрессор холодильника и не морозит',
				},
				{
					id:6,
					value: 'Не отключается компрессор (холодильник все время работает)',
					label: 'Не отключается компрессор (холодильник все время работает)',
				},
				{
					id:7,
					value: 'После разморозки холодильник не отключается',
					label: 'После разморозки холодильник не отключается',
				},
				{
					id:8,
					value: 'Холодильник не морозит',
					label: 'Холодильник не морозит',
				},
				{
					id:9,
					value: 'Холодильник не показывает температуру',
					label: 'Холодильник не показывает температуру',
				},
				{
					id:10,
					value: 'Не работает вентилятор',
					label: 'Не работает вентилятор',
				},
				{
					id:11,
					value: 'Холодильник щелкает но не включается',
					label: 'Холодильник щелкает но не включается',
				},
				{
					id:12,
					value: 'Не горит лампочка в холодильнике',
					label: 'Не горит лампочка в холодильнике',
				},
				{
					id:13,
					value: 'Не выключается свет в холодильнике',
					label: 'Не выключается свет в холодильнике',
				},
				{
					id:14,
					value: 'Не закрывается дверь холодильника',
					label: 'Не закрывается дверь холодильника',
				},
				{
					id:15,
					value: 'Не работает дисплей',
					label: 'Не работает дисплей',
				},
				{
					id:16,
					value: 'Не горят индикаторы',
					label: 'Не горят индикаторы',
				},
			],
			result: [
				{
					id: 1,
					value: 'Не запускается двигатель холодильника (не включается мотор)',
					info: [
						{
							id: 1,
							title: 'Неисправно пусковое реле',
							percent: '35%',
							remont: 'Замена пускового реле',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Не работает термостат',
							percent: '35%',
							remont: 'Замена термостата',
							price:'от 60 BYN'
						},
						{
							id: 3,
							title: 'Не исправен мотор-компрессор',
							percent: '30%',
							remont: 'Ремонт или замена мотора-компрессора',
							price:'от 150 BYN'
						},
					]
				},
				{
					id: 2,
					value: 'Морозилка работает, а холодильная камер нет',
					info: [
						{
							id: 1,
							title: 'Утечка хладагента по тепловому контуру',
							percent: '60%',
							remont: 'Заправка хладагента',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Засорение капиллярной трубки',
							percent: '40%',
							remont: 'Необходимо прочистить дренажное отверстие при помощи клизмы, спринцовки, шприца с водой или тонкой проволоки. Перед процедурой отключите прибор от сети и выньте нижние ящики из холодильной камеры для удобства работы.',
							price:'-'
						}
					]
				},
				{
					id: 3,
					value: 'Не морозит морозильная камера холодильника',
					info: [
						{
							id: 1,
							title: 'Утечка фреона (хладагента)',
							percent: '35%',
							remont: 'Заправка фреона',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Вышел из строя мотор-компрессор',
							percent: '25%',
							remont: 'Замена мотора-компрессора',
							price:'от 125 BYN'
						},
						{
							id: 3,
							title: 'Неисправности терморегулятора',
							percent: '40%',
							remont: 'Замена терморегулятора',
							price:'от 60 BYN'
						}
					]
				},
				{
					id: 4,
					value: 'Не размораживается задняя стенка холодильника',
					info: [
						{
							id: 1,
							title: 'Промерзание холодильного шкафа',
							percent: '100%',
							remont: 'Ремонт задней стенки',
							price:'от 125 BYN'
						},
						
					]
				},
				{
					id: 5,
					value: 'Греется компрессор холодильника и не морозит',
					info: [
						{
							id: 1,
							title: 'Неисправно пуско-защитное реле',
							percent: '70%',
							remont: 'Замена пуско-защитного реле',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Не работает мотор-компрессор',
							percent: '30%',
							remont: 'Ремонт или замена мотора-компрессора',
							price:'от 150 BYN'
						},
						
					]
				},
				{
					id: 6,
					value: 'Не отключается компрессор (холодильник все время работает)',
					info: [
						{
							id: 1,
							title: 'Не плотно прилегает уплотнитель двери холодильной или морозильной камеры',
							percent: '50%',
							remont: 'Износ или повреждение резинового уплотнителя на дверце аппарата. Дверца прилегает неплотно, из-за этого теплый воздух попадает в холодильную камеру, возможно намораживание "шубы" на стенках. Компрессор вынужден работать на износ, холодильник морозит сильнее, в связи с этим возможно подмораживание продуктов.',
							price:'от 80 BYN'
						},
						{
							id: 2,
							title: 'Не исправен термостат',
							percent: '50%',
							remont: 'Замена термостата',
							price:'от 60 BYN'
						}
					]
				},
				{
					id: 7,
					value: 'После разморозки холодильник не отключается',
					info: [
						{
							id: 1,
							title: 'Утечка хладагента',
							percent: '60%',
							remont: 'Поиск места утечки, запайка или замена трубки, заправка хладагента',
							price:'от 120 BYN'
						},
						{
							id: 2,
							title: 'Неисправности терморегулятора',
							percent: '40%',
							remont: 'Замена терморегулятора',
							price:'от 60 BYN'
						}
					]
				},
				{
					id: 8,
					value: 'Холодильник не морозит',
					info: [
						{
							id: 1,
							title: 'Не исправен мотор-компрессор',
							percent: '20%',
							remont: 'Замена мотора-компрессора',
							price:'от 150 BYN'
						},
						{
							id: 2,
							title: 'Сломался термостат или датчик температуры',
							percent: '30%',
							remont: 'Замена термостата или датчика',
							price:'от 60 BYN'
						},
						{
							id: 3,
							title: 'Утечка хладагента по тепловому контуру',
							percent: '25%',
							remont: 'Поиск места утечки, запайка или замена трубки, заправка хладагента',
							price:'от 120 BYN'
						},
						{
							id: 4,
							title: 'Засорение капиллярной трубки',
							percent: '25%',
							remont: 'Необходимо прочистить дренажное отверстие при помощи клизмы, спринцовки, шприца с водой или тонкой проволоки. Перед процедурой отключите прибор от сети и выньте нижние ящики из холодильной камеры для удобства работы.',
							price:'-'
						}
					]
				},
				{
					id: 9,
					value: 'Холодильник не показывает температуру',
					info: [
						{
							id: 1,
							title: 'Не исправен блок индикации',
							percent: '35%',
							remont: 'Ремонт или замена блока индикации',
							price:'от 100 BYN'
						},
						{
							id: 2,
							title: 'Вышел из строя датчик температуры ',
							percent: '65%',
							remont: 'Ремонт или замена датчика',
							price:'от 60 BYN'
						}
					]
				},
				{
					id: 10,
					value: 'Не работает вентилятор',
					info: [
						{
							id: 1,
							title: 'Требуется ремонт или замена вентилятора',
							percent: '100%',
							remont: 'Ремонт или замена вентилятора',
							price:'от 100 BYN'
						}
					]
				},
				{
					id: 11,
					value: 'Холодильник долго не включается',
					info: [
						{
							id: 1,
							title: 'Не исправен термостат',
							percent: '100%',
							remont: 'Замена термостата',
							price:'от 60 BYN'
						}
					]
				},
				{
					id: 12,
					value: 'Холодильник щелкает но не включается',
					info: [
						{
							id: 1,
							title: 'Не работает пускового реле',
							percent: '70%',
							remont: 'Требуется замена пускового реле',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Не исправен мотор-компрессор',
							percent: '30%',
							remont: 'Ремонт или замена мотора-компрессора',
							price:'от 150 BYN'
						}
					]
				},
				{
					id: 13,
					value: 'Не горит лампочка в холодильнике',
					info: [
						{
							id: 1,
							title: 'Перегорела лампочка',
							percent: '70%',
							remont: 'Замена лампочки',
							price:'от 40 BYN'
						},
						{
							id: 2,
							title: 'Неисправна кнопка включения лампы',
							percent: '30%',
							remont: 'Ремонт или замена кнопки',
							price:'от 75 BYN'
						}
					]
				},
				{
					id: 14,
					value: 'Не выключается свет в холодильнике',
					info: [
						{
							id: 1,
							title: 'Просела дверь и не достает до кнопки выключателя',
							percent: '55%',
							remont: 'Регулировка (ремонт) двери',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Неисправна кнопка выключения',
							percent: '45%',
							remont: 'Ремонт или замена кнопки',
							price:'от 75 BYN'
						}
					]
				},
				{
					id: 15,
					value: 'Не закрывается дверь холодильника',
					info: [
						{
							id: 1,
							title: 'Провисла дверь ',
							percent: '50%',
							remont: 'Регулировка (ремонт) двери',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Требуется замена уплотнителя двери',
							percent: '50%',
							remont: 'Замена уплотнителя двери ',
							price:'от 80 BYN'
						}
					]
				},
				{
					id: 16,
					value: 'Не работает дисплей',
					info: [
						{
							id: 1,
							title: 'Поврежден шлейф',
							percent: '50%',
							remont: 'Ремонт или замена шлейфа',
							price:'от 100 BYN'
						},
						{
							id: 2,
							title: 'Неиcправен дисплей',
							percent: '50%',
							remont: 'Ремонт или замена дисплея',
							price:'от 150 BYN'
						}
					]
				},
				{
					id: 17,
					value: 'Не горят индикаторы',
					info: [
						{
							id: 1,
							title: 'Не исправен блок индикации',
							percent: '50%',
							remont: 'Замена или ремонт блока',
							price:'от 150 BYN'
						},
						{
							id: 2,
							title: 'Не исправен шлейф блока индикации',
							percent: '50%',
							remont: 'Ремонт или замена шлейфа',
							price:'от 100 BYN'
						}
					]
				},
			]
		},
	}
]


export const posudomojkaData = [
	{
		id: 1,
		h1: 'Онлайн-диагностика неисправности посудомоечной машины',
		p:'Заполните форму, чтобы узнать вероятную причину неисправности и ориентировочную цену ремонта посудомоечной машины!',
		description: 'Онлайн диагностика неисправности и расчет стоимости ремонта посудомоечной машины. Доступные цены. Закажите ремонт прямо сейчас!',
		dataForm:
		{
			name: [
				{
					value: 'LG',
					label: 'LG',
				},
				{
					value: 'AEG',
					label: 'AEG',
				},
				{
					value: 'Samsung',
					label: 'Samsung',
				},
				{
					value: 'Ariston',
					label: 'Ariston',
				},
				{
					value: 'Атлант',
					label: 'Атлант',
				},
				{
					value: 'Beko',
					label: 'Beko',
				},
				{
					value: 'Bosch',
					label: 'Bosch',
				},
				{
					value: 'Candy',
					label: 'Candy',
				},
				{
					value: 'Electrolux',
					label: 'Electrolux',
				},
				{
					value: 'Indesit',
					label: 'Indesit',
				},
				{
					value: 'Siemens',
					label: 'Siemens',
				},
				{
					value: 'Whirlpool',
					label: 'Whirlpool',
				},
				{
					value: 'Zanussi',
					label: 'Zanussi',
				},
				{
					value: 'Haier',
					label: 'Haier',
				},
				{
					value: 'Maunfeld',
					label: 'Maunfeld',
				},
				{
					value: 'Midea',
					label: 'Midea',
				},
				{
					value: 'Weissgauff',
					label: 'Weissgauff',
				},
			],
			age: [
				{
					value: 'до 3 лет',
					label: 'до 3 лет',
				},
				{
					value: 'от 3 до 8 лет',
					label: 'от 3 до 8 лет',
				},
				{
					value: 'более 8 лет',
					label: 'более 8 лет',
				}
			],
			priznak: [
				{
					id:1,
					value: 'Не включается',
					label: 'Не включается',
				},
				{
					id:2,
					value: 'Не запускается программа',
					label: 'Не запускается программа',
				},
				{
					id:3,
					value: 'Не греет',
					label: 'Не греет',
				},
				{
					id:4,
					value: 'Не сливает воду',
					label: 'Не сливает воду',
				},
				{
					id:5,
					value: 'Срабатывание системы Аквастоп',
					label: 'Срабатывание системы Аквастоп',
				},
				{
					id:6,
					value: 'Наружные протекания воды',
					label: 'Наружные протекания воды',
				},
				{
					id:7,
					value: 'Шумно работает',
					label: 'Шумно работает',
				},
				{
					id:8,
					value: 'Выбивает автомат',
					label: 'Выбивает автомат',
				},
				{
					id:9,
					value: 'Плохо отмывает посуду',
					label: 'Плохо отмывает посуду',
				},
				{
					id:10,
					value: 'Неприятный запах',
					label: 'Неприятный запах',
				},
				{
					id:11,
					value: 'Не отключается',
					label: 'Не отключается',
				},
				{
					id:12,
					value: 'Не открывается',
					label: 'Не открывается',
				},
				{
					id:13,
					value: 'Не закрывается',
					label: 'Не закрывается',
				},
				{
					id:14,
					value: 'Не растворяются таблетки',
					label: 'Не растворяются таблетки',
				},
				{
					id:15,
					value: 'Не сушит',
					label: 'Не сушит',
				},
			],
			result: [
				{
					id: 1,
					value: 'Не выключается',
					info: [
						{
							id: 1,
							title: 'Не работает электронный модуль',
							percent: '55%',
							remont: 'Ремонт модуля управления',
							price:'от 80 BYN'
						},
						{
							id: 2,
							title: 'Неисправности кнопок',
							percent: '20%',
							remont: 'Ремонт или замена проводки посудомоечной машины',
							price:'от 40 BYN'
						},
						{
							id: 3,
							title: 'Неисправность сетевого фильтра',
							percent: '5%',
							remont: 'Ремонт или замена сетевого фильтра',
							price:'от 45 BYN'
						},
						{
							id: 3,
							title: 'Неисправность сетевого шнура',
							percent: '5%',
							remont: 'Замена сетевого шнура',
							price:'от 45 BYN'
						},
						{
							id: 4,
							title: '',
							percent: '5%',
							remont: '',
							price:'от 150 BYN'
						},
					]
				},
				{
					id: 2,
					value: 'Не запускается программа',
					info: [
						{
							id: 1,
							title: 'Неисправность циркуляционного насоса',
							percent: '40%',
							remont: 'Ремонт или замена насоса',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Не работает ТЭН',
							percent: '30%',
							remont: 'Замена ТЭНа',
							price:'от 70 BYN'
						},
						{
							id: 3,
							title: 'Засор в системе подачи воды',
							percent: '10%',
							remont: 'Устранение засора в системе подачи воды',
							price:'от 40 BYN'
						},
						{
							id: 4,
							title: 'Поломка клапана подачи воды',
							percent: '15%',
							remont: 'Замена клапана подачи воды',
							price:'от 80 BYN'
						}
					]
				},
				{
					id: 3,
					value: 'Не греет',
					info: [
						{
							id: 1,
							title: 'Неисправен ТЭН',
							percent: '75%',
							remont: 'Замена ТЭНа',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Неисправен термодатчик',
							percent: '20%',
							remont: 'Замена термодатчика',
							price:'от 65 BYN'
						},
						{
							id: 3,
							title: 'Нарушена циркуляция воды',
							percent: '5%',
							remont: 'Поиск и устранение засоров, ремонт или замена насоса',
							price:'от 50 BYN'
						}
					]
				},
				{
					id: 4,
					value: 'Не сливает воду',
					info: [
						{
							id: 1,
							title: 'Не работает сливной насос',
							percent: '45%',
							remont: 'Ремонт или замена насоса',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Засор в патрубках или шлангах',
							percent: '30%',
							remont: 'Поиск и устранение засора',
							price:'от 50 BYN'
						},
						{
							id: 3,
							title: 'Неисправен прессостат',
							percent: '15%',
							remont: 'Замена прессостата',
							price:'от 60 BYN'
						},
						{
							id: 4,
							title: 'Поломка электронного модуля',
							percent: '10%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 180 BYN'
						}
					]
				},
				{
					id: 5,
					value: 'Срабатывание системы Аквастоп',
					info: [
						{
							id: 1,
							title: 'Износ уплотнителей, сальника насоса',
							percent: '65%',
							remont: 'Замена уплотнителей и сальников',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Разгерметизация соединений патрубков и шлангов',
							percent: '25%',
							remont: 'Проверка соединений, замена патрубков или шлангов',
							price:'от 50 BYN'
						},
						{
							id: 3,
							title: 'Неисправность датчика системы Аквастоп',
							percent: '5%',
							remont: 'Замена неисправного датчика',
							price:'от 70 BYN'
						},
						{
							id: 4,
							title: 'Поломка электронного модуля',
							percent: '10%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 80 BYN'
						}
					]
				},
				{
					id: 6,
					value: 'Шумно работает',
					info: [
						{
							id: 1,
							title: 'Некорректно работает циркуляционный насос',
							percent: '80%',
							remont: 'Ремонт или замена циркуляционного насоса',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Некорректно работает сливной насос',
							percent: '12%',
							remont: 'Ремонт или замена сливного насоса',
							price:'от 70 BYN'
						},
						{
							id: 3,
							title: 'Поломка импеллера',
							percent: '8%',
							remont: 'Ремонт или замена импеллера',
							price:'от 50 BYN'
						}
					]
				},
				{
					id: 7,
					value: 'Выбивает автомат',
					info: [
						{
							id: 1,
							title: 'Неисправен ТЭН',
							percent: '80%',
							remont: 'Замена ТЭНа',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Неисправен циркуляционный насос',
							percent: '15%',
							remont: 'Замена насоса',
							price:'от 60 BYN'
						},
						{
							id: 3,
							title: 'Неисправность электропроводки',
							percent: '5%',
							remont: 'Ремонт или замена проводки ПММ',
							price:'от 50 BYN'
						}
					]
				},
				{
					id: 8,
					value: 'Плохо отмывает посуду',
					info: [
						{
							id: 1,
							title: 'Недостаток мощности циркуляционного насоса',
							percent: '45%',
							remont: 'Ремонт или замена насоса',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Не работает ТЭН',
							percent: '30%',
							remont: 'Замена ТЭНа',
							price:'от 70 BYN'
						},
						{
							id: 3,
							title: 'Засор импеллера',
							percent: '15%',
							remont: 'Чистка импеллера',
							price:'от 50 BYN'
						},
						{
							id: 4,
							title: 'Некорректно работает дозатор моющих средств',
							percent: '10%',
							remont: 'Чистка, ремонт или замена дозатора моющих средств',
							price:'от 60 BYN'
						}
					]
				},
				{
					id: 9,
					value: 'Неприятный запах',
					info: [
						{
							id: 1,
							title: 'Засоры фильтров и импеллеров',
							percent: '100%',
							remont: 'Чистка фильтров и импеллеров',
							price:'от 45 BYN'
						}
					]
				},
				{
					id: 10,
					value: 'Не отключается',
					info: [
						{
							id: 1,
							title: 'Протекание воды в поддон и срабатывание системы защиты от протечек',
							percent: '30%',
							remont: 'Герметизация узлов, замена сальников',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Неисправность сливного насоса',
							percent: '25%',
							remont: 'Замена сливного насоса',
							price:'от 70 BYN'
						},
						{
							id: 3,
							title: 'Долгий набор воды при засоре системы подачи',
							percent: '20%',
							remont: 'Чистка засоров в системе подачи воды',
							price:'от 70 BYN'
						},
						{
							id: 4,
							title: 'Недостаточная циркуляция из-за неисправности насоса',
							percent: '15%',
							remont: 'Ремонт или замена циркуляционного насоса',
							price:'от 60 BYN'
						},
						{
							id: 5,
							title: 'Засор фильтров',
							percent: '4%',
							remont: 'Чистка фильтров',
							price:'от 45 BYN'
						},
						{
							id: 6,
							title: 'Неисправность датчика уровня воды',
							percent: '30%',
							remont: 'Замена датчика уровня воды',
							price:'от 60 BYN'
						},
						{
							id: 7,
							title: 'Выход из строя электронного модуля',
							percent: '3%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 45 BYN'
						}
					]
				},
				{
					id: 11,
					value: 'Не открывается',
					info: [
						{
							id: 1,
							title: 'Неисправность блокиратора двери',
							percent: '70%',
							remont: 'Ремонт или замена блокиратора двери',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Поломка петель двери',
							percent: '20%',
							remont: 'Замена петель двери',
							price:'от 60 BYN'
						},
						{
							id: 3,
							title: 'Неисправность электронного модуля',
							percent: '10%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 80 BYN'
						}
					]
				},
				{
					id: 12,
					value: 'Не закрывается',
					info: [
						{
							id: 1,
							title: 'Неисправность блокиратора двери',
							percent: '70%',
							remont: 'Ремонт или замена блокиратора двери',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Поломка петель двери',
							percent: '2%',
							remont: 'Замена петель двери',
							price:'от 60 BYN'
						},
						{
							id: 3,
							title: 'Износ уплотнителя двери',
							percent: '5%',
							remont: 'Замена уплотнителя двери',
							price:'от 45 BYN'
						},
						{
							id: 4,
							title: 'Неисправность электронного модуля',
							percent: '5%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 80 BYN'
						},
					]
				},
				{
					id: 13,
					value: 'Не растворяются таблетки',
					info: [
						{
							id: 1,
							title: 'Выход из строя циркуляционного насоса',
							percent: '40%',
							remont: 'Ремонт или замена циркуляционного насоса',
							price:'от 60 BYN'
						},
						{
							id: 2,
							title: 'Не нагревает воду из-за поломки ТЭНа',
							percent: '20%',
							remont: 'Замена ТЭНа',
							price:'от 70 BYN'
						},
						{
							id: 3,
							title: 'Неисправен механизм отсека для таблетки',
							percent: '15%',
							remont: 'Ремонт или замена отсека для таблеток',
							price:'от 70 BYN'
						},
						{
							id: 4,
							title: 'Неисправность распределителя потоков',
							percent: '15%',
							remont: 'Ремонт или замена распределителя потоков',
							price:'от 150 BYN'
						},
						{
							id: 5,
							title: 'Неисправность форсунок импеллеров',
							percent: '05%',
							remont: 'Чистка или замена форсунок',
							price:'от 150 BYN'
						},
					]
				},
				{
					id: 14,
					value: 'Не сушит',
					info: [
						{
							id: 1,
							title: 'Вышел из строя ТЭН',
							percent: '75%',
							remont: 'Замена ТЭНа',
							price:'от 70 BYN'
						},
						{
							id: 2,
							title: 'Проблема с электронным модулем',
							percent: '20%',
							remont: 'Ремонт или замена модуля управления',
							price:'от 80 BYN'
						},
						{
							id: 5,
							title: 'Неисправность термодатчика',
							percent: '5%',
							remont: 'Замена термодатчика',
							price:'от 60 BYN'
						}
					]
				}
			]
		},
	}
]