import React from 'react'
import { Helmet } from "react-helmet"
import { Image } from 'antd'
import { motion } from "framer-motion"
import { titleAnimation, titleAnimation2, yAnimation } from '../../constants/animation/AnimationConst'
import techika from '../../images/techika.webp'
import BreadCrumbComp from '../../components/breadcrumb/BreadcrumbComp'

export const AboutPage = () => {
	return (
		<section className='pb-12 w-full z-10 min-h-[60vh]' id='aktsii'>
			<Helmet>
				<title>{'О нас'}</title>
				<meta name="description" content={'Фирма по ремонту бытовой техники'} />
			</Helmet>
			{/* <FloatButton.BackTop shape="square" /> */}
			<BreadCrumbComp />
			<div className='container mx-auto'>
				
				<motion.div
					className='mt-12'
					initial="hidden"
					whileInView="visible"
				>
					<motion.h1
						className='text-3xl uppercase font-black mb-8'
						variants={titleAnimation}
					>
						О нас
					</motion.h1>

					<motion.div
						variants={titleAnimation2}>
						<Image src={techika}/>
					</motion.div>

					<motion.p
						variants={yAnimation}
						className='font-light'
					>
						<span className='block mb-3'>
						Добро пожаловать на страницу "О нас"! Мы хотим поделиться с вами некоторыми дополнительными сведениями о нашей компании и наших ценностях.
						</span>
						<span className='block mb-3'>
						Наша компания по ремонту бытовой техники - небольшая, но стремительно растущая. Мы основаны с ясной целью - предоставить высококачественное обслуживание и решить любые проблемы, связанные с вашей бытовой техникой. Наш многолетний опыт работы в этой отрасли позволяет нам быть в курсе последних технологий и трендов. Наши опытные мастера обладают глубокими знаниями и умениями, необходимыми для успешного ремонта самых различных типов бытовых устройств. Мы гордимся тем, что можем предложить надежное обслуживание, которое приводит к долгосрочным и качественным решениям для наших клиентов.
						</span>
						<span className='block mb-3'>
						Наша команда стремится к постоянному совершенствованию и повышению качества предоставляемых услуг. Мы вкладываем все наши усилия в то, чтобы каждый клиент получил индивидуальное и профессиональное обслуживание, а также полное удовлетворение от результатов нашей работы.
						</span>
						<span className='block mb-3'>
						Важной составляющей нашего подхода является использование только качественных запчастей и материалов. Мы уверены, что только таким образом мы можем гарантировать надежность и долговечность восстановленной техники. Мы тщательно выбираем своих поставщиков и работаем с проверенными брендами, чтобы убедиться, что ваша техника получает только самое лучшее.
						</span>
						<span className='block mb-3'>
						Мы также гордимся нашей гибкой системой ценообразования. Мы предлагаем конкурентоспособные цены на ремонт, чтобы сделать его доступным для каждого клиента. Кроме того, мы также предоставляем скидки для наших постоянных клиентов и акционные предложения, чтобы сделать наш сервис ещё более выгодным для вас.
						</span>
						<span className='block mb-3'>
						Мы ценим каждого клиента и стремимся установить долгосрочные отношения, основанные на взаимном доверии и удовлетворенности. Ваша полная удовлетворенность нашим сервисом является для нас приоритетом номер один, и мы готовы сделать все возможное, чтобы достичь этой цели.
						</span>
						<span className='block mb-3'>
						Если у вас возникли проблемы с вашей бытовой техникой, не стесняйтесь обратиться к нам. Мы гарантируем профессиональный ремонт, использование качественных запчастей, конкурентосппособные цены и предоставление гарантии. Наша гарантия покрывает как ремонтные работы, так и установленные запчасти, чтобы вы могли быть уверены в качестве и надежности каждого выполненного нами ремонта.
						</span>
						<span className='block mb-3'>
						Мы также гордимся тем, что предлагаем удобные условия обслуживания. Наша команда мастеров выполняет ремонт у вас на дому с выездом. Это означает, что вам не нужно тратить время и усилия на транспортировку техники в сервисный центр. Мы приедем к вам в удобное для вас время и место, чтобы провести диагностику и ремонт прямо на месте.
						</span>
						<span className='block mb-3'>
						Мы стремимся к тому, чтобы каждый клиент чувствовал себя ценным и получал высокий уровень сервиса. Наша команда обучена обращаться с клиентами с уважением и вниманием, чтобы полностью удовлетворить их потребности. Мы готовы ответить на ваши вопросы, предоставить подробную информацию о ремонте и обеспечить вас всей необходимой поддержкой.
						</span>
						<span className='block mb-3'>
						Мы приглашаем вас доверить нам ремонт вашей бытовой техники. Мы гарантируем профессиональный подход, высокое качество работы и внимание к каждой детали. Позвольте нам вернуть вашу технику в отличное рабочее состояние, предоставить вам долгосрочную гарантию и превзойти ваши ожидания. Мы готовы быть вашим надежным партнером в ремонте бытовой техники.
						</span>
					</motion.p>
				</motion.div>
			</div>
		</section>
	)
}
