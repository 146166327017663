import { Button, message, Steps, theme } from 'antd';
import { useState } from 'react';
const steps = [
  {
    title: 'Оставляете заявку',
    content: 'Вы можете оформить заявку на нашем сайте, нажав на кнопку ниже, или связаться с нами по телефону. Пожалуйста, укажите номер, с которого вы делали заказ, чтобы мы могли идентифицировать вашу заявку. Наличие бумажки с гарантией желательно, но не обязательно, так как мы хорошо помним всех наших клиентов. Если срок гарантии истек, не беспокойтесь, мы все равно предоставим вам скидку 10% на ремонт. Гарантия распространяется на все работы по ремонту и на запчасти, которые были установлены в процессе ремонта. Мы стремимся обеспечить высокий уровень профессионализма и доверия, поэтому гарантируем качество нашей работы и используемые компоненты. Наша команда готова помочь вам с любыми проблемами вашей бытовой техники. Не стесняйтесь обращаться к нам для получения гарантийного ремонта или ремонта со скидкой после истечения гарантийного срока. Мы ценим наших клиентов и стремимся обеспечить им удобство и качество обслуживания.',
  },
  {
    title: 'Выезд мастера',
    content: 'Наши мастера будут проверять сроки гарантии и приложат все усилия, чтобы приехать к вам в кратчайшие сроки. Мы понимаем, что ваше время ценно, поэтому наша цель - предоставить профессиональное и оперативное обслуживание. После выполнения ремонта мастер выдаст вам новую гарантию на срок от 3 до 24 месяцев. Мы всегда стремимся обеспечить качество и надежность нашей работы, и гарантия является подтверждением нашей ответственности за проведенные ремонтные работы.',
  },
  {
    title: 'Гарантийный ремонт',
    content: 'Удовлетворение клиента нашим сервисом после гарантийного ремонта является нашим важным приоритетом. Мы стремимся обеспечить полную удовлетворенность каждого клиента и гарантировать высокий уровень качества и надежности нашей работы.',
  },
];
const StepsComp = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
 
  return (
    <div className='bg-white shadow-xl py-7 px-5 rounded-xl'>
      <Steps current={current} items={items} />
		  <div className='bg-white/80 text-sm font-light'>{steps[current].content}</div>
		  

      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Дальше
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Вот и всё!')}>
            Завершить
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Назад
          </Button>
        )}
      </div>
    </div>
  );
};
export default StepsComp;