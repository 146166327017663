import { Drawer, Button, Form, message } from 'antd';
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { sendOrderTelegram } from '../../http/telegramAPI';

function WindowsComp() {
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [tel, setTel] = useState('')

	useEffect(() => {
		const timer = setTimeout(() => {
			setDrawerVisible(true);
		}, 10000);

		return () => clearTimeout(timer);
	}, []);

	const validateOperatorCode = (value) => {
		const operatorCode = value.replace(/\D/g, '').slice(3, 5);
		return ["29", "33", "44", "25"].includes(operatorCode);
	};

	const handlePhoneChange = (e) => {
		const newValue = e.target.value;

		if (validateOperatorCode(newValue)) {
			setTel(newValue);
		} else {
			setTel('');
		}
	};

	const handleDrawerClose = () => {
		setDrawerVisible(false);
	};

	const handleFormSubmit = (values) => {
		const messageTo = `
        <b>Запрос на консультацию с сайта rem-teh.by</b>\n
        <b>Телефон:</b> <a href='tel:${tel}'>${tel}</a>\n
      `;
		sendOrderTelegram(messageTo)
			.then(data => {
				if (data.ok) {
					setTel('')
					setDrawerVisible(false)
					message.success('Ваше сообщение отправлено! Мы скоро вам перезвоним.')
					if (window.dataLayer) {
						window.dataLayer.push({'event': 'rem-teh-by-callback-submit'});
					} else {
						console.warn('GTM dataLayer is not defined');
					}
				}
			})
			.catch(error => {
				message.error('Ошибка отправки сообщения. Попробуйте снова.');
				console.error('Ошибка отправки сообщения:', error);
			});
	};

	const beforeMaskedValueChange = (newState, oldState, userInput) => {
		let { value } = newState;
		const a = value.replace(/\D/g, '').slice(3, 5);
		let selection = newState.selection;
		if (a.length === 2) {
			const b = ["29", "33", "44", "25"].includes(a);
			if (!b) {
				value = '+375';
				selection = { start: 5, end: 5 }
				message.warning('Введите код оператора')
			}
		}
		const cursorPosition = selection ? selection.start : null;
		if (value.endsWith('-') && userInput !== '-' && !tel.endsWith('-')) {
			if (cursorPosition === value.length) {
				selection = { start: cursorPosition - 1, end: cursorPosition - 1 };
			}
			value = value.slice(0, -1);
		}
		return {
			value,
			selection
		};
	};

	return (
		<div>
			<Drawer
				title="Консультация"
				placement="right"
				onClose={handleDrawerClose}
				visible={drawerVisible}
				style={{ background: '#eab308' }}
			>
				<div className='pt-16 relative h-full z-50'>
					<p className='text-center mb-10 text-xl font-light'>
						Есть вопросы? Перезвоним Вам в течении 30 секунд!
					</p>
					<Form
						onFinish={handleFormSubmit}
						size='large'
						layout="vertical"
					>
						<Form.Item
							label='Ваш телефон'
							name="tel"
							tooltip="код оператора и номер"
							rules={[
								{
									required: true,
									message: 'Пожалуйста введите номер телефона!',
								},
							]}
						>
							<InputMask
								placeholder="+375 29 123-45-67"
								mask="+3\7\5 99 999 99 99"
								maskChar={'-'}
								className='border py-1 px-3 rounded-md w-full'
								beforeMaskedValueChange={beforeMaskedValueChange}
								value={tel}
								onChange={handlePhoneChange}
							/>

						</Form.Item>

						<Form.Item>
							<Button type="" htmlType="submit" className='w-full' style={{ background: '#000', color: '#fff' }}>
								Жду звонка
							</Button>
						</Form.Item>
					</Form>

					<div className='mt-8 text-center'>
						<p className='uppercase mt-6 text-red-600 text-xl'>
							Звонок бесплатный
						</p>
					</div>

					<div className='bg-white/25 w-20 h-20 rotate-12 absolute bottom-24 left-10 -z-10' />
					<div className='bg-white/25 w-8 h-8 rotate-2 absolute bottom-6 left-32 -z-10' />
					<div className='bg-white/25 w-20 h-20 rotate-12 absolute bottom-36 right-10 -z-10' />
					<div className='bg-white/25 w-24 h-24 rotate-45 absolute bottom-60 left-10 -z-10' />
					<div className='bg-white/25 w-28 h-28 rotate-45 absolute bottom-80 right-10 -z-10' />
					<div className='bg-white/25 w-16 h-16 rotate-45 absolute bottom-12 right-12 -z-10' />
					<div className='bg-white/25 w-6 h-6 rotate-12 absolute bottom-0 right-28 -z-10' />
				</div>
			</Drawer>
		</div>
	)
}

export default WindowsComp;
