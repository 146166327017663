import img from '../../images/articles/molniya.webp'

export const data_article1 = {
	title: 'Удар молнии или скачок напряжения: Ремонт и Возмещение Убытков',
	description: 'Эта статья обсуждает важность быстрого и эффективного действия, когда ваша техника подвергается удару молнии или скачку напряжения.',
	h1: 'Удар молнии или скачок напряжения: Поломки, Ремонт и Возмещение Убытков',
	img: img,
	view2:55,
	alt:'Удар молнии или скачок напряжения',
	content: (
		<div className="">
			<p className="text-justify">
				Когда ваша техника сталкивается с ударом молнии или скачком напряжения, это может привести к серьезной поломке устройств. В таких неприятных ситуациях важно действовать быстро и эффективно, чтобы решить эту проблему.
			</p>
			<ul className="pl-3 pr-2 text-justify">
				<li className="mb-4">
					<h2 className="font-semibold">
						Скачок напряжения в сети:
					</h2>
					<ul className="list-disc">
						<li className="">
							Когда происходит скачок напряжения в сети электроснабжения, напряжение в электропроводах временно увеличивается внезапно и значительно.
						</li>
						<li className="">
							Этот внезапный рост напряжения может привести к перегрузке электрических компонентов и электроники в вашей технике.
						</li>
						<li className="">
							Устройства, подключенные к сети в момент скачка напряжения, могут повреждаться. Электронные компоненты, такие как микрочипы, конденсаторы и трансформаторы, могут выйти из строя.
						</li>
					</ul>
				</li>

				<li className="mb-4">
					<h2 className="font-semibold">
						Удар молнии:
					</h2>
					<ul className="list-disc">
						<li className="">
							Удар молнии представляет собой внезапное и мощное электрическое разряд, который может поразить здания и инфраструктуру.
						</li>
						<li className="">
							Если ваше здание или электронное устройство не имеют адекватной системы защиты от молнии, удар молнии может привести к разрушению и повреждению электроники.
						</li>
						<li className="">
							Молния может вызвать сильные электрические токи, которые могут перегрузить и сжечь электронные компоненты устройств.
						</li>
					</ul>
				</li>

				<li className="mb-4">
					<h2 className="font-semibold">
						Поломка техники:
					</h2>
					<ul className="list-disc">
						<li className="">
							Когда техника подвергается скачку напряжения или удару молнии, это может вызвать разные виды поломок, такие как выход из строя блоков питания, перегорание микрочипов, повреждение проводов и т. д.
						</li>
						<li className="">
							Поломка может быть видна сразу, например, если устройство больше не включается или работает некорректно. Однако некоторые повреждения могут проявиться позже.
						</li>
					</ul>
				</li>

				<li className="">
					<h2 className="font-semibold">
						Ремонт и документация:
					</h2>
					<ul className="list-disc">
						<li className="">
							Для восстановления техники после подобных событий требуется квалифицированный ремонт, который проводят специалисты по ремонту бытовой технике.
						</li>
						<li className="">
							После ремонта вы получите официальный документ, который будет подтверждать причину поломки и проделанную работу. Этот документ будет полезен, если требуется обращение в гарантийную службу, ЖЭС или другие организации для компенсации убытков или гарантийного обслуживания.
						</li>
					</ul>
				</li>
			</ul>

			<p className="font-bold mt-6 px-3 text-justify">
				Важно понимать, что скачки напряжения и удары молнии могут нанести серьезный ущерб вашей технике, и в некоторых случаях поломки могут быть скрытыми и проявиться позже. Поэтому рекомендуется в случае подобных событий обратиться к профессионалам для диагностики и ремонта оборудования, а также документировать причины и последствия поломок для дальнейших действий, таких как обращение в гарантийную службу или запрос компенсации.
			</p>
		</div>
	)
}