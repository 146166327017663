import { Image } from 'antd'
import React from 'react'
import avtohold from "../../images/service/avtoHold.webp"
import avtohold2 from "../../images/service/avtoHold2.webp"
import { Helmet } from "react-helmet"

const data = [
	{ id: 1, name: 'Диагностика на исправность электросхемы', price: '60', warranty: '-' },
	{ id: 2, name: 'Диагностика на исправность холодильного контура', price: '110', warranty: '-' },
	{ id: 3, name: 'Замена выключателя/тумблера/сетевого кабеля/штекера прикуривателя', price: '135', warranty: '6' },
	{ id: 4, name: 'Замена термостата/термодатчика', price: '200', warranty: '12' },
	{ id: 5, name: 'Удаление датчика опрокидывания кабины', price: '120', warranty: '12' },
	{ id: 6, name: 'Замена термоэлектрического модуля (Элемент Пельтье)', price: '180', warranty: '6' },
	{ id: 7, name: 'Замена вентилятора обдува конденсатора/испарителя', price: '150', warranty: '12' },
	{ id: 8, name: 'Замена электронного модуля управления компрессором', price: '760', warranty: '12' },
	{ id: 9, name: 'Заправка холодильного агрегата хладагентом', price: '250', warranty: '12' },
	{ id: 10, name: 'Устранение утечки хладагента (кроме случаев связанных с ремонтом испарителя) и заправка холодильного агрегата', price: '330', warranty: '12' },
	{ id: 11, name: 'Ремонт испарителя, устранение утечки хладагента и заправка холодильного агрегата', price: '390', warranty: '12' },
	{ id: 12, name: 'Устранение засорение капиллярного трубопровода и заправка холодильного агрегата', price: '320', warranty: '12' },
	{ id: 13, name: 'Устранение засорения/замена фильтра-осушителя и заправка холодильного агрегата', price: '290', warranty: '12' },
	{ id: 14, name: 'Замена компрессора, фильтра-осушителя и заправка холодильного агрегата', price: '1 390', warranty: '12' },
	{ id: 15, name: 'Замена компрессора в комплекте с блоком , фильтра-осушителя и заправка холодильного агрегата', price: '1 800', warranty: '12' },
	{ id: 16, name: 'Замена испарителя', price: '550', warranty: '12' },
	{ id: 17, name: 'Чистка конденсатора сжатым воздухом', price: '', warranty: '-' },
	{ id: 18, name: 'Ремонт крышки корпуса холодильника (без учета материалов)', price: '120', warranty: '3' },
	{ id: 19, name: 'Демонтаж/монтаж  автохолодильника из кабины', price: '90', warranty: '-' },
];


export function AvtoHoldPage() {
	return (
		<section className='xyy:py-16 sd:py-36'>
			<Helmet>
				<title>{'Ремонт автомобильных салонных холодильников в Минске'}</title>
				<meta name="description" content={`Компания специализируется на качественном ремонте автомобильных салонных холодильников Mercedes (Мерседес), MAN (Ман), Scania (Скания), IVECO (Ивеко) и других марок. Мы предлагаем быстрое и надежное обслуживание с использованием современного оборудования и оригинальных запчастей.`} />
			</Helmet>


			<div className='container mx-auto'>

				<div className='flex sd:flex-row xyy:flex-col'>
					<div className='sd:mr-8 xyy:mr-0'>
						<h1 className='font-semibold xyy:text-3xl sd:text-5xl'>
							Ремонт автомобильных холодильников
						</h1>
						<p className='mt-5 leading-7 sd:text-lg xyy:text-base text-justify'>
							Срочный ремонт автомобильных салонных холодильников 12В и 24В. У нас всегда есть в наличии компрессора, термостаты и электронные модули к автомобильным холодильникам. Поэтому ремонт будет выполнен в кратчайшие сроки.
						</p>
					</div>
					<Image src={avtohold} preview={false} alt='Ремонт автомобильных холодильников' />
				</div>


				<div className='sd:mt-16 xyy:mt-12 text-center'>
					<h2 className='sd:text-4xl xyy:text-3xl'>
						Стоимость ремонта
					</h2>
					<p className='mt-4 sd:text-lg xyy:text-base'>
						Выполняем срочный ремонт автомобильных салонных холодильников: <span className='font-semibold'> Mercedes (Мерседес), MAN (Ман), Scania (Скания), IVECO (Ивеко) </span> и других марок.
					</p>
					<div className="overflow-x-auto sd:mt-8 xyy:mt-5">
						<table className="border-collapse w-full shadow-xl">
							<thead>
								<tr className="bg-gray-100 text-gray-600 sd:text-base xyy:text-xs">
									<th className="sd:p-3 xyy:p-1 font-bold uppercase border border-gray-300">№ п/п</th>
									<th className="sd:p-3 xyy:p-1 font-bold uppercase border border-gray-300">Наименование работ</th>
									<th className="sd:p-3 xyy:p-1 font-bold uppercase border border-gray-300">Стоимость бел.руб.</th>
									<th className="sd:p-3 xyy:p-1 font-bold uppercase border border-gray-300">Гарантия, мес.</th>
								</tr>
							</thead>
							<tbody>
								{data.map(item => (
									<tr key={item.id} className="bg-white hover:bg-gray-100 sd:text-base xyy:text-xs">
										<td className="sd:p-3 xyy:p-1 border border-gray-300">{item.id}</td>
										<td className="sd:p-3 xyy:p-1 border border-gray-300">{item.name}</td>
										<td className="sd:p-3 xyy:p-1 border border-gray-300">{item.price}</td>
										<td className="sd:p-3 xyy:p-1 border border-gray-300">{item.warranty}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>


				<article className='mt-14'>
					<div className='flex sd:flex-row xyy:flex-col'>
						<h3 className='font-bold sd:text-3xl xyy:text-xl sd:mr-4 xyy:mr-0'>
							Процесс Ремонта автомобильных салонных холодильников в Минске: Качественный сервис для вашего комфорта
						</h3>
						<Image src={avtohold2}
							preview={false} alt='Ремонт автомобильных холодильников'
							className='sd:mt-0 xyy:mt-8'
						/>
					</div>
					<div className='mt-7 text-justify'>
						<p className='mb-3'>
							Автомобильные салонные холодильники - это важное устройство, обеспечивающее комфорт и удобство во время путешествий и длительных поездок. Однако, как и любое другое электронное оборудование, они могут подвергаться поломкам и требовать профессионального ремонта. В Минске существует целый ряд компаний, специализирующихся на ремонте автомобильных салонных холодильников, где каждый владелец автомобиля может получить качественный сервис и решение своих проблем.
						</p>

						<h4 className='mb-2 font-semibold'>
							Диагностика и Оценка
						</h4>
						<p className='mb-4'>
							Процесс ремонта автомобильного холодильника начинается с тщательной диагностики, чтобы определить причину неисправности. Опытные специалисты обычно проводят комплексную проверку электрических и механических компонентов, а также анализируют работу системы охлаждения. После этого составляется подробная оценка стоимости ремонта и обсуждаются все необходимые действия с клиентом.
						</p>

						<h4 className='mb-2 font-semibold'>
							Ремонт и Замена компонентов
						</h4>
						<p className='mb-4'>
							После утверждения оценки ремонт начинается с замены или ремонта поврежденных компонентов. Это может включать в себя замену термостатов, компрессоров, электронных модулей управления, испарителей и других деталей в зависимости от характера поломки. Процесс осуществляется с использованием современного оборудования и оригинальных запчастей, чтобы гарантировать долгосрочную работу холодильной системы.
						</p>
						<h4 className='mb-2 font-semibold'>
							Тестирование и Гарантия
						</h4>
						<p className='mb-4'>
							После завершения ремонта проводится тщательное тестирование системы, чтобы убедиться в ее правильной работе и эффективности. Клиент получает гарантию на выполненные работы, что обеспечивает защиту от возможных неисправностей в течение определенного периода времени.
						</p>
						<h5 className='mb-2 font-semibold'>
							Заключение
						</h5>
						<p className='mb-4'>
							В Минске доступны профессиональные услуги по ремонту автомобильных салонных холодильников, предоставляемые опытными и квалифицированными специалистами. Независимо от марки или модели вашего автомобиля, вы можете быть уверены, что ваш холодильник будет в надежных руках, обеспечивая вам комфорт и удовлетворение ваших потребностей во время каждой поездки.
						</p>
					</div>
				</article>
			</div>
		</section >
	)
}

