export const list = [
	"Hitachi",
	"Neff", 
	"Liebherr",
	"Sharp",
	"Mitsubishi",
	"Toshiba",
	"Siemens",
	"Smeg",
	"Asko",
	"Vestfrost",
	"AEG",
	"Weissgauff",
	"Miele",
	"Kupersbusch",
	"Bauknecht",
	"Gaggenau", 
	"Hyundai",
	"V-ZUG",
	"Waissgauff",
	"Delonghi",
	"Gorenje",
	"Jura",
	"Nivona",
	"Bosch",
	"Gaggia Academia",
	"La Pavoni",
	"Franke",
	"Dalla Corte",
	"Victoria Arduino",
	"KitchenAid",
	"Graude"
 ]