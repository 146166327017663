import React, { useState } from 'react'
import { Form, Button, Input, DatePicker, TimePicker, message } from 'antd'
import InputMask from 'react-input-mask'
import { sendOrderTelegram } from '../../http/telegramAPI'

const { TextArea } = Input

export const FormDate = ({ onClose, title }) => {
	const [tel, setTel] = useState('')


	const onFinish = (values) => {
		let messageForm = `<b>Заказ с сайта rem-teh.by</b>\n`
		messageForm += `<b>${title || ''} </b>\n`
		messageForm += `<b>Предварительная запись </b>\n`
		messageForm += `<b>на дату:</b> ${values.date.$d.toLocaleDateString("ru-RU")}\n`
		messageForm += `<b>время:</b> ${values.time.$d.toLocaleTimeString("ru-RU")}\n`
		messageForm += `<b>- - - - - - - - - - - - - - -</b>\n`
		messageForm += `<b>Телефон:</b> ${values.tel}\n`
		messageForm += `<b>Сообщение:</b> «${values.message || '-'}»\n`

		sendOrderTelegram(messageForm)
			.then(data => {
				console.log("🚀 🚀 🚀  _ onFinish _ data:", data)
				if (data.ok) {
					message.success('Заказ принят')
					onClose()
					if (window.dataLayer) {
						window.dataLayer.push({ 'event': 'rem-teh-by-callback-submit' });
						console.log("🚀 🚀 🚀  _ onFinish _ window.dataLayer:", window.dataLayer)
					} else {
						console.warn('GTM dataLayer is not defined');
					}
				}
			})
			.catch(error => {
				message.error('Ошибка отправки сообщения. Попробуйте снова.');
				console.error('Ошибка отправки сообщения:', error);
			});
	}

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	}

	const validateOperatorCode = (value) => {
		const operatorCode = value.replace(/\D/g, '').slice(3, 5);
		return ["29", "33", "44", "25"].includes(operatorCode);
	};

	const handlePhoneChange = (e) => {
		const newValue = e.target.value;

		if (validateOperatorCode(newValue)) {
			setTel(newValue);
		} else {
			setTel('');
		}
	};

	const beforeMaskedValueChange = (newState, oldState, userInput) => {
		let { value } = newState;
		const a = value.replace(/\D/g, '').slice(3, 5);
		let selection = newState.selection;
		if (a.length === 2) {
			const b = ["29", "33", "44", "25"].includes(a);
			if (!b) {
				value = '+375';
				selection = { start: 5, end: 5 }
				message.warning('Введите код оператора')
			}
		}
		const cursorPosition = selection ? selection.start : null;
		if (value.endsWith('-') && userInput !== '-' && !tel.endsWith('-')) {
			if (cursorPosition === value.length) {
				selection = { start: cursorPosition - 1, end: cursorPosition - 1 };
			}
			value = value.slice(0, -1);
		}
		return {
			value,
			selection
		};
	};


	return (
		<>
			<Form
				name="basic"
				labelCol={{
					span: 24,
				}}
				wrapperCol={{
					span: 24,
				}}

				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>


				<Form.Item
					label='Выберите дату'
					name="date"
					// tooltip="Если на эту дату свободный мастеров нет Вам перезвонит наш менеджер"
					rules={[
						{
							required: true,
							message: 'Пожалуйста выберите дату!',
						},
					]}
				>
					<DatePicker
					/>
				</Form.Item>

				<Form.Item
					label='Выберите время'
					name="time"
					// tooltip="Если на это время свободный мастеров нет Вам перезвонит наш менеджер"
					rules={[
						{
							required: true,
							message: 'Пожалуйста выберите время!',
						},
					]}
				>
					<TimePicker
						showTime={{
							format: 'HH:mm',
						}}
					/>
				</Form.Item>

				<Form.Item
					label='Сообщение'
					name="message"
					tooltip=""
				>
					<TextArea
						placeholder="Сообщение"
						autoSize={{
							minRows: 3,
						}}
					/>
				</Form.Item>


				<Form.Item
					label='
					Телефон'
					name="tel"

					tooltip="код оператора и номер"
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите номер!',
						},
					]}
				>
					<InputMask
						placeholder="29 123-45-67"
						mask="+3\7\5 99 999 99 99"
						maskChar={'-'}
						className='border py-1 px-4 rounded-md w-full'
						beforeMaskedValueChange={beforeMaskedValueChange}
						value={tel}
						onChange={handlePhoneChange}
					/>
				</Form.Item>

				<Form.Item
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
					<Button type="primary" htmlType="submit">
						Отправить заказ
					</Button>
				</Form.Item>
			</Form>

			<p className='text-orange-500 text-[10px] mt-10 uppercase'>15% скидка по предварительной записи</p>

		</>
	)
}
