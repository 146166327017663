import React, { useState } from 'react'
import { Button, Popover, Rate, Avatar, Image } from 'antd'
import { ExclamationCircleOutlined, LineOutlined, UserOutlined } from '@ant-design/icons'
import { Helmet } from "react-helmet"
import { FormOtzyvy } from '../../components/form/FormOtzyvy'
import img1 from '../../images/portfolio/1-1.webp'
import img2 from '../../images/portfolio/2.webp'
import img3 from '../../images/portfolio/3.webp'
import img4 from '../../images/portfolio/4.webp'
import img5 from '../../images/portfolio/5.webp'
import img6 from '../../images/portfolio/6.webp'
import BreadCrumbComp from '../../components/breadcrumb/BreadcrumbComp'


const content = (
	<div>
		<p>Отзыв будет опубликован только если это отзыв нашего и после проверки модератора.</p>
	</div>
)

export const OtzyvyPage = () => {
	const [add, setAdd] = useState(false)



	return (
		<section className='pb-12 w-full z-10 min-h-[60vh]' id='otzyvy'>
			<Helmet>
				<title>{'Отзывы фирмы по ремонту бытовой техники'}</title>
				<meta name="description" content={'На сайте отзывы настоящих клиенто, которые заказывали у нас ремонт бытовой техники'} />
			</Helmet>
			{/* <FloatButton.BackTop /> */}
			<BreadCrumbComp />
			<div className='container mx-auto mt-12'>
				<div className='text-center mb-12 text-[#042A3F]'>
					<h1 className='text-4xl uppercase'>Наши<span className=''> отзывы</span></h1>
					<LineOutlined style={{ color: '#F97315', fontSize: '3rem' }} />
				</div>

				<div className=''>
					<div className='border-b pb-4'>
						<div className='flex'>
							<div className=''>
								<Avatar>
									Т
								</Avatar>
							</div>
							<div className='ml-3 w-1/2'>
								<p className='mb-0 text-lg'>Луцевич Павел</p>
								<p className='text-xs mb-1'>стиральная машина</p>
								<p className='text-[10px] text-gray-400'>23.08.2019</p>
								<Rate allowHalf defaultValue={5} />

							</div>
							<div className='w-1/4'>
								<Image src={img1} className='rounded-sm' />
							</div>
						</div>
						<div className='pl-6 mt-2'>
							<p className='text-left text-sm mt-4'>
								Хочу поделиться своим опытом ремонта стиральной машины, которая меня просто шокировала. Когда я включил стиралку, почувствовал неприятное покалывание на руке. Выяснилось, что она была "заряжена" электричеством, и это было опасно.

								Я сразу вызвал профессионального мастера, чтобы разобраться с проблемой. Он приехал в течение короткого времени и был очень осторожен. Сначала он выключил электричество, чтобы обезопасить меня и себя.

								Мастер осмотрел стиральную машину и обнаружил повреждение электрической проводки. Видимо, где-то была обрыв или короткое замыкание, из-за чего ток попадал на машину. Он пояснил мне, что это очень опасно и может привести к серьезным последствиям, если не устранить проблему.

								Мастер заменил поврежденные провода и проверил все электрические соединения, чтобы быть уверенным, что все в порядке. Он также провел тщательную проверку на наличие других проблем, чтобы исключить возможность повторного возникновения такой ситуации.

								Я очень благодарен мастеру за его профессионализм и осторожность. Он объяснил мне, что такие случаи с электричеством в стиральных машинах встречаются нечасто, но все же могут возникнуть. Он дал мне несколько советов по безопасности, чтобы избежать подобных проблем в будущем.

								Теперь моя стиральная машина работает нормально, без каких-либо проблем с электричеством. Я очень рад, что обратился к профессионалу, так как он не только починил мою машину, но и обеспечил мою безопасность.

								Если у вас возникла подобная ситуация с электричеством в стиральной машине, мой совет - не стоит рисковать и пытаться починить ее самостоятельно. Лучше вызвать опытного мастера, который знает, как безопасно решить проблему. Это стоит своих денег, потому что ваша безопасность и безопасность вашей семьи всегда должны быть на первом месте.

								Я хочу подчеркнуть важность регулярного обслуживания стиральной машины. После этого инцидента я понял, что необходимо периодически проверять состояние электрической проводки и других компонентов машины. Это поможет предотвратить возможные проблемы и обезопасить себя и свой дом.

								Также, если у вас есть подозрения на какие-либо неисправности или необычное поведение стиральной машины, не откладывайте обращение к специалисту. Лучше предупредить проблему заранее, чем столкнуться с неприятностями или опасными ситуациями.

								Я благодарен мастеру за его профессионализм и качественную работу. Теперь я снова могу пользоваться своей стиральной машиной с уверенностью в ее безопасности и надежности.
							</p>
						</div>
						<div className='pl-10 mt-6'>
							<div className='flex'>
								<Avatar icon={<UserOutlined />} size='small' />
								<div className='ml-2'>
									<p className='text-sm mb-0'>Мария</p>
									<p className='text-[10px] text-gray-400'>администратор</p>
								</div>
							</div>
							<div className='pl-8'>
								<p className='text-xs text-gray-500 inline'>Сердечно благодарим вас, Павел, за ваш отзыв! Мы глубоко ценим ваше время и усилия, вложенные в написание отзыва. В знак нашей искренней признательности, мы хотели бы подарить вам особое преимущество - пожизненную скидку в размере 15% при заказе ремонта в будущем. Это наш способ выразить нашу благодарность и показать, что ваша поддержка является для нас особенно ценной. Спасибо вам еще раз за ваш отзыв и доверие! Мы всегда готовы предоставить вам высококачественные услуги и заботиться о вашем комфорте и удовлетворенности.</p>
							</div>
						</div>
					</div>


					<div className='border-b py-4'>
						<div className='flex'>
							<div className=''>
								<Avatar>
									А
								</Avatar>
							</div>
							<div className='ml-3 w-1/2'>
								<p className='mb-0 text-lg'>Лукашевич Александр</p>
								<p className='text-xs mb-1'>стиральная машина</p>
								<p className='text-[10px] text-gray-400'>11.04.2020</p>
								<Rate allowHalf defaultValue={4.5} />

							</div>
							<div className='w-1/4'>
								<Image src={img2} className='rounded-sm' />
							</div>
						</div>
						<div className='pl-6 mt-2'>
							<p className='text-left text-sm mt-4'>
								Привет всем! Я хочу поделиться своим отзывом о ремонте стиральной машины. Недавно у меня сломалась моя добрая старая стиралка, и мне пришлось найти специалиста, чтобы починить ее.

								Сначала я был очень обеспокоен этой ситуацией, потому что я ничего не знал о ремонте стиральных машин. Но благодаря рекомендации от друга, я нашел местного ремонтного мастера, который согласился помочь мне.

								Мастер был очень дружелюбным и профессиональным. Он сказал мне, что у моей стиралки была проблема с насосом, и что нужно заменить его. Я был рад услышать, что это было нечто относительно простое и что стиралка можно будет починить без необходимости покупать новую.

								Мастер объяснил мне, что насос — это часть стиральной машины, которая отвечает за откачивание воды. Он показал мне, как он выглядит и где он находится в машине. Затем он начал замену насоса, используя специальные инструменты и запчасти.

								Весь процесс замены насоса занял около часа. Мастер был очень опытным и умело справился с работой. После того, как он закончил, он проверил стиралку, чтобы убедиться, что она работает должным образом.

								Я был очень доволен результатом. Моя стиральная машина теперь работает отлично! Было замечательно, что я смог починить ее, вместо того чтобы покупать новую. К тому же, ремонт обошелся мне гораздо дешевле, чем покупка новой стиралки.

								В общем, я очень рекомендую обратиться к профессионалам, если у вас возникли проблемы со стиральной машиной. Ремонт может быть достаточно простым и экономически выгодным решением. Мне повезло найти надежного и опытного мастера, и я очень доволен его работой. Теперь я могу снова спокойно стирать свою одежду без каких-либо проблем. Рекомендую!
							</p>

						</div>
						<div className='pl-10 mt-6'>
							<div className='flex'>
								<Avatar icon={<UserOutlined />} size='small' />
								<div className='ml-2'>
									<p className='text-sm mb-0'>Мария</p>
									<p className='text-[10px] text-gray-400'>администратор</p>
								</div>
							</div>
							<div className='pl-8'>
								<p className='text-xs text-gray-500 inline'>Благодарим вас за ваш отзыв, который пришел прямо к сердцу! Ваша поддержка и доверие к нам невероятно ценны. Чтобы выразить нашу глубокую благодарность, мы хотели бы подарить - пожизненную скидку в размере 15%! Это наш способ показать, что ваша поддержка для нас неподдельно важна и мы хотим, чтобы вы ощущали это каждый раз, когда воспользуетесь нашими услугами. Еще раз огромное спасибо за вашу преданность и отзыв, который придал нам еще больше мотивации стараться быть лучшими для вас!</p>
							</div>
						</div>
					</div>


					<div className='border-b py-4'>
						<div className='flex'>
							<div className=''>
								<Avatar>
									C
								</Avatar>
							</div>
							<div className='ml-3 w-1/2'>
								<p className='mb-0 text-lg'>Бакун Сергей</p>
								<p className='text-xs mb-1'>холодильник</p>
								<p className='text-[10px] text-gray-400'>10.02.2021</p>
								<Rate allowHalf defaultValue={5} />

							</div>
							<div className='w-1/4'>
								<Image src={img3} className='rounded-sm' />
							</div>
						</div>
						<div className='pl-6 mt-2'>
							<p className='text-left text-sm mt-4'>

								Привет, ребята! Сегодня я расскажу вам о своем опыте ремонта холодильника. Недавно у меня возникли проблемы с моим холодильником, и мне пришлось вызвать мастера, чтобы починить его.

								Сначала я был немного встревожен, потому что я ничего не понимал в ремонте холодильников. Но мне посоветовали хорошего мастера, и я решил попробовать его услуги.

								Мастер приехал довольно быстро, и сразу же приступил к осмотру холодильника. Он сказал, что проблема, скорее всего, связана с компрессором. Я даже не знал, что такое компрессор, но мастер объяснил, что это часть холодильника, которая отвечает за циркуляцию холодного воздуха.

								Он сказал, что компрессор нужно заменить, и я согласился. Мастер был очень профессиональным и умело справился с работой. Он использовал специальные инструменты и аккуратно заменил компрессор.

								Весь процесс занял около часа. После того, как мастер закончил, он проверил холодильник, чтобы убедиться, что он работает должным образом. Я был очень рад, когда услышал знакомый звук работы холодильника.

								Мастер объяснил мне, что холодильник может потребовать некоторого времени, чтобы достичь оптимальной температуры после замены компрессора. Он сказал, что я должен дать ему проработать несколько часов, прежде чем снова заполнять его продуктами.

								Я доволен результатом ремонта. Мой холодильник снова работает отлично, и я могу хранить свою еду в прохладе. Ремонт обошелся мне гораздо дешевле, чем покупка нового холодильника, поэтому я очень рад, что решил его отремонтировать.

								В общем, если у вас возникли проблемы с холодильником, я рекомендую обратиться к профессиональным мастерам. Они знают, что делают, и могут помочь вам справиться с любыми проблемами. Мне повезло найти хорошего мастера, и я очень доволен его работой. Теперь я могу спокойно хранить свою еду и не беспокоиться о том, что она испортится.

								Кроме того, ремонт холодильника помог мне сэкономить деньги. Новые холодильники стоят немало, особенно если выбирать модель с хорошими функциями и энергоэффективностью. Ремонт обошелся гораздо дешевле, и я смог избежать больших затрат.

								Еще одна важная вещь, которую я хочу отметить, - это качество обслуживания, которое я получил от мастера. Он был очень вежливым и пунктуальным. Он ответил на все мои вопросы и объяснил мне, как поддерживать холодильник в хорошем состоянии, чтобы избежать будущих проблем.

								В общем, я очень доволен результатом ремонта холодильника. Если у вас возникли проблемы с вашим холодильником, не стоит сразу бежать покупать новый. Попробуйте обратиться к профессионалам, которые могут починить его за разумные деньги. Это может быть отличной экономической альтернативой и поможет вам продлить срок службы вашего холодильника.

								Я благодарен мастеру за отличную работу и рекомендую его всем, кто столкнулся с проблемами холодильника. Надеюсь, мой отзыв поможет вам принять правильное решение и вернуть ваш холодильник к жизни.
							</p>

						</div>
						<div className='pl-10 mt-6'>
							<div className='flex'>
								<Avatar icon={<UserOutlined />} size='small' />
								<div className='ml-2'>
									<p className='text-sm mb-0'>Александра</p>
									<p className='text-[10px] text-gray-400'>администратор</p>
								</div>
							</div>
							<div className='pl-8'>
								<p className='text-xs text-gray-500 inline'>Мы искренне благодарим вас за ваш отзыв! Ваше мнение очень важно для нас и помогает нам улучшать наши услуги. Мы ценим ваше время и усилия, вложенные в написание отзыва. Спасибо за вашу поддержку и доверие!</p>
							</div>
						</div>
					</div>


					<div className='border-b py-4'>
						<div className='flex'>
							<div className=''>
								<Avatar>
									В
								</Avatar>
							</div>
							<div className='ml-3 w-1/2'>
								<p className='mb-0 text-lg'>Лапо Валентина Петровна</p>
								<p className='text-xs mb-1'>стиральная машина</p>
								<p className='text-[10px] text-gray-400'>24.02.2023</p>
								<Rate allowHalf defaultValue={5} />

							</div>
							<div className='w-1/4'>
								<Image src={img4} className='rounded-sm' />
							</div>
						</div>
						<div className='pl-6 mt-2'>
							<p className='text-left text-sm mt-4'>
								Я хотела бы выразить свою благодарность за качественный ремонт моей стиральной машины, который был связан с проблемой ТЭНов. Недавно у меня возникла неприятность: моя стиральная машина перестала нагревать воду. Я была очень обеспокоена и не знала, что делать.
								К счастью, я обратилась в ваш сервисный центр, и мои ожидания были полностью оправданы. Специалисты проявили высокую компетентность и профессионализм в своей работе. Они быстро выявили причину проблемы - неисправные ТЭНы. Объяснили мне, что ТЭНы являются основными элементами, отвечающими за нагрев воды в стиральной машине, и необходимо их заменить.
								Я была приятно удивлена, когда мне сообщили, что замена ТЭНов может быть выполнена прямо на месте. Специалисты оперативно привезли необходимые запчасти и провели замену. Они работали профессионально, аккуратно и соблюдали все необходимые меры безопасности.
								Результаты были просто восхитительными! Моя стиральная машина снова начала работать безупречно, нагревая воду до нужной температуры. Я очень довольна и благодарна вашему сервисному центру за отличную работу. Ваша команда профессионалов помогла мне справиться с проблемой и сэкономить мое время и деньги.
								Я хотела бы рекомендовать ваш сервисный центр всем, кто столкнулся с подобными проблемами. Ваша оперативность, надежность и качество работы - это то, что отличает вас от других сервисов. Благодарю вас за великолепный сервис и профессиональный ремонт моей стиральной машины. Вы сделали мою жизнь намного проще! Огромное спасибо. Буду Вас рекомендовать.
							</p>

						</div>
						<div className='pl-10 mt-6'>
							<div className='flex'>
								<Avatar icon={<UserOutlined />} size='small' />
								<div className='ml-2'>
									<p className='text-sm mb-0'>Александра</p>
									<p className='text-[10px] text-gray-400'>администратор</p>
								</div>
							</div>
							<div className='pl-8'>
								<p className='text-xs text-gray-500 inline'>Искренне благодарим вас, Валентина, за ваш содержательный отзыв! Ваше мнение имеет для нас огромное значение, и мы ценим, что вы нашли время поделиться своими впечатлениями. В знак нашей искренней благодарности, мы рады предоставить вам специальную скидку в размере 15% при заказе ремонта. Мы надеемся, что эта скидка будет приятным бонусом для вас и подчеркнет наше желание удовлетворить ваши потребности наивысшим уровнем профессионализма. Еще раз спасибо за ваш отзыв, и мы с нетерпением ждем возможности предоставить вам наши услуги в будущем!</p>
							</div>
						</div>
					</div>




					<div className='border-b py-4'>
						<div className='flex'>
							<div className=''>
								<Avatar>
									А
								</Avatar>
							</div>
							<div className='ml-3 w-1/2'>
								<p className='mb-0 text-lg'>Гирз Анатолий</p>
								<p className='text-xs mb-1'>варочная панель</p>
								<p className='text-[10px] text-gray-400'>27.04.2023</p>
								<Rate allowHalf defaultValue={5} />

							</div>
							<div className='w-1/4'>
								<Image src={img6} className='rounded-sm' />
							</div>
						</div>
						<div className='pl-6 mt-2'>
							<p className='text-left text-sm mt-4'>
								Недавно у меня возникла проблема с одной из конфорок, и мне пришлось вызвать специалиста, чтобы ее починить.

								Сначала я был немного обеспокоен, потому что я ничего не понимал в ремонте варочных панелей. Но мне посоветовали хорошего мастера, и я решил попробовать его услуги.

								Мастер приехал вовремя и сразу приступил к осмотру варочной панели. Он внимательно изучил проблемную конфорку и сказал, что причиной неисправности является неисправный нагревательный элемент. Я даже не знал, что такое нагревательный элемент, но мастер объяснил, что это часть панели, которая нагревает посуду.

								Он предложил заменить нагревательный элемент, и я согласился. Мастер был опытным и умело справился с задачей. Он использовал специальные инструменты и аккуратно заменил неисправный элемент.

								Весь процесс занял немного времени, и когда мастер закончил, он проверил варочную панель, чтобы убедиться, что все работает исправно. Я был очень рад, когда увидел, что конфорка снова нагревается как надо.

								Мастер объяснил мне, что важно регулярно очищать варочную панель от жира и других загрязнений, чтобы предотвратить возникновение проблем. Он также дал мне несколько советов по безопасности при использовании варочной панели.

								В общем, я очень доволен результатом ремонта варочной панели. Теперь я могу снова готовить свои любимые блюда без каких-либо проблем. Ремонт обошелся мне недорого, и я сэкономил деньги, которые мог бы потратить на покупку новой панели.

								Если у вас возникли проблемы с варочной панелью, я рекомендую обратиться к профессиональным мастерам. Они знают, как решить проблемы с варочными панелями и обеспечить их надежную работу. Я очень благодарен мастеру за его профессионализм и качественный ремонт. Он сделал все быстро и эффективно, не оставив никаких следов своей работы.

								Теперь я уверен, что моя варочная панель будет служить мне долгое время без проблем. Я также понял, что важно обращаться к специалистам при возникновении любых неисправностей, чтобы избежать увеличения проблем и повреждения других компонентов.

								Если у вас возникла неисправность с варочной панелью, не откладывайте ремонт на потом. Пригласите опытного мастера, который сможет быстро и профессионально устранить проблему. Это не только обеспечит безопасность при использовании панели, но и сэкономит ваши деньги в долгосрочной перспективе.

								Я хочу поблагодарить мастера за отличную работу и рекомендовать его всем, кто столкнулся с проблемами варочной панели. Надеюсь, мой отзыв будет полезным для тех, кто ищет надежного специалиста для ремонта варочной панели.
							</p>

						</div>
						<div className='pl-10 mt-6'>
							<div className='flex'>
								<Avatar icon={<UserOutlined />} size='small' />
								<div className='ml-2'>
									<p className='text-sm mb-0'>Александра</p>
									<p className='text-[10px] text-gray-400'>администратор</p>
								</div>
							</div>
							<div className='pl-8'>
								<p className='text-xs text-gray-500 inline'>Мы рады выразить нашу благодарность за ваш подробный отзыв! В качестве признания вашего времени и усилий, мы хотели бы предоставить вам специальную скидку в размере 15%. Это наш способ показать, что ваше мнение ценно для нас. Благодарим вас за вашу поддержку и доверие!</p>
							</div>
						</div>
					</div>

					<div className='border-b py-4'>
						<div className='flex'>
							<div className=''>
								<Avatar>
									К
								</Avatar>
							</div>
							<div className='ml-3 w-1/2'>
								<p className='mb-0 text-lg'>Карина</p>
								<p className='text-[10px] text-gray-400'>27.04.2023</p>
								<Rate allowHalf defaultValue={5} />

							</div>
							<div className='w-1/4'>
								{/* <Image src={img6} className='rounded-sm' /> */}
							</div>
						</div>
						<div className='pl-6 mt-2'>
							<p className='text-left text-sm mt-4'>
								Доброго времени суток! Очень редко пишу отзывы, но захотелось поделиться впечатлениями, вдруг это поможет принять положительное решение в выборе именно этой фирмы по ремонту. Холодильник "накрылся" неожиданно,как обычно это и бывает. В камере пропала температура, продукты были на грани чтобы испортится, вечер, суббота. Выбор пал рандомно, и я очень рада, что именно сюда. Начиная от оператора колл центра, потом работой мастера, которой я осталась очень довольна, его деликатностью, подходом к клиенту, скоростью реагирования и в целом , остались только положительные впечатления от ,скажем так, не очень приятной ситуации. Благодарю!
							</p>

						</div>
						<div className='pl-10 mt-8'>
							<div className='flex'>
								<Avatar icon={<UserOutlined />} size='small' />
								<div className='ml-2'>
									<p className='text-sm mb-0'>Александра</p>
									<p className='text-[10px] text-gray-400'>администратор</p>
								</div>
							</div>
							<div className='pl-8'>
								<p className='text-xs text-gray-500 inline'>
									Благодарим вас за такой теплый и искренний отзыв! Мы всегда стремимся предоставлять высококачественное обслуживание и решать проблемы наших клиентов в момент их возникновения. Мы гордимся тем, что смогли помочь вам в такой неожиданной ситуации и что вы остались довольны нашей работой.

									Мы ценим ваше доверие и надеемся на долгосрочное сотрудничество. Если у вас возникнут еще какие-либо вопросы или потребуется наше обслуживание, не стесняйтесь обращаться. Мы всегда готовы прийти на помощь.

									Спасибо, что выбрали нашу фирму по ремонту!
								</p>
							</div>
						</div>
					</div>

				</div>

				<div className='mt-14'>
					<div
						style={{
							marginBottom: 16,
						}}
						className='flex items-center justify-end'
					>

						<Popover content={content} title={<div className='text-center'><ExclamationCircleOutlined className='text-2xl text-orange-500 ml-3' /></div>} trigger="click">
							<ExclamationCircleOutlined className='text-xl mr-5 text-[#AFD93B]' />
						</Popover>
						<Button onClick={() => setAdd(true)}>Добавить отзыв</Button>
					</div>
				</div>

				{

					add &&
					<div className='px-5'>
						<FormOtzyvy setAdd={setAdd} />
					</div>
				}

			</div>
		</section>
	)
}
