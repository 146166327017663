import "./styles.css";
import { useRef } from "react";
import {
	motion,
	useScroll,
	useSpring,
	useTransform,
	useMotionValue,
	useVelocity,
	useAnimationFrame
} from "framer-motion";
import { wrap } from "@motionone/utils";
import { list } from "../../constants/listForScroll/data";


export function ScrollVelocity({ baseVelocity = 100 }) {
	const baseX = useMotionValue(0);
	const { scrollY } = useScroll();
	const scrollVelocity = useVelocity(scrollY);
	const smoothVelocity = useSpring(scrollVelocity, {
		damping: 50,
		stiffness: 400
	});
	const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
		clamp: false
	});
	const x = useTransform(baseX, (v) => `${wrap(0, -1000, v)}%`);
	// const x = useTransform(baseX, (v) => `${wrap(0, 100, v)}%`);
	const directionFactor = useRef(1);
	useAnimationFrame((t, delta) => {
		let moveBy = directionFactor.current * baseVelocity * (delta / 1000);
		if (velocityFactor.get() < 0) {
			directionFactor.current = -1;
		} else if (velocityFactor.get() > 0) {
			directionFactor.current = 1;
		}
		moveBy += directionFactor.current * moveBy * velocityFactor.get();
		baseX.set(baseX.get() + moveBy);
	});
	return (
		<div className="parallax bg-amber-500">
			<motion.div className="scroller" style={{ x }}>
				{
					list.map((el, idx) => {
						return (
							<span key={idx}>
								{el}
							</span>
						)
					})
				}
			</motion.div>
		</div>
	);
}
